import React, { useEffect, useState } from "react";
import styles from "./Result.module.css";
import demoprofileImg from "../../../Assests/Images/ProfileDemo.svg";
import CrossImg from "../../../Assests/Images/Cross.svg";
import Edit from "../../../Assests/Images/Edit.svg";
import DeleteResult from "./DeleteResult";
import EditResult from "./EditResult";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../../Common/Toster/Toster";
import { useNavigate } from "react-router-dom";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Loader from "../../../Common/Loader/Loader";
import {
  capitalizeFirstLetter,
  formatDate,
  getDayOfWeek,
} from "../../../Utils/CommonFunction";
import { CONSTANT } from "../../../Utils/Constant";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { REPEATING_DAYS } from "../../../Utils/MockData";

function Result({ member_id, userName, profileImg }) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const [result, setResult] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pageNo, setPageNo] = useState(CONSTANT.PAGE_ONE);
  const [resultCount, setResultCount] = useState();
  const [resultId, setResultId] = useState("");

  const [resultIdDelete, setResultIdDelete] = useState("");

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  function handleShowEdit(index) {
    if (index === editIndex) {
      setShowEdit(false);
      setEditIndex();
    } else {
      setShowEdit(true);
      setEditIndex(index);
    }
  }

  const handleGetResult = (page) => {
    const response = TrainzaApi.GetMemberResult(
      isAuthenticated,
      member_id,
      page
    );
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.data?.code == STATUS_CODE.INVALID_MEMBER_ID) {
          Toster(t("INVALD_MEMBER"), "error");
          navigate("/members");
        } else if (
          response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
        ) {
          Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
          navigate("/members");
        } else {
          if (page == 1) {
            setResult(response?.data?.memberResultList);
          } else {
            setResult([...result, ...response?.data?.memberResultList]);
          }
          setPageNo(page + 1);
          setIsLoadMore(response?.data?.loadMore);
          setResultCount(response?.data?.count);
        }
      }
    });
  };

  useEffect(() => {
    handleGetResult(pageNo);
  }, []);

  const handleDeleteResult = () => {
    const response = TrainzaApi.DeleteResult(
      isAuthenticated,
      member_id,
      resultIdDelete
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.data?.code == STATUS_CODE.INVALID_MEMBER_ID) {
          Toster(t("INVALD_MEMBER"), "error");
          navigate("/members");
        } else if (
          response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
        ) {
          Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
          navigate("/members");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            Toster(t("RESULT_DELETED"), "success");
            handleGetResult(CONSTANT.PAGE_ONE);
            setShowDelete(false);
          } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response.data.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.userName}>
        <img
          src={profileImg ? profileImg : demoprofileImg}
          alt="profileImg"
        ></img>
        <p>{capitalizeFirstLetter(userName)}</p>
      </div>

      {result?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={styles.runDetailBox}
              style={{
                backgroundColor:
                  index === editIndex ? CONSTANT.INACTIVE_BACKGROUND : "",
              }}
            >
              <div>
                <p className={styles.marathonName}>
                  {capitalizeFirstLetter(item?.title)}
                </p>
                <div className={styles.marathonTiming}>
                  <p>
                    <span>{getDayOfWeek(item?.eventDate)}</span>{" "}
                    {item.startDate
                      ? formatDate(item.startDate)
                      : REPEATING_DAYS[item.repeatingDays - 1].day}{" "}
                    {item.endDate ? `- ${formatDate(item.endDate)}` : ""}-{" "}
                    {item.pace} - <span>{item.distance}</span>
                    {item.distanceUnit == 1 ? "KM" : "MI"} -{" "}
                    <span>{item.result}</span>
                  </p>
                </div>
              </div>
              <div className={styles.runDetailBoxEdit}>
                <img
                  src={Edit}
                  alt="edit"
                  onClick={() => {
                    handleShowEdit(index);
                    setResultId(item.id);
                    setShowAdd(false);
                  }}
                ></img>
                <img
                  src={CrossImg}
                  alt="crossImg"
                  onClick={() => {
                    handleShowDelete();
                    setResultIdDelete(item.id);
                  }}
                ></img>
              </div>
            </div>
            {editIndex === index && showEdit ? (
              <React.Fragment>
                {showEdit ? (
                  <EditResult
                    handleGetResult={handleGetResult}
                    member_id={member_id}
                    resultId={resultId}
                    editIndex={editIndex}
                    setShowEdit={setShowEdit}
                    setEditIndex={setEditIndex}
                    setShowAdd={setShowAdd}
                    showEdit={showEdit}
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}

      <div className={styles.bottomSection}>
        <CustomButton
          variant="footerBtnCustom"
          onClick={() => {
            setShowEdit(false);
            setEditIndex();
            setShowAdd(true);
            setResultId();
          }}
        >
          <p>
            {t("ADD")} <span>{t("RESULTS__")}</span>
          </p>
        </CustomButton>
        <div className={styles.result}>
          <p>
            {resultCount}{" "}
            <span>{resultCount > 1 ? t("RESULTS_") : t("Result")}</span>
          </p>
        </div>

        <CustomButton
          variant="footerBtnCustom"
          onClick={() => {
            if (isLoadMore) {
              handleGetResult(pageNo);
            }
          }}
          opacity={isLoadMore ? "" : CONSTANT.OPACITY_RATIO}
        >
          {" "}
          <p>
            {t("LOAD")} <span>{t("MORE")}</span>
          </p>
        </CustomButton>
      </div>

      <DeleteResult
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteGroup={handleDeleteResult}
        isLoading={isLoading}
      />
      {showAdd && (
        <EditResult
          setShowEdit={setShowEdit}
          setEditIndex={setEditIndex}
          setShowAdd={setShowAdd}
          member_id={member_id}
          handleGetResult={handleGetResult}
          resultId={resultId}
        />
      )}
    </div>
  );
}

export default Result;
