import { Col, Form, Row } from "react-bootstrap";
import styles from "../WorkOutTab/Workout.module.css";
import { useTranslation } from "react-i18next";
import { useForm, useController } from "react-hook-form";
import { CustomInputField } from "../../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import { useNavigate } from "react-router-dom";
import { CustomSelect } from "../../../ReactBootstrap/CustomSelect/CustomSelect";
import { useEffect, useRef, useState } from "react";
import TrainzaApi from "../../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import Toster from "../../../Common/Toster/Toster";
import ReactQuill from "react-quill";
import { RiVideoFill } from "react-icons/ri";
import { FaImage } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import Loader from "../../../Common/Loader/Loader";
import CrossImg from "../../../Assests/Images/Cross.svg";
import { CONSTANT } from "../../../Utils/Constant";
import { fetchImageAsFile } from "../../../Utils/CommonFunction";
import DeleteWorkoutTypes from "../TrainingTab/DeleteWorkoutType";

function AddWorkout({
  setShowAdd,
  workOutId,
  setWorkOutId,
  setWorkoutType123,
  addManually,
  setAddManually,
  workoutList123,
  setWhichScreen,
  handleWorkoutDetailToShow,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [workoutList, setWorkoutList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeWorkout, setActiveWorkout] = useState(0);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [workoutTitle, setWorkoutTitle] = useState("");
  const [workoutType, setWorkoutType] = useState("");
  const [workoutDetail, setWorkoutDetail] = useState([]);
  const [showDeleteWorkout, setShowDeleteWorkout] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //===== function for handle validation====
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    clearErrors,
    trigger,
    watch,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  // =====  handle textarea=====
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "WORKOUT_DETAIL",
    control,
    rules: {
      required: {
        value: true,
        message: t("Please enter workout detail"),
      },
      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        return strippedValue !== "" || t("Please enter workout detail");
      },
    },
  });

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const stripHtmlTags = (input) => input?.replace(/(<([^>]+)>)/gi, "").trim();
  const watchedValue = watch("WORKOUT_DETAIL") || getValues("WORKOUT_DETAIL");

  useEffect(() => {
    if (stripHtmlTags(watchedValue) === "" || getValues("WORKOUT_DETAIL")) {
      trigger("WORKOUT_DETAIL");
    }
  }, [watchedValue, trigger]);

  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");
    elements.forEach((el) => {
      el.style.color = ""; 
    });

    return doc.body.innerHTML;
  };


  //======= prevent white space=====
  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
       // Apply max character limit
       if (sanitizedValue.length > 65535) {
        // Trim the value if it exceeds the limit
        onChange(sanitizedValue.slice(0, 65535));
      } else {
        onChange(sanitizedValue);
      }
    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
   
  };

  // Set prefilled images when workoutDetail is fetched
  useEffect(() => {
    if (workOutId && workoutDetail.images && workoutDetail.images.length > 0) {
      const defaultImages = workoutDetail.images.map((item) => item.image);
      setSelectedImage(defaultImages);
      setImagePreview(defaultImages);
    }
  }, [workoutDetail]);

  //====   function for handle select video======
  const handleVideoSelect = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setSelectedVideo(file);
    } else {
      setSelectedVideo(null);
    }
  };

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const newImagesCount = (selectedImage?.length || 0) + files.length;
    if (newImagesCount > 3) {
      Toster("You cannot upload more than 3 images", "error");
      return;
    }
    const validImages = files.filter((file) => file.type.startsWith("image/"));
    setSelectedImage((prevImages) => {
      const updatedImages = prevImages
        ? [...prevImages, ...validImages]
        : [...validImages];
      return updatedImages.slice(0, 3);
    });
    const previewUrls = validImages.map((file) => URL.createObjectURL(file));
    setImagePreview((prevPreviews) => {
      const updatedPreviews = prevPreviews
        ? [...prevPreviews, ...previewUrls]
        : [...previewUrls];
      return updatedPreviews.slice(0, 3);
    });

    e.target.value = null;
  };

  const handleRemoveImage = (index) => {
    const newSelectedImages = selectedImage.filter((_, i) => i !== index);
    const newImagePreviews = imagePreview.filter((_, i) => i !== index);
    setSelectedImage(newSelectedImages);
    setImagePreview(newImagePreviews);
  };

  //===== function for calling workout type list api====
  const handleWorkoutTypeList = (search) => {
    const response = TrainzaApi.getWorkoutTypeList(isAuthenticated, search);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setWorkoutList(response?.data?.workoutTypeList);
      }
    });
  };

  useEffect(() => {
    handleWorkoutTypeList("");
  }, []);

  // =====function for select workout type======
  const handleWorkout = (e) => {
    setValue("WORKOUT_TYPES", e.target.value);
    setWorkoutType(e.target.value);
  };

  // =====Function to handle input changes======
  const handleInputChange = (e) => {
    if (formState.errors.WORKOUT_TITLE) {
      clearErrors("WORKOUT_TITLE");
    }
    handleKeyPress(e);
    setWorkoutTitle(e.target.value);
  };

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  function determineDataType(data) {
    if (typeof data === "string") {
      return "string";
    }
    if (
      data instanceof ArrayBuffer ||
      data instanceof Blob ||
      data instanceof Uint8Array
    ) {
      return "binary";
    }

    return "unknown";
  }

  const fetchImageAsBinary = async (url) => {
    setIsLoading(true);
    try {
      const imageFile = await fetchImageAsFile(
        `${process.env.REACT_APP_BASE_URL}/proxy?url=${url}`,
        "myImage.jpg"
      );
      setIsLoading(false);
      return imageFile;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  // ======  function for calling save workout =====
  const OnSubmits = async (formdata) => {
    setIsLoading(true)
    const PostData = new FormData();
    if (workOutId) {
      PostData.append("workoutId", workOutId);
    }
    PostData.append("title", workoutTitle);
    PostData.append("type", workoutType);
    PostData.append("details", value);
    PostData.append("videoLink", formdata.ADD_VEDIO ? formdata.ADD_VEDIO : "");
    for (var i = 0; i < selectedImage?.length; i++) {
      var isSelectedImgBInary = determineDataType(selectedImage[i]);

      if (isSelectedImgBInary !== "binary") {
        const imageFile = await fetchImageAsBinary(selectedImage[i]);
        PostData.append("image", imageFile);
      } else {
        PostData.append("image", selectedImage[i]);
      }
    }
    if (selectedImage?.length < 1 || !selectedImage) {
      PostData.append("image", "");
    }

    PostData.append("isActive", activeWorkout ? 1 : 0);
    TrainzaApi.addWorkOut(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          setWorkOutId("");

          if (workOutId) {
            Toster(t("WORKOUT_UPDATED_SUCCUSFULLY"), "success");
            setShowAdd(false);
          } else {
            if (addManually) {
              handleWorkoutDetailToShow(response?.data?.id);
              const newObj = {
                id: response?.data?.id,
                title: workoutTitle,
                status: activeWorkout ? 1 : 0,
              };
              workoutList123?.push(newObj);
              setAddManually(false);
              setWorkOutId(response?.data?.id);
              setWorkoutType123(response?.data?.id);
              setWorkoutType(response?.data?.id);
              setWhichScreen(CONSTANT.GET_DETAIL);

              Toster(t("WORKOUT_ADD_SUCCUSFULLY"), "success");
            } else {
              setShowAdd(false);
              Toster(t("WORKOUT_ADD_SUCCUSFULLY"), "success");
            }
          }
        } else if (response?.data?.code === STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //===== function for calling workout type list api====
  const handleWorkoutDetail = () => {
    const response = TrainzaApi.getWorkoutDetail(isAuthenticated, workOutId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setWorkoutDetail(response?.data);
        setWorkoutType(response?.data?.typeId);
      }
    });
  };

  useEffect(() => {
    if (workOutId) {
      handleWorkoutDetail();
    }
  }, [workOutId]);

  //===== function for show data prefilled=====
  useEffect(() => {
    if (workOutId) {
      setWorkoutTitle(workoutDetail.title);
      setValue("WORKOUT_TITLE", workoutDetail.title);
      setWorkoutType(workoutDetail.typeId);
      setValue("WORKOUT_TYPES", workoutDetail.typeId);
      setValue("WORKOUT_DETAIL", workoutDetail?.details);
      setValue("ADD_VEDIO", workoutDetail.videoLink);
      setActiveWorkout(workoutDetail.status);
      if (workoutDetail.images && workoutDetail.images.length > 0) {
        const default_images = workoutDetail?.images?.map((item) => {
          return item.image;
        });
        setSelectedImage(default_images);
        setImagePreview(default_images);
      }
    }
  }, [workoutDetail]);

  useEffect(() => {
    setValue("WORKOUT_TYPES", workoutType);
  }, [workoutType, workoutList]);

  useEffect(() => {
    setIsButtonDisabled(!(workoutTitle && workoutType && value));
  }, [workoutTitle, workoutType, value]);

  const handleDeleteWorkout = () => {
    const response = TrainzaApi.deleteWorkoutList(isAuthenticated, workOutId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code == STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code == STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("WORKOUT_DELETE_SUCCESSFULLY"), "success");
        setShowDeleteWorkout(false);
        setShowAdd(false);
        setWorkOutId();
      } else if (response?.data.code == STATUS_CODE.VALIDATION_ERROR) {
        Toster(
          t(
            "THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDeleteWorkout(false);
      } else if (
        response?.data.code ==
        STATUS_CODE.THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION
      ) {
        Toster(
          t(
            "THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDeleteWorkout(false);
      } else {
        setShowDeleteWorkout(false);
      }
    });
  };

  //====== handle scroller in case of error=====
  const deatailRef = useRef(null);
  useEffect(() => {
    if (
      !formState.errors.WORKOUT_TITLE &&
      !formState.errors.WORKOUT_TYPES &&
      formState.errors.WORKOUT_DETAIL
    ) {
      deatailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.WORKOUT_DETAIL]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.headingAddMember}>
        {workOutId ? (
          <p>
            {t("UPDATE_WORKOUT")} <span> {t("ADD_NEW_TEXT")}</span>
          </p>
        ) : (
          <p>
            {t("ADD_NEW_WORKOUT")} <span> {t("ADD_NEW_TEXT")}</span>
          </p>
        )}
      </div>

      <div className={styles.addForm}>
        <Form onSubmit={handleSubmit(OnSubmits)}>
          <div className={styles.addSection}>
            <CustomInputField
              styleName="inputTextForAddWork"
              type="text"
              PlaceHolder={t("ADD_WORKOUT_TITLE")}
              RegisterName={"WORKOUT_TITLE"}
              register={register}
              formState={formState}
              value={workoutTitle}
              onChange={handleInputChange}
            >
              <p>
                {" "}
                {t("WORKOUT_TITLE_TEXT")} {""} {""}
                <span style={{ fontWeight: "500" }}>
                  {t("WORKOUT_TITLE_REQUIRED")}
                </span>{" "}
                {t("LABEL_REQUIRED")}
              </p>
            </CustomInputField>

            <CustomSelect
              Label={
                <p>
                  {t("WORKOUT_TITLE_TEXT")} <span>{t("TYPE_")}</span> (
                  {t("REQUIRED_")})
                </p>
              }
              styleName="selectOptionWorkout"
              RegisterName={"WORKOUT_TYPES"}
              register={register}
              formState={formState}
              onChangeHandler={handleWorkout}
              defaultKey="Select Workout Type"
              arrayData={workoutList}
              disabled={workoutList?.length === 0}
              defaultValue=""
            ></CustomSelect>

            <Form.Group className={styles.formInput} ref={deatailRef}>
              <Form.Label>
                <p>
                  {t("WORKOUT_TITLE_TEXT")} <span>{t("DETAILS")}</span>{" "}
                  {t("LABEL_REQUIRED")}
                </p>
              </Form.Label>
              <ReactQuill
                theme="snow"
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                placeholder={t("DETAILS_OF_WORKOUT")}
                className="brandTextEditor"
                ref={ref}
                modules={modules}
                formats={formats}
              />
              <div className="validateError">
                {error && <p className="error-message">{error.message}</p>}
              </div>
            </Form.Group>

            <Form.Group className={styles.formInputVideo}>
              <Form.Label>
                <p>
                  <RiVideoFill
                    size={20}
                    style={{ cursor: "pointer" }}
                  />
                  <span>{t("VIDEO_")}</span>
                </p>
              </Form.Label>
              <h4>{t("ADD_OPTIONAL_VIDEO_FOR_YOUR_WORKOUT")}</h4>
              <div className={styles.videoUpload}>
                <input
                  type="file"
                  accept="video/*"
                  ref={fileInputRef}
                  onChange={handleVideoSelect}
                  style={{ display: "none" }}
                />
                {selectedVideo && (
                  <div className={styles.videoPreview}>
                    <span>{selectedVideo.name}</span>
                  </div>
                )}
              </div>
            </Form.Group>

            <CustomInputField
              styleName="inputTextForAddWork"
              type="text"
              PlaceHolder={t("ADD_VIDEO_LINK")}
              RegisterName={"ADD_VEDIO"}
              register={register}
              formState={formState}
            >
              <p>
                {" "}
                {t("LINK_TO")} {""} {""}
                <span style={{ fontWeight: "500" }}>{t("VIDEO_")}</span>{" "}
              </p>
            </CustomInputField>

            <Form.Group className={styles.formInputVideo}>
              <Form.Label>
                <p>
                  <FaImage
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => imageInputRef.current.click()}
                  />
                  <span>{t("IMAGE_UPLOAD")}</span>
                </p>
              </Form.Label>

              <h4>{t("ADD_OPTIONAL_IMAGE_FOR_YOUR_WORKOUT")}</h4>
              <div className={styles.imageUpload}>
                <input
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  onChange={handleImageSelect}
                  style={{ display: "none" }}
                  multiple
                />
                {selectedImage && selectedImage?.length > 0 && (
                  <>
                    <Row>
                      {imagePreview?.map((preview, index) => (
                        <Col lg={4} md={3} xs={12}>
                          <div key={index} className={styles.showImagePreview}>
                            <img
                              src={preview}
                              alt={`Selected ${index}`}
                              className={styles.workoutIMg}
                            />

                            <img
                              src={CrossImg}
                              alt="CrossImg"
                              className={styles.CrossImg}
                              onClick={() => handleRemoveImage(index)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}

                <CustomButton
                  variant="footerBtnCustom"
                  type="button"
                  onClick={() => imageInputRef.current.click()}
                >
                  <p>
                    {t("UPLOAD_IMAGE")} <span>{t("IMAGE")}</span>
                  </p>
                </CustomButton>
              </div>
            </Form.Group>

            <div className={styles.devider}></div>

            <div className={styles.switchCase}>
              <div className={styles.switchCaseInfo}>
                <p>
                  {t("ACTIVE_INACTIVE")} <span>{t("WORKOUT")}</span>
                </p>
                <h3>{t("SWITCH_HEADING")}</h3>
              </div>

              <Form.Check
                type="switch"
                id="custom-switch"
                className={styles.switchButton}
                checked={activeWorkout}
                onChange={() => {
                  setActiveWorkout(!activeWorkout);
                }}
              />
            </div>

            <div className={styles.devider}></div>
          </div>

          <div className={styles.addWorkoutBtn}>
            <div className={styles.bottomBtn}>
              <div>
                <CustomButton
                  variant="cancelBtn"
                  type="button"
                  onClick={() => {
                    if (addManually) {
                      setAddManually(false);
                      setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                    } else {
                      setWorkOutId("");
                      setShowAdd(false);
                    }
                  }}
                >
                  {t("CENCEL")}
                </CustomButton>
                <CustomButton
                  variant="cancelBtn"
                  type="button"
                  opacity={!workOutId ? CONSTANT.OPACITY_RATIO : ""}
                  disabled={!workOutId}
                  onClick={() => {
                    if (workOutId) {
                      setShowDeleteWorkout(true);
                    }
                  }}
                >
                  <RiDeleteBin6Line size="20" style={{ marginRight: "5px" }} />
                  {t("DELETE")}
                </CustomButton>
              </div>
              <CustomButton
                variant="footerBtnCustom"
                onClick={handleSubmit(OnSubmits)}
                type="button"
                disabled={isButtonDisabled} 
              >
                <p>
                  {t("SAVE")} <span>{t("WORKOUT")}</span>
                </p>
              </CustomButton>{" "}
            </div>
          </div>
        </Form>
      </div>
      <DeleteWorkoutTypes
        show={showDeleteWorkout}
        setShowDelete={setShowDeleteWorkout}
        handleWorkoutDelete={handleDeleteWorkout}
      />
    </div>
  );
}

export default AddWorkout;
