import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MembersPage.module.css";
import PlusIcon from "../../Assests/Images/PlusIcon.svg";
import Members from "./Members";
import Invites from "./Invites";
import AddDetail from "./AddDetail";
import { MEMBERS } from "../../Utils/MockData";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import SearchBar from "../../ReactBootstrap/SearchBar";
import { useLocation } from "react-router-dom";

function MembersPage() {
  const { t } = useTranslation();

  const location = useLocation();

  const isAdded = location?.state?.isAdded;

  useEffect(() => {
    if (isAdded) {
      setShowtab(3);
    }
  }, [isAdded]);

  const [showtab, setShowtab] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [memberLimit, setMemberLimit] = useState();
  const [totalCount, setTotalCount] = useState();

  const [isDataPresent, setIsDataPresent] = useState(false);

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 1
          ? t("MEMBERS")
          : showtab === 2
          ? t("MEMBERS_INVITES")
          : t("MEMBERS_ADD_NEW")}
      </p>
      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => {
              setShowtab(1);
              setSearchKeyword("");
            }}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("MEMBER")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(2)}
            variant={showtab === 2 ? "primary" : "secondary"}
          >
            {t("INVITES")}
          </CustomTabButton>

          <CustomImgButton
            variant="primary"
            onClick={() => setShowtab(3)}
            src={PlusIcon} // Replace with your icon path
            alt="PlusIcon"
            // disabled={totalCount >= memberLimit}
          ></CustomImgButton>
        </div>

        {showtab === 1 && (
          <SearchBar
            placeholder={t("SEARCH_")}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            disable={isDataPresent}
            opacity={isDataPresent ? "40%" : ""}
          ></SearchBar>
        )}
      </div>
      <div className={styles.devider}></div>

      {/* =====TABS======== */}
      {showtab === 1 ? (
        <Members
          searchKeyword={searchKeyword}
          isDataPresent={isDataPresent}
          setIsDataPresent={setIsDataPresent}
          memberLimit={memberLimit}
          setMemberLimit={setMemberLimit}
          setShowtab={setShowtab}
          showtab={showtab}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
        />
      ) : showtab === 2 ? (
        <Invites />
      ) : (
        <AddDetail setShowtab={setShowtab} memberLimit={memberLimit} />
      )}

      {/* =========== */}
      <h1 className={styles.memberLimitHead}>
        {/* {memberLimit} {t("MEMBER_LIMIT")} */}
        {showtab === 1
          ? `${memberLimit} ${t("MEMBER_LIMIT")}`
          : `${memberLimit} ${t("ACTIVE_MEMBER_LIMIT")}`}
      </h1>
      <p className={styles.optionLabel}>{t("UPGRADE_CANCEL")}</p>
      <p className={styles.manageHead}>{t("MANAGE_SUBSCRIPTION_TO_MANAGE")} </p>
      <button
        className={styles.manageSubscription}
        onClick={() => window.open("http://www.trainza.co.za/")}
        type="button"
      >
        <p>
          {t("MANAGE")} <span>{t("SUBSCRIPTION")}</span>
        </p>
      </button>
    </div>
  );
}

export default MembersPage;
