import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-bootstrap/Modal";
import styles from "./Result.module.css";

import { useTranslation } from "react-i18next";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../Utils/MockData";
import Lessthan from "../../../Assests/Images/Lessthan.png";
import Greatethan from "../../../Assests/Images/Greatethan.svg";

import Calender1 from "../../../Assests/Images/Calender1.png";
import Calender2 from "../../../Assests/Images/Calender2.png";
import { resetTimeToMidnight } from "../../../Utils/CommonFunction";

function CalenderComp({
  show,
  handleClose,
  setWeekday,
  setDay,
  setMonth,
  setYear,
  previousRef,
  nextRef,
  tileDisabled,
  selectedDate,
  eventData,
}) {
  const { t } = useTranslation();
  const currentDate = new Date();

  const dateObject = new Date(selectedDate);

  const [value, onChange] = useState(selectedDate ? dateObject : new Date());

  const [view, setView] = useState("month");

  const date = new Date(selectedDate); // Create a Date object
  const selectedDateYear = date?.getFullYear(); // Extract the year
  const [currentMonthName, setCurrentMonthName] = useState("");

  const [currentYearName, setCurrentYearName] = useState("");

  useEffect(() => {
    if (show) {
      // When the calendar is opened, set the current year and month
      const initialDate = selectedDate ? new Date(selectedDate) : new Date();
      setCurrentMonthName(
        initialDate.toLocaleDateString("en-US", { month: "long" })
      );
      setCurrentYearName(initialDate.getFullYear());

      // Set the value to the initial date
      onChange(initialDate);
    }
  }, [show, selectedDate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formattedDate = formatDate(currentDate);

  // const handleViewChange = useCallback(({ activeStartDate, view }) => {

  //   console.log("activeStartDate",activeStartDate)
  //   setView(view);
  //   if (view === "month") {
  //     const monthName = activeStartDate.toLocaleDateString("en-US", {
  //       month: "long",
  //     });
  //     setCurrentMonthName(monthName);
  //   }
  // }, []);

  const handleViewChange = useCallback(
    ({ activeStartDate, view }) => {
      setView(view);
      // Handle month view
      if (view === "month") {
        const monthName = activeStartDate.toLocaleDateString("en-US", {
          month: "long",
        });
        const year = activeStartDate.getFullYear(); // Get the year
        setCurrentMonthName(monthName);
        setCurrentYearName(year); // Assuming you have a state to set the current year
      }

      // Handle year view or other views
      if (view === "year") {
        const year = activeStartDate.getFullYear();
        setCurrentYearName(year); // Set the year for year view
      }
    },
    [show]
  );

  const goToPreviousMonth = () => {
    const previousDate = selectedDate
      ? new Date(selectedDate)
      : new Date(value);
    previousDate.setDate(previousDate.getDate() - 1); // Go back by one day
    const previousDateReset = resetTimeToMidnight(previousDate);
    // Disable going to a date before today
    if (previousDateReset >= resetTimeToMidnight(currentDate)) {
      onChange(previousDate);
      const previousDateWeekday = previousDate.toLocaleString("default", {
        weekday: "long",
      });
      const previousDateName = previousDate.toLocaleString("default", {
        month: "long",
      });
      const previousDateYear = previousDate.getFullYear();
      const previousDateDay = previousDate.getDate();

      setWeekday(previousDateWeekday);
      setDay(previousDateDay);
      setMonth(previousDateName);
      setYear(previousDateYear);
    }
  };

  const goToNextMonth = () => {
    const nextDate = selectedDate ? dateObject : new Date(value);
    nextDate.setDate(nextDate.getDate() + 1);
    onChange(nextDate);
    const nextDateWeekday = nextDate.toLocaleString("default", {
      weekday: "long",
    });
    const nextDateName = nextDate.toLocaleString("default", {
      month: "long",
    });
    const nextDateYear = nextDate.getFullYear();
    const nextDateMonth = nextDate.getMonth() + 1;
    const nextDateDay = nextDate.getDate();

    setWeekday(nextDateWeekday);
    setDay(nextDateDay);
    setMonth(nextDateName);
    setYear(nextDateYear);
  };

  React.useImperativeHandle(previousRef, () => ({
    handleCallPrevious: goToPreviousMonth,
  }));

  React.useImperativeHandle(previousRef, () => ({
    handleCallPrevious: goToPreviousMonth,
  }));

  React.useImperativeHandle(nextRef, () => ({
    handleCallNext: goToNextMonth,
  }));

  const handleDateClick = (date) => {
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();
    setWeekday(weekday);
    setDay(day);
    setMonth(month);
    setYear(year);

    if (weekday && day && month && year) {
      handleClose();
    }
  };

  // Handle "Go to today"
  const goToToday = () => {
    const today = new Date();
    onChange(today);
    setWeekday(DAYS_OF_WEEK[today.getDay()]);
    setDay(today.getDate());
    setMonth(MONTH_OF_YEAR[today.getMonth()]);
    setYear(today.getFullYear());
    handleClose();
  };

  useEffect(() => {
    if (selectedDate) {
      const predefautDate = new Date(selectedDate);
      onChange(predefautDate);
    }
  }, [show]);

  useEffect(() => {
    const monthName = value.toLocaleDateString("en-US", { month: "long" });
    setCurrentMonthName(monthName);
  }, [value]);

  var dateString = selectedDate;
  var month = MONTH_OF_YEAR[Number(dateString?.substring(5, 7)) - 1];

  //==== function for handle privious and next button====
  const isPreviousDisabled =
    resetTimeToMidnight(value) <= resetTimeToMidnight(currentDate) ||
    (selectedDate &&
      resetTimeToMidnight(new Date(selectedDate)) <=
        resetTimeToMidnight(currentDate));

  const isNextDisabled =
    selectedDate &&
    resetTimeToMidnight(new Date(selectedDate)) <
      resetTimeToMidnight(currentDate);

  // ====Allow the "Today" button if it's not today already=====
  const isTodayDisabled =
    resetTimeToMidnight(value) === resetTimeToMidnight(currentDate);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="dateModal">
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            onClickDay={handleDateClick}
            tileDisabled={tileDisabled}
            onActiveStartDateChange={handleViewChange}
          />
          {/* {currentMonthName === month && currentYearName == selectedDateYear
            ? view === "month" && (
                <div className={styles.modalBtnSection}>
                  <button
                    onClick={goToPreviousMonth}
                    className={styles.previousOrNext}
                    disabled={isPreviousDisabled}
                    style={{
                      opacity: isPreviousDisabled ? 0.5 : 1,
                      cursor: isPreviousDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <img src={Lessthan} alt="lessthan" height="19px"></img>
                    <img
                      src={Calender2}
                      alt="Calender2"
                      className={styles.calenderChange}
                    ></img>
                  </button>

                  <button
                    className={styles.showDay}
                    onClick={goToToday}
                    disabled={isTodayDisabled}
                    style={{
                      opacity: isTodayDisabled ? 0.5 : 1,
                      cursor: isTodayDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("TODAY")}
                  </button>
                  <button
                    onClick={!isNextDisabled ? goToNextMonth : null}
                    className={styles.previousOrNext}
                    style={{
                      opacity: isNextDisabled ? 0.5 : 1,
                      cursor: isNextDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <img
                      src={Calender1}
                      alt="Calender2"
                      className={styles.calenderChange}
                    ></img>
                    <img src={Greatethan} alt="greater" height="19px"></img>
                  </button>
                </div>
              )
            : ""} */}

          {(currentMonthName === month &&
            currentYearName === selectedDateYear) ||
          (currentYearName === currentDate.getFullYear() &&
            currentMonthName ===
              currentDate.toLocaleDateString("en-US", { month: "long" }))
            ? view === "month" && (
                <div className={styles.modalBtnSection}>
                  <button
                    onClick={goToPreviousMonth}
                    className={styles.previousOrNext}
                    disabled={isPreviousDisabled}
                    style={{
                      opacity: isPreviousDisabled ? 0.5 : 1,
                      cursor: isPreviousDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <img src={Lessthan} alt="lessthan" height="19px" />
                    <img
                      src={Calender2}
                      alt="Calender2"
                      className={styles.calenderChange}
                    />
                  </button>

                  <button
                    className={styles.showDay}
                    onClick={goToToday}
                    disabled={isTodayDisabled}
                    style={{
                      opacity: isTodayDisabled ? 0.5 : 1,
                      cursor: isTodayDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("TODAY")}
                  </button>
                  <button
                    onClick={!isNextDisabled ? goToNextMonth : null}
                    className={styles.previousOrNext}
                    style={{
                      opacity: isNextDisabled ? 0.5 : 1,
                      cursor: isNextDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <img
                      src={Calender1}
                      alt="Calender2"
                      className={styles.calenderChange}
                    />
                    <img src={Greatethan} alt="greater" height="19px" />
                  </button>
                </div>
              )
            : null}
        </div>
      </Modal>
    </>
  );
}

export default CalenderComp;
