import React, { useEffect, useState } from "react";
import styles from "./AddEventResult.module.css";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Form from "react-bootstrap/Form";
import SelectImg from "../../../../Assests/Images/SelectImg.svg";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../../Utils/MockData";

import {
  calculatePace,
  capitalizeFirstLetter,
  formatTimeInCorrectFormat,
  removeLeadingZeros,
} from "../../../../Utils/CommonFunction";
import { useForm } from "react-hook-form";

import { CONSTANT } from "../../../../Utils/Constant";
import { STATUS_CODE } from "../../../../Utils/StatusCode";
import { userLogoutClear } from "../../../../redux/slices/userSlice";
import TrainzaApi from "../../../../Helpers/Api";
import Toster from "../../../../Common/Toster/Toster";
import Loader from "../../../../Common/Loader/Loader";
import { ValidationSchema } from "../../../../Utils/ValidationSchema";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";

function AddEventResult({
  setShowAdd,
  eventId,
  resultId,
  memberName,
  setResultId,
  handleGetResult,
}) {
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [distanceList, setDistanceList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const [eventDistanceName, setEventDistanceName] = useState("");

  const [hrs, setHrs] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(0);

  const [pace, setPace] = useState("");

  const currentDate = new Date();

  useEffect(() => {
    setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const handleCangeHrs = (e) => {
    const inputValue = e.target.value;

    // const cleanedValue = inputValue.replace(/^0+/, "");

    // const newValue = cleanedValue.replace(".", "");

    if (inputValue < 1000) {
      setHrs(inputValue);
    }
  };

  const handleCangeMins = (e) => {
    let inputValue = e.target.value.trim();
    if (inputValue < 60) {
      if (inputValue == 0) {
        setMins(0);
      } else {
        if (/^\d{2}$/.test(inputValue)) {
          setMins(inputValue);
          return;
        }

        if (/^\d+$/.test(inputValue)) {
          inputValue = parseInt(inputValue, 10).toString();
        }

        if (inputValue === "") {
          setMins("");
          return;
        }

        const intValue = parseInt(inputValue, 10);

        if (intValue >= CONSTANT.MIN_MIN && intValue <= CONSTANT.MAX_MIN) {
          const formattedValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
          setMins(formattedValue);
        } else {
          console.log("Value out of range or invalid");
        }
      }
    }
  };

  const handleCangeSecs = (e) => {
    let inputValue = e.target.value.trim();
    if (inputValue < 60) {
      if (inputValue == 0) {
        setSecs(0);
      } else {
        if (/^\d{2}$/.test(inputValue)) {
          setSecs(inputValue);
          return;
        }

        if (/^\d+$/.test(inputValue)) {
          inputValue = parseInt(inputValue, 10).toString();
        }

        if (inputValue === "") {
          setSecs("");
          return;
        }

        const intValue = parseInt(inputValue, 10);

        if (intValue >= CONSTANT.MIN_SEC && intValue <= CONSTANT.MAX_SEC) {
          const formattedValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
          setSecs(formattedValue);
        } else {
          console.log("Value out of range or invalid");
        }
      }
    }
  };

  const handleGetMemberDistanceData = () => {
    const response = TrainzaApi.GetEventMemberDistance(
      isAuthenticated,
      eventId
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setDistanceList(response?.data?.distances);
        setMemberList(response?.data?.members);
      }
    });
  };

  const handleGetMemberDistanceDetail = () => {
    const response = TrainzaApi.DeleteEventResultDetail(
      isAuthenticated,
      eventId,
      resultId
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setDistanceList(response?.data?.event?.distances);
        setTimeout(() => {
          setValue("DISTANCE", response?.data?.distanceId);
          setValue("MEMBER", response?.data?.memberId);
        }, 1000);

        let timeArray = response?.data?.result?.split(":");
        let hrs = parseInt(timeArray[0]); // hours as integer
        let mins = parseInt(timeArray[1]); // minutes as integer
        let secs = parseInt(timeArray[2]); // seconds as integer
        setHrs(hrs);
        setMins(mins);
        setSecs(secs);
        setPace(response?.data?.pace);

        let id = response?.data?.distanceId;
        let distance = response?.data?.event?.distances;
        let desiredDistance = distance.find((item) => item?.id === id);
        setEventDistanceName(desiredDistance?.distance);
      }
    });
  };

  useEffect(() => {
    if (!resultId) {
      handleGetMemberDistanceData();
    } else {
      handleGetMemberDistanceDetail();
    }
  }, []);

  useEffect(() => {
    const pace = `${calculatePace(
      `${hrs}:${mins}:${secs}`,
      eventDistanceName
    )} p/km`;
    setPace([pace]);
  }, [hrs, mins, secs, eventDistanceName]);

  function OnSubmit(data) {
    //   <div className="validateErrorResult">
    //   {pace[0] === CONSTANT.UNDEFINED_PACE ||
    //   pace[0] === "0:00 p/km"
    //     ? t("ENTER_TIME")
    //     : ""}
    // </div>
    if (pace[0] === CONSTANT.UNDEFINED_PACE || pace[0] === "0:00 p/km") {
      Toster("Please enter time", "error");
    }

    const PostData = new FormData();

    if (resultId) {
      PostData.append("eventResultId", resultId);
    }
    PostData.append("memberId", data.MEMBER);
    PostData.append("distanceId", data.DISTANCE);
    PostData.append(
      "time",
      formatTimeInCorrectFormat(`${hrs}:${mins}:${secs}`)
    );

    PostData.append("pace", pace);

    if (pace[0] !== CONSTANT.UNDEFINED_PACE && pace[0] !== "0:00 p/km") {
      setIsLoading(true);
      TrainzaApi.AddEventResultData(isAuthenticated, PostData, eventId).then(
        (response) => {
          setIsLoading(false);

          const ResponseCode = STATUS_MSG[response?.data?.code];

          if (
            response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            response?.data?.code === STATUS_CODE.SESSION_EXPIRED
          ) {
            dispatch(userLogoutClear());
            Toster(t("SESSION_EXPIRED"), "error");
          } else {
            if (response?.code === STATUS_CODE.SUCCESS) {
              if (resultId) {
                Toster(t("RESULT_UPDATED"), "success");
              } else {
                Toster(t("RESULT_ADDED"), "success");
              }

              handleGetResult();
              reset();
              setMins();
              setHrs();
              setSecs();
              if (resultId) {
                setResultId("");
              }

              setShowAdd(false);
            } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
              Toster(response?.data?.message, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        }
      );
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.scheduleDate}>
            <p>
              <span>{resultId ? "Update Result" : "Add Result"}</span>
            </p>
          </div>
        </div>
      </div>
      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div className={styles.fields}>
          {resultId ? (
            <Form.Group className={styles.inputArea}>
              <Form.Label>
                <div className={styles.inputLabel}>
                  <p>
                    {t("SELECT")} <span>{t("MEMBER_SMALL")}</span>
                  </p>
                </div>
              </Form.Label>
              <Form.Select className={styles.inputSelect} disabled={true}>
                <option value="" defaultValue>
                  {memberName}
                </option>
              </Form.Select>
            </Form.Group>
          ) : (
            <Form.Group className={styles.inputArea}>
              <Form.Label>
                <div className={styles.inputLabel}>
                  <p>
                    {t("SELECT")} <span>{t("MEMBER_SMALL")}</span>
                  </p>
                </div>
              </Form.Label>
              <Form.Select
                className={styles.inputSelect}
                {...register("MEMBER", ValidationSchema.MEMBER)}
                isInvalid={!!formState.errors.MEMBER}
                onChange={(e) => {
                  if (formState.errors.MEMBER) {
                    clearErrors("MEMBER");
                  }
                  setValue("MEMBER", e.target.value);
                }}
                disabled={resultId}
              >
                <option value="" defaultValue>
                  {t("SELECT")} {t("MEMBER_SMALL")}
                </option>
                {memberList?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {capitalizeFirstLetter(item.firstName)}{" "}
                      {item.lastName ? item.lastName : ""}
                    </option>
                  );
                })}
              </Form.Select>
              <img src={SelectImg} alt="SelectImg"></img>

              <Form.Control.Feedback type="invalid">
                {t(formState.errors.MEMBER?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group className={styles.inputArea}>
            <Form.Label>
              <div className={styles.inputLabel}>
                <p>
                  {t("SELECT")} <span>{t("DISTANCE")}</span>
                </p>
              </div>
            </Form.Label>
            <Form.Select
              className={styles.inputSelect}
              {...register("DISTANCE", ValidationSchema.DISTANCE)}
              isInvalid={!!formState.errors.DISTANCE}
              onChange={(e) => {
                const distanceId = e.target.value;
                const foundObject = distanceList?.find(
                  (item) => item?.id === distanceId
                );
                const distance = foundObject?.distance;
                if (formState.errors.DISTANCE) {
                  clearErrors("DISTANCE");
                }
                setEventDistanceName(distance);
                setValue("DISTANCE", distanceId);
              }}
            >
              <option value="" defaultValue>
                {t("DISTANCE_SELECT")}
              </option>
              {distanceList?.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.distance} {item.unit === 1 ? t("KM") : t("MI")}
                  </option>
                );
              })}
            </Form.Select>
            <img src={SelectImg} alt="SelectImg"></img>
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.DISTANCE?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <div className={styles.timeAndPace}>
            <div>
              <p className={styles.timeHeading}>{t("TIME_")}</p>
              <div className={styles.inputBox}>
                <div className={styles.inputContainer}>
                  <input
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={hrs === "" ? 0 : removeLeadingZeros(hrs)}
                    onChange={(e) => {
                      handleCangeHrs(e);
                    }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "-" ||
                    //     e.key === "+" ||
                    //     e.key === "." ||
                    //     e.key === "e" ||
                    //     e.key === "E"
                    //   ) {
                    //     e.preventDefault();
                    //   }
                    // }}
                    // onPaste={(e) => {
                    //   e.preventDefault();
                    //   const pastedData = e.clipboardData.getData("text");
                    //   if (/^\d*$/.test(pastedData)) {
                    //     const input = e.target;
                    //     const cursorPosition = input.selectionStart;
                    //     const newValue =
                    //       input.value.slice(0, cursorPosition) +
                    //       pastedData +
                    //       input.value.slice(cursorPosition);
                    //     handleCangeHrs({ target: { value: newValue } });
                    //   }
                    // }}
                  ></input>
                  <p>{t("HOURS")} </p>
                </div>

                <p
                  style={{
                    color: "#6C757D",
                    margin: "0px 5px",
                    fontSize: "20px",
                  }}
                >
                  :
                </p>

                <div className={styles.inputContainer}>
                  <input
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={mins}
                    onChange={(e) => {
                      handleCangeMins(e);
                    }}
                  ></input>
                  <p>{t("MINUTES")} </p>
                </div>

                <p
                  style={{
                    color: "#6C757D",
                    margin: "0px 5px",
                    fontSize: "20px",
                  }}
                >
                  :
                </p>

                <div className={styles.inputContainer}>
                  <input
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={secs}
                    onChange={(e) => {
                      handleCangeSecs(e);
                    }}
                  ></input>
                  <p>{t("SECONDS")} </p>
                </div>

                {/* <div className="validateErrorResult">
                  {pace[0] === CONSTANT.UNDEFINED_PACE ||
                  pace[0] === "0:00 p/km"
                    ? t("ENTER_TIME")
                    : ""}
                </div> */}
              </div>
            </div>

            <div className={styles.pace}>
              <p>{t("PACE")}</p>
              <input
                disabled={true}
                value={pace[0] !== CONSTANT.UNDEFINED_PACE ? pace : ""}
              ></input>
            </div>
          </div>
        </div>

        <div className={styles.scheduleFooter}>
          <div className={styles.cancelOrDelete}>
            <button
              type="button"
              onClick={() => {
                if (resultId) {
                  setResultId("");
                } else {
                  setShowAdd(false);
                }
              }}
            >
              {t("CENCEL")}
            </button>

            {/* <button type="button">{t("DELETE")}</button> */}
          </div>
          <div className={styles.saveButton}>
            <button type="submit">{resultId ? t("UPDATE") : t("SAVE")}</button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default AddEventResult;
