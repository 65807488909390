import React, { useEffect, useRef, useState } from "react";
import styles from "./MembersPage.module.css";
import { Form, Row, Col } from "react-bootstrap";
import SelectImg from "../../Assests/Images/SelectImg.svg";
import ProfileDemo from "../../Assests/Images/ProfileDemo.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CropImg from "../../Common/Crop/CropImg";
import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import { CONSTANT } from "../../Utils/Constant";
import TrainzaApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Calender from "../../Assests/Images/Calender.svg";
import mime from "mime";
import {
  b64toBlob,
  cleanData,
  convertServerDataInOurArray,
  fetchImageAsFile,
  getRandom,
  manipulatePlus,
  transformTimeArray,
} from "../../Utils/CommonFunction";
import Loader from "../../Common/Loader/Loader";
import CalenderComp from "./MemberDetail/CalenderComp";
import { MONTH_CODE, MONTH_NO_CODE } from "../../Utils/MonthCode";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../Utils/MockData";
import DeleteResult from "./MemberDetail/DeleteResult";
import CalenderBackup from "./MemberDetail/CalenderBackup";
import { MASKING } from "../../Utils/PhoneMasking";
import MemberLimit from "./MemberDetail/MemberLimit";
import MemberCalenderComp from "./MemberDetail/MemberAddCalender";

function AddDetail({
  setShowtab,
  member_id,
  isMemberUpdate,
  setIsMemberUpdate,
  setProfileImg,
  setIsMemberValid,
  memberLimit,
}) {
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const today = new Date().toISOString().split("T")[0];

  const [minDate, setMinDate] = useState();

  function getNextYearDate() {
    const currentDate = new Date();
    const nextYearDate = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return nextYearDate.toISOString().split("T")[0];
  }

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const currentUserId = useSelector((state) => state.user.userId);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [defaultStatus, setDefaultStatus] = useState("1");

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [noCropImg, setNoCropImg] = useState(null);
  const [isCropper, setIsCropper] = useState(false);

  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState(27);
  const [phoneCountryCode, setPhoneCountryCode] = useState("za");

  const [defaultphone, setDefaultphone] = useState("");
  const [defaultCode, setDefaultCode] = useState("27");
  const [defaultCountry, setDefaultCountry] = useState("Za");

  const [grpList, setGrpList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [heightList, setHeightList] = useState([]);
  const [weightList, setWeightList] = useState([]);
  const [distanceList, setDistanceList] = useState([]);
  const [selectedGrp, setSelectedGrp] = useState([]);
  const [isEmailShare, setIsEmailShare] = useState(0);
  const [isPhoneShare, setIsPhoneShare] = useState(0);
  const [isProfileRemove, setIsProfileRemove] = useState(1);
  const [isMembershipActive, setIsMembershipActive] = useState(0);
  const [isViewOtherMember, setIsViewOtherMember] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [countryset, setCountryset] = useState();
  const [stateset, setStateset] = useState();
  const [heightset, setHeightset] = useState();
  const [weightset, setWeightset] = useState();
  const [showCrop, setShowCrop] = useState(false);
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [showDate, setShowDate] = useState(false);
  const currentDate = new Date();

  var oneYearAheadDate = new Date(currentDate);
  oneYearAheadDate.setFullYear(currentDate.getFullYear() + 1);
  oneYearAheadDate.setHours(0, 0, 0, 0);

  // we set default curent date by this code
  // useEffect(() => {
  //   setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
  //   setDay(currentDate.getDate());
  //   setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
  //   setYear(currentDate.getFullYear());
  // }, []);

  useEffect(() => {
    var daySelected = day;

    if (day?.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const rightFormatOfDate = year
      ? `${year}-${MONTH_CODE[month]}-${daySelected}`
      : "";
    if (rightFormatOfDate) {
      setSelectedDate(rightFormatOfDate);
    }
  }, [day, year, month, weekday]);

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  var tileDisabledForStartDate = ({ date, view }) => {
    const minDate = new Date().setHours(0, 0, 0, 0);
    return date > minDate;
  };

  // ==================membership valid upto=========

  const [weekdayMember, setWeekdayMember] = useState("");
  const [dayMember, setDayMember] = useState("");
  const [monthMember, setMonthMember] = useState("");
  const [yearMember, setYearMember] = useState();

  const [selectedDateMember, setSelectedDateMember] = useState("");

  const [startDateMember, setStartDateMember] = useState("");

  const [showDateMember, setShowDateMember] = useState(false);

  useEffect(() => {
    setWeekdayMember(DAYS_OF_WEEK[oneYearAheadDate.getDay()]);
    setDayMember(oneYearAheadDate.getDate());
    setMonthMember(MONTH_OF_YEAR[oneYearAheadDate.getMonth()]);
    setYearMember(oneYearAheadDate.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = dayMember;

    if (dayMember?.toString()?.length < 2) {
      daySelected = `${0}${dayMember}`;
    } else {
      daySelected = dayMember;
    }
    const rightFormatOfDate = `${yearMember}-${MONTH_CODE[monthMember]}-${daySelected}`;

    if (rightFormatOfDate) {
      setSelectedDateMember(rightFormatOfDate);
    }
  }, [dayMember, yearMember, monthMember, weekdayMember]);

  const handleShowDateMember = () => setShowDateMember(true);

  const handleCloseDateMember = () => setShowDateMember(false);

  // var tileDisabledForStartDateMember = ({ date, view }) => {
  //   // Get the current date
  //   const today = new Date();
  //   // Set minimum selectable date to exactly one year from today
  //   const minSelectableDate = new Date(today);
  //   minSelectableDate.setFullYear(today.getFullYear() + 1);
  //   minSelectableDate.setHours(0, 0, 0, 0);
  //   // Disable dates before the minimum selectable date
  //   return view === "month" && date < new Date().setHours(0, 0, 0, 0);
  //   return date < minSelectableDate;
  // };

  var tileDisabledForStartDateMember = ({ date, view }) => {
    // Get the current date
    const today = new Date();
    // Set minimum selectable date to exactly one year from today
    const minSelectableDate = new Date(today);
    minSelectableDate.setFullYear(today.getFullYear() + 1);
    minSelectableDate.setHours(0, 0, 0, 0);
    // Disable dates before the minimum selectable date
    return view === "month" && date < new Date().setHours(0, 0, 0, 0);
    return date < minSelectableDate;
  };

  // =====================================

  const handleCloseCrop = () => {
    setShowCrop(false);
    fileInputRef.current.value = "";
    setIsCropper(false);
  };

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleLimit = () => {
    setShowLimit(!showLimit);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNoCropImg(file);
    setIsProfileRemove(0);

    setIsCropper(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const capitalizeWholeWord = (string) => {
    return string.toUpperCase();
  };

  const getHeightWidthDistanceMeta = () => {
    const HeightResponse = TrainzaApi.HeightWidthDistanceMeta("heightMeta");
    HeightResponse.then((res) => {
      setHeightList(res?.data?.heightMeta);
    });

    const WeightResponse = TrainzaApi.HeightWidthDistanceMeta("weightMeta");
    WeightResponse.then((res) => {
      setWeightList(res?.data?.weightMeta);
    });

    // const DistanceResponse = TrainzaApi.HeightWidthDistanceMeta("distanceMeta");
    // DistanceResponse.then((res) => {
    //   setDistanceList(res?.data?.distanceMeta);
    // });
  };

  // function for PBDISTANCE API
  const getPBdistance = () => {
    setIsLoading(true);
    const PBDistanceResponse = TrainzaApi.PBdistanceMeta();
    PBDistanceResponse.then((res) => {
      setDistanceList(res?.data?.personalBestDistanceMeta);
      setIsLoading(false);
    });
  };

  const getCountryMetaData = () => {
    const HeightResponse = TrainzaApi.countryMetaData();
    HeightResponse.then((res) => {
      setCountryList(res?.data?.countryMeta);
    });
  };

  const getStateMetaData = (id) => {
    const StateResponse = TrainzaApi.stateMetaData(id);
    StateResponse.then((res) => {
      setStateList(res?.data?.stateMeta);
    });
  };

  const handleGetGroupList = () => {
    const response = TrainzaApi.groupList(isAuthenticated, "");
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setGrpList(response?.data?.groupList);
      }
    });
  };

  useEffect(() => {
    getHeightWidthDistanceMeta();
    getCountryMetaData();
    handleGetGroupList();
    getPBdistance();
  }, []);

  const handleSelectGroup = (id) => {
    const updatedIds = selectedGrp.includes(id)
      ? selectedGrp.filter((selectedId) => selectedId !== id)
      : [...selectedGrp, id];
    setSelectedGrp(updatedIds);
  };

  const [inputValues, setInputValues] = useState(
    distanceList?.reduce((acc, item) => {
      acc[item.id] = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        createdAt: 0,
      };
      return acc;
    }, {})
  );

  const [modifyTimeArray, setModifyTimeArray] = useState([]);

  const [modifyTimeArrayWithNoChange, setModifyTimeArrayWithNoChange] =
    useState([]);

  const [isAnyChange, setIsAnyChange] = useState(false);

  const handleInputChange = (id, field, value) => {
    setIsAnyChange(true);
    const inputField = Number(value);
    if (field !== "hours") {
      if (inputField < 60) {
        let stringValue = value.toString();

        let validatedValue = Number(stringValue);

        if (
          (field === "minutes" || field === "seconds") &&
          (validatedValue < 0 || validatedValue > 59)
        ) {
          validatedValue = Math.max(0, Math.min(value, validatedValue));
        }

        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            [field]: validatedValue,
            createdAt: new Date().toISOString(),
          },
        }));
      }
    } else if (field == "hours") {
      if (value < 1000) {
        let stringValue = value.toString();

        let validatedValue = Number(stringValue);

        if (field === "hours" && (validatedValue < 0 || validatedValue > 999)) {
          validatedValue = Math.max(0, Math.min(value, validatedValue));
        }

        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            [field]: validatedValue,
            createdAt: new Date().toISOString(),
          },
        }));
      }
    }
  };

  const generateObjectArray = () => {
    return distanceList.map((item) => ({
      id: item.id,
      ...inputValues[item.id],
    }));
  };

  const modifyArray = () => {
    const objectsArray = generateObjectArray();
    const updatedArray = objectsArray
      .filter((obj) => "hours" in obj || "minutes" in obj || "seconds" in obj)
      .map((obj) => ({
        ...obj,
        hours: obj.hours || "0",
        minutes: obj.minutes || "0",
        seconds: obj.seconds || "0",
      }));

    setModifyTimeArray(updatedArray);
  };

  useEffect(() => {
    modifyArray();
  }, [inputValues]);

  const fetchImageAsBinary = async (url) => {
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const OnSubmit = async (data) => {
    setIsLoading(true);
    const DOB =
      selectedDate &&
      selectedDate !== null &&
      selectedDate !== "undefined-undefined-undefined";
    if (
      !selectedDate ||
      selectedDate == null ||
      selectedDate == "undefined-undefined-undefined"
    ) {
      dobRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      Toster("Please select DOB", "error");
      setIsLoading(false);
    }
    const PostData = new FormData();

    if (member_id) {
      PostData.append("memberId", member_id);
    }
    PostData.append("firstName", data.FIRST_NAME);
    PostData.append("lastName", data.LAST_NAME);
    PostData.append("email", data.ADD_EMAIL);
    PostData.append("isEmailPubliclyShared", isEmailShare ? 1 : 0);
    PostData.append("phoneDialCode", phoneDialCode);
    PostData.append("phoneCountryCode", phoneCountryCode);
    PostData.append("phoneNumber", phone);
    PostData.append("isNumberPubliclyShared", isPhoneShare ? 1 : 0);
    PostData.append("dateOfBirth", selectedDate);
    PostData.append("gender", data.GENDER);
    PostData.append("height", data.HEIGHT ? data.HEIGHT : "");
    PostData.append("weight", data.WEIGHT ? data.WEIGHT : "");
    PostData.append("countryMeta", data.COUNTRY);
    PostData.append("stateMeta", data.STATE);
    PostData.append("town", data.TOWN_CITY);

    var path = selectedImage;
    if (selectedImage) {
      const startsWithHtt = path.startsWith("htt");
      if (startsWithHtt) {
        path = `${process.env.REACT_APP_BASE_URL}/proxy?url=${selectedImage}`;
      } else {
        path = selectedImage;
      }
    }

    const imageFile = selectedImage ? await fetchImageAsBinary(path) : "";
    PostData.append("profilePicture", imageFile);

    var PbArray = transformTimeArray(modifyTimeArray);
    PbArray = PbArray.filter((item) => item.time !== "00:00:00");

    var PbArrayWithNoChange = transformTimeArray(modifyTimeArrayWithNoChange);
    PbArrayWithNoChange = PbArrayWithNoChange.filter(
      (item) => item.time !== "00:00:00"
    );

    PostData.append(
      "personalBests",
      isAnyChange
        ? JSON.stringify(PbArray)
        : JSON.stringify(PbArrayWithNoChange)
    );
    PostData.append(
      "brands",
      JSON.stringify([
        {
          id: currentUserId,
          membershipValidUntil: selectedDateMember,
          activeMembership: isMembershipActive
            ? JSON.stringify(1)
            : JSON.stringify(0),
          canViewOtherMember: isViewOtherMember
            ? JSON.stringify(1)
            : JSON.stringify(0),
          createdAt: "2024-05-02 14:10:12",
        },
      ])
    );
    PostData.append(
      "removeProfilePicture",
      !selectedImage && !noCropImg ? 1 : 0
    );
    // isProfileRemove
    PostData.append("status", defaultStatus ? 1 : 0);
    PostData.append("trainingGroups", JSON.stringify(selectedGrp));

    if ((!phone || phone?.length > 4) && DOB) {
      setIsLoading(true);
      TrainzaApi.AddMember(isAuthenticated, PostData).then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/sign-up");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            if (member_id) {
              Toster(t("MEMBER_UPDATED_SUCCESSFULLY"), "success");
              setIsMemberUpdate(0);
            } else {
              Toster(t("MEMBER_ADDED_SUCCESSFULLY"), "success");
              setShowtab(1);
            }
            reset();
            setPhone("");
            setPhoneDialCode(27);
            setPhoneCountryCode("za");
            setSelectedGrp([]);
            setIsEmailShare(0);
            setIsPhoneShare(0);
            setIsProfileRemove(1);
            setIsCropper(false);
            setIsMembershipActive(0);
            setIsViewOtherMember(0);
            setSelectedImage(null);
            setImageSrc(null);
            setNoCropImg(null);
            setInputValues(
              distanceList.reduce((acc, item) => {
                acc[item.id] = {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  createdAt: 0,
                };
                return acc;
              }, {})
            );
            setModifyTimeArray([]);
            // if (!member_id) {
            //   setShowtab(1);
            // }
          } else if (
            response?.data?.code == STATUS_CODE.MEMBER_LIMIT_EXCEEDED
          ) {
            setShowLimit(true);
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  const handleGetMemberDetail = () => {
    setIsLoading(true);
    const response = TrainzaApi.GetMemberDetail(isAuthenticated, member_id);
    response.then((response) => {
      setIsLoading(false);
      const clubMemberMapping = response?.data?.clubMemberMapping;
      const trainingGroupsData = response?.data?.trainingGroupsData;
      const personalBests = response?.data?.personalBests;
      const memberInfo = response?.data?.memberInfo;

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (
          response?.data?.code == STATUS_CODE.INVALID_MEMBER_ID ||
          clubMemberMapping?.length < 1
        ) {
          setIsMemberValid(false);
        } else if (
          response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
        ) {
          Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
          navigate("/members");
        } else {
          setProfileImg(memberInfo?.profilePicture);
          const convertedData = convertServerDataInOurArray(personalBests);
          const processedData = {};
          convertedData?.forEach((item) => {
            const { id, hours, createdAt, minutes, seconds } = item;
            processedData[id] = {
              hours,
              createdAt,
              minutes: parseInt(minutes),
              seconds: parseInt(seconds),
            };
          });
          setInputValues(processedData);
          setModifyTimeArray(convertedData);
          setModifyTimeArrayWithNoChange(convertedData);
          setSelectedGrp(trainingGroupsData?.map((group) => group?.groupId));
          setIsMembershipActive(
            JSON?.parse(clubMemberMapping[0]?.isMembershipActive)
          );
          setIsViewOtherMember(clubMemberMapping[0]?.canViewOtherMembers);
          setSelectedImage(memberInfo?.profilePicture);
          // setImageSrc(memberInfo?.profilePicture);
          setNoCropImg(memberInfo?.profilePicture);
          setIsEmailShare(memberInfo?.isEmailPubliclyShared);
          setIsPhoneShare(memberInfo?.isNumberPubliclyShared);
          setDefaultCode(memberInfo?.phoneDialCode);
          setDefaultCountry(memberInfo?.phoneCountryCode);
          setDefaultphone(memberInfo?.phoneNumber);
          setValue("CONTACT_NO", memberInfo?.phoneNumber);
          setValue("FIRST_NAME", memberInfo?.firstName);
          setValue("LAST_NAME", memberInfo?.lastName);
          setValue("ADD_EMAIL", memberInfo?.email);
          setYear(memberInfo?.dateOfBirth?.substring(0, 4));
          setMonth(MONTH_NO_CODE[memberInfo?.dateOfBirth?.substring(5, 7)]);
          setDay(memberInfo?.dateOfBirth?.substring(8, 10));
          setSelectedDate(memberInfo?.dateOfBirth);
          setStartDate(memberInfo?.dateOfBirth);
          setValue("GENDER", JSON.stringify(memberInfo?.gender));
          setHeightset(memberInfo?.height);
          setWeightset(memberInfo?.weight);
          setCountryset(memberInfo?.countryMeta);
          setStateset(memberInfo?.stateMeta);
          setValue("TOWN_CITY", memberInfo?.town);
          setValue("VALID_UPTO", clubMemberMapping[0]?.membershipValidUntil);
          setMinDate(clubMemberMapping[0]?.membershipValidUntil);
          setYearMember(
            clubMemberMapping[0]?.membershipValidUntil?.substring(0, 4)
          );
          setMonthMember(
            MONTH_NO_CODE[
              clubMemberMapping[0]?.membershipValidUntil?.substring(5, 7)
            ]
          );
          setDayMember(
            clubMemberMapping[0]?.membershipValidUntil?.substring(8, 10)
          );

          setSelectedDateMember(clubMemberMapping[0]?.membershipValidUntil);
          setStartDateMember(clubMemberMapping[0]?.membershipValidUntil);
          setDefaultStatus(memberInfo?.status);
        }
      }
    });
  };

  useEffect(() => {
    if (defaultCode && defaultCountry && defaultphone) {
      setPhoneDialCode(defaultCode);
      setPhoneCountryCode(defaultCountry);
      setPhone(defaultphone);
    }
  }, [defaultCode, defaultCountry, defaultphone]);

  useEffect(() => {
    if (member_id) {
      setValue("COUNTRY", countryset);
      setValue("HEIGHT", heightset);
      setValue("WEIGHT", weightset);
      getStateMetaData(countryset);
    }
  }, [countryset, heightset, weightset, countryList]);

  useEffect(() => {
    if (member_id) {
      setValue("STATE", stateset);
    } else if (stateset === "") {
      setValue("STATE", "");
    }
  }, [stateset, stateList]);

  useEffect(() => {
    if (member_id) {
      handleGetMemberDetail();
    }
  }, [member_id]);

  const handleDeleteMember = () => {
    const response = TrainzaApi.DeleteMember(isAuthenticated, member_id);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("MEMBER_DELETED"), "success");
          navigate("/members");
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const addLeadingZero = (digit) => {
    const digitStr = String(digit);

    if (digitStr?.length === 1) {
      return `0${digitStr}`;
    } else {
      return digitStr;
    }
  };

  //=== function for prevent white space====
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const numberRef = useRef(null);

  useEffect(() => {
    if (
      formState.errors.CONTACT_NO ||
      (phone?.length > 0 && phone?.length < 6)
    ) {
      numberRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.CONTACT_NO]);

  useEffect(() => {
    setPhone(phone?.replace(/-/g, ""));
  }, [phone]);

  const dobRef = useRef(null);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.headingAddMember}>
        {isMemberUpdate ? (
          <p>{t("UPDATE_MEMBER")}</p>
        ) : (
          <p>{t("ADD_NEW_MEMBER")}</p>
        )}
      </div>

      <div className={styles.devider}></div>

      <div className={styles.DetailHead}>
        <p>{t("MEMBER_SMALL")} </p>
        <h1>{t("DETAILS")}</h1>
      </div>

      <div className={styles.devider}></div>

      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div>
          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("FIRST_NAME")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("FIRST_NAME")}
              // {...register("FIRST_NAME", ValidationSchema.FIRST_NAME)}
              {...register("FIRST_NAME", {
                onChange: (e) => handleKeyPress(e),
                ...ValidationSchema.FIRST_NAME,
              })}
              isInvalid={!!formState.errors.FIRST_NAME}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.FIRST_NAME?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("SURNAME_LAST_NAME")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("SURNAME_LAST_NAME")}
              // {...register("LAST_NAME", ValidationSchema.LAST_NAME)}
              {...register("LAST_NAME", {
                onChange: (e) => handleKeyPress(e),
                ...ValidationSchema.LAST_NAME,
              })}
              isInvalid={!!formState.errors.LAST_NAME}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.LAST_NAME?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("YOUR_EMAIL_ADDRESS")}
              {...register("ADD_EMAIL", ValidationSchema.ADD_EMAIL)}
              isInvalid={!!formState.errors.ADD_EMAIL}
              disabled={!!member_id}
              style={{ cursor: !!member_id ? "not-allowed" : "text" }}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.ADD_EMAIL?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="acceptTerms">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={isEmailShare}
                onChange={() => {
                  setIsEmailShare(!isEmailShare);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {t("SHARE_PUBLICLY")}
              </label>
            </div>
          </div>

          <Form.Group className={styles.DetailInput} ref={numberRef}>
            <Form.Label>{t("CONTACT_NO")}</Form.Label>
            <p>
              {capitalizeWholeWord(phoneCountryCode)}
              {""}
              {manipulatePlus(phoneDialCode)}
            </p>

            {member_id ? (
              <PhoneInput
                {...register("CONTACT_NO")}
                // country={"za"}
                className="PhoneInputArea"
                countryCodeEditable={false}
                disabled={member_id}
                value={`${phoneDialCode} ${phone}`}
                // onChange={(value, country) => {
                //   let dialCode = country.dialCode;
                //   let phone = value.slice(dialCode.length, value.length);
                //   setPhoneCountryCode(country.countryCode);
                //   setPhoneDialCode(dialCode);
                //   setPhone(phone);
                //   setValue("CONTACT_NO", phone);
                // }}
              />
            ) : (
              <PhoneInput
                {...register("CONTACT_NO", ValidationSchema.CONTACT_NO)}
                className="PhoneInputArea"
                countryCodeEditable={false}
                defaultMask={MASKING[phoneDialCode]}
                disabled={member_id}
                value={`${phoneDialCode} ${phone}`}
                onChange={(value, country) => {
                  const newDialCode = country.dialCode;
                  const newCountryCode = country.countryCode;
                  if (newCountryCode !== phoneCountryCode) {
                    setPhone("");
                    setValue("CONTACT_NO", "");
                  } else {
                    const newPhone = value.slice(
                      newDialCode.length,
                      value.length
                    );
                    setPhone(newPhone);
                    setValue("CONTACT_NO", newPhone);
                  }

                  setPhoneCountryCode(newCountryCode);
                  setPhoneDialCode(newDialCode);
                }}
              />
            )}

            {!member_id ? (
              <div className="validateError">
                {!phone && t(formState.errors.CONTACT_NO?.message)}
                {phone?.length > 0 &&
                  phone?.length <= 4 &&
                  t("CONTACT_NO_MUST_BE_ATLEAST_5_DIGIT")}
              </div>
            ) : (
              ""
            )}

            <img
              src={SelectImg}
              alt="SelectImg"
              className={styles.phoneSelectImg}
            ></img>
          </Form.Group>

          <div className="acceptTerms">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isPhoneShare}
                id="flexCheckDefault"
                onChange={() => {
                  setIsPhoneShare(!isPhoneShare);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {t("SHARE_PUBLICLY")}
              </label>
            </div>
          </div>

          <div className={styles.datePickerBox} ref={dobRef}>
            <p>{t("DOB_REQUIRED")}</p>
            <div className={styles.dateSelection} onClick={handleShowDate}>
              <img src={Calender} alt="calender"></img>
              {/* {showDate ? (
                <p>{t("Select DOB")}</p>
              ) : (
                <p>
                  {day ? day : "Select DOB"} {month} {year}
                </p>
              )} */}

              {showDate ? (
                <p>{t("Select DOB")}</p>
              ) : (
                <p>
                  {day && month && year
                    ? `${day} ${month} ${year}`
                    : "Select DOB"}
                </p>
              )}
            </div>
          </div>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("GENDER_REQUIRED")}</Form.Label>
            <div className={styles.checkBoxArea}>
              <Form.Check
                inline
                label={t("FEMALE")}
                name="group1"
                type={"radio"}
                id={`inline-${"radio"}-2`}
                value={2}
                {...register("GENDER", ValidationSchema.GENDER)}
                isInvalid={!!formState.errors.GENDER}
              />
              <Form.Check
                inline
                label={t("MALE")}
                name="group1"
                type={"radio"}
                id={`inline-${"radio"}-1`}
                value={1}
                {...register("GENDER", ValidationSchema.GENDER)}
                isInvalid={!!formState.errors.GENDER}
              />
            </div>

            <Form.Control.Feedback type="invalid">
              {t(formState.errors.GENDER?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("HEIGHT_NOT_PUBLIC_FOR_COACH")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("HEIGHT")}
              onChange={(e) => {
                setValue("HEIGHT", e.target.value);
              }}
            >
              <option value="" defaultValue>
                {t("SELECT_HEIGHT")}
              </option>
              {heightList?.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.height}
                  </option>
                );
              })}
            </Form.Select>
            <img
              src={SelectImg}
              alt="SelectImg"
              className={styles.selectImg}
            ></img>
          </Form.Group>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("WEIGHT_NOT_PUBLIC_FOR_COACH")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("WEIGHT")}
              // isInvalid={!!formState.errors.WEIGHT}
              onChange={(e) => {
                setValue("WEIGHT", e.target.value);
              }}
            >
              <option value="" defaultValue>
                {t("SELECT_WEIGHT")}
              </option>
              {weightList?.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.weight}
                  </option>
                );
              })}
            </Form.Select>
            <img
              src={SelectImg}
              alt="SelectImg"
              className={styles.selectImg}
            ></img>

            {/* <Form.Control.Feedback type="invalid">
              {t(formState.errors.WEIGHT?.message)}
            </Form.Control.Feedback> */}
          </Form.Group>

          <div className={styles.devider}></div>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("COUNTRY_REQUIRED")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("COUNTRY", ValidationSchema.COUNTRY)}
              isInvalid={!!formState.errors.COUNTRY}
              onChange={(e) => {
                setValue("COUNTRY", e.target.value);
                setValue("STATE", "");
                setStateset("");
                clearErrors("COUNTRY");
                getStateMetaData(e.target.value);
              }}
            >
              <option value="" defaultValue>
                {t("SELECT_COUNTRY")}
              </option>
              {countryList?.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Select>
            <img
              src={SelectImg}
              alt="SelectImg"
              className={styles.selectImg}
            ></img>
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.COUNTRY?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("STATE_PROVINCE")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("STATE", ValidationSchema.STATE)}
              isInvalid={!!formState.errors.STATE}
              onChange={(e) => {
                setValue("STATE", e.target.value);
                clearErrors("STATE");
              }}
              disabled={stateList?.length === 0}
            >
              <option value="" defaultValue>
                {t("SELECT_STATE_PROVINCE")}
              </option>
              {stateList?.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Select>
            <img
              src={SelectImg}
              alt="SelectImg"
              className={styles.selectImg}
            ></img>
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.STATE?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={styles.DetailInput}>
            <Form.Label>{t("TOWN_CITY_REQ")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("INPUT_YOUR_CITY_TOWN")}
              // {...register("TOWN_CITY", ValidationSchema.TOWN_CITY)}
              {...register("TOWN_CITY", {
                onChange: (e) => handleKeyPress(e),
                ...ValidationSchema.TOWN_CITY,
              })}
              isInvalid={!!formState.errors.TOWN_CITY}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.TOWN_CITY?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <div className={styles.devider}></div>

          <div className={styles.DetailHead}>
            <p>{t("PROFILE")} </p>
            <h1>{t("PICTURE")}</h1>
          </div>

          <div className={styles.profileSection}>
            <img
              src={selectedImage ? selectedImage : ProfileDemo}
              alt="ProfileDemo"
            ></img>
            <div className={styles.profileSectionOption}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />

              <button type="button" onClick={handleButtonClick}>
                {t("SELECT_PHOTO")}
              </button>

              {selectedImage && (
                <React.Fragment>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedImage(null);
                      setIsProfileRemove(1);
                      setIsCropper(false);
                      setNoCropImg(null);
                      fileInputRef.current.value = "";
                    }}
                  >
                    {t("REMOVE_PHOTO")}
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>

          <CropImg
            handleClose={handleCloseCrop}
            show={showCrop}
            imageSrc={imageSrc}
            setSelectedImage={setSelectedImage}
            aspectRatio={CONSTANT.ASPECT_RATIO_IN_ADD_MEMBER}
            setIsCropper={setIsCropper}
            setImageSrc={setImageSrc}
          />

          <div className={styles.DetailHead}>
            <p>{t("PERSONAL")} </p>
            <h1>{t("BESTS")}</h1>
          </div>
        </div>

        <Row lg={1} style={{ marginTop: "28px" }}>
          {distanceList?.map((item, index) => {
            const values = inputValues[item.id];
            return (
              <Col key={item.id}>
                <div className={styles.timeGrid}>
                  <div className={styles.timeHead}>
                    <p>
                      {item.distance}
                      <span>{item.distance_unit == 1 ? "KM" : "MI"} </span>
                    </p>
                  </div>

                  <div className={styles.timeInput}>
                    <div>
                      <input
                        // type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="hours"
                        value={
                          addLeadingZero(values?.hours) === "00"
                            ? ""
                            : values?.hours
                            ? addLeadingZero(values?.hours)
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(item.id, "hours", e.target.value)
                        }
                      />
                      <p className={styles.personalBestTimeLabel}>
                        {t("HOURS")}
                      </p>
                    </div>

                    <p style={{ marginBottom: "25px" }}>:</p>
                    <div>
                      <input
                        // type="number"
                        name="minutes"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={
                          addLeadingZero(values?.minutes) === "00"
                            ? ""
                            : values?.minutes
                            ? addLeadingZero(values?.minutes)
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(item.id, "minutes", e.target.value)
                        }
                      />
                      <p className={styles.personalBestTimeLabel}>
                        {t("MINUTES")}
                      </p>
                    </div>

                    <p style={{ marginBottom: "25px" }}>:</p>
                    <div>
                      <input
                        // type="number"
                        name="seconds"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={
                          addLeadingZero(values?.seconds) === "00"
                            ? ""
                            : values?.seconds
                            ? addLeadingZero(values?.seconds)
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(item.id, "seconds", e.target.value)
                        }
                      />
                      <p className={styles.personalBestTimeLabel}>
                        {t("SECONDS")}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>

        <div className={styles.devider}></div>

        <div className={styles.DetailHead} style={{ marginBottom: "15px" }}>
          <p>{t("TRAINING")} </p>
          <h1>{t("GROUPS_")}</h1>
        </div>

        {grpList?.map((item, index) => {
          return (
            <div className="grpCheck" key={index}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedGrp.includes(item.id)}
                  onChange={() => handleSelectGroup(item.id)}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {item?.name}
                </label>
              </div>
            </div>
          );
        })}

        <div className={styles.devider}></div>

        <div className={styles.DetailHead} style={{ marginBottom: "15px" }}>
          <p>{t("MEMBERSHIP")} </p>
          <h1>{t("INFO")}</h1>
        </div>

        <div className={styles.datePickerBox}>
          <p>{t("MEMBERSHIP_VALID_UPTO")}</p>
          <div className={styles.dateSelection} onClick={handleShowDateMember}>
            <img src={Calender} alt="calender"></img>
            {showDateMember ? (
              <p>{t("Select Date")}</p>
            ) : (
              <p>
                {dayMember} {monthMember} {yearMember}
              </p>
            )}
          </div>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.switchCase}>
          <div className={styles.switchCaseInfo}>
            <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
            <p>{t("TURN_MEMBER_ON_OFF_AS_REQUIRED")}</p>
          </div>

          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={isMembershipActive}
            onChange={() => {
              setIsMembershipActive(!isMembershipActive);
            }}
          />
        </div>

        <div className={styles.devider}></div>

        <div className={styles.switchCase}>
          <div className={styles.switchCaseInfo}>
            <h1>{t("CAN_VIEW_OTHER_CLUB_MEMBER")}</h1>
            <p>{t("ALLOW_USER_TO_SEE_OTHER_MEMBER")}</p>
          </div>

          <Form.Check
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={isViewOtherMember}
            onChange={() => {
              setIsViewOtherMember(!isViewOtherMember);
            }}
          />
        </div>

        <div className={styles.devider}></div>

        <div className={styles.cancelOrSave}>
          <div style={{ display: "flex" }}>
            <button
              className={styles.cancelBtn}
              type="button"
              onClick={() => {
                if (member_id) {
                  setIsMemberUpdate(0);
                } else setShowtab(1);
              }}
            >
              {t("CENCEL")}
            </button>
            {member_id ? (
              <button
                className={styles.cancelBtn}
                type="button"
                onClick={handleDelete}
              >
                {t("DELETE")}
              </button>
            ) : (
              ""
            )}
          </div>

          <button className={styles.saveBtn} type="submit">
            {t("SAVE_CHANGES")}
          </button>
        </div>
      </Form>

      <MemberCalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        tileDisabled={tileDisabledForStartDate}
        selectedDate={selectedDate}
        dateToDiable={new Date()}
        eventData={CONSTANT.ALLOW_PREVIOUS}
      />

      <CalenderBackup
        show={showDateMember}
        handleClose={handleCloseDateMember}
        setWeekday={setWeekdayMember}
        setDay={setDayMember}
        setMonth={setMonthMember}
        setYear={setYearMember}
        tileDisabled={tileDisabledForStartDateMember}
        selectedDate={selectedDateMember}
        eventData={CONSTANT.ALLOW_PREVIOUS}
      />

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteMember}
        isLoading={isLoading}
      />

      <MemberLimit
        show={showLimit}
        handleClose={handleLimit}
        limit={memberLimit}
      />
    </div>
  );
}

export default AddDetail;
