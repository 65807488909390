import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class TrainzaApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...TrainzaApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  // SignUp======
  static async signUp(data) {
    let res = await this.request("/brand/signup", data, "post");
    return res;
  }

  // Login======
  static async login(data) {
    let res = await this.request("/brand/login", data, "post");
    return res;
  }

  // Forgot password======
  static async forgotPassword(data) {
    let res = await this.request("/brand/forgotPassword", data, "post");
    return res;
  }

  // Verification======
  static async verification(data) {
    let res = await this.request("/brand/verifyCodes", data, "post");

    return res;
  }

  // Logout User======
  static async logoutUser(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/logout", {}, "post", header);
    return res;
  }

  // UpdateLoginCredential======
  static async updateLogin(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/loginDetails", data, "put", header);
    return res;
  }

  // BrandInfo======
  static async brandInfo(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/brandInfo", data, "post", header);

    return res;
  }

  // Branding======
  static async branding(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/branding", data, "post", header);

    return res;
  }

  //BrandDetail
  static async brandDetail(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/details`, {}, "get", header);
    return res;
  }

  //GetGroup
  static async groupList(authToken, search) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/group?search=${search}`,
      {},
      "get",
      header
    );
    return res;
  }

  //GetGroupMember
  static async groupMember(authToken, id, page) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/group/${id}/members?pageNo=${page}`,
      {},
      "get",
      header
    );
    return res;
  }

  // AddGroup======
  static async addGroup(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/group", data, "post", header);

    return res;
  }

  //DeleteGroup
  static async DeleteGroupList(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/group/${id}`, {}, "delete", header);
    return res;
  }

  //DeleteGroup
  static async DeleteGroupListMember(authToken, grpId, memberId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/group/${grpId}/members/${memberId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Country meta data
  static async countryMetaData() {
    // let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/countryMeta`, {}, "get", {});
    return res;
  }

  //Country meta data
  static async stateMetaData(id) {
    // let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/stateMeta?countryId=${id}`,
      {},
      "get",
      {}
    );
    return res;
  }

  //Height/Width/Distance meta data
  static async HeightWidthDistanceMeta(param) {
    let res = await this.request(`/superAdmin/${param}`, {}, "get", {});
    return res;
  }

  // PB Distance meta api
  static async PBdistanceMeta() {
    let res = await this.request(
      `/superAdmin/personalBestDistanceMeta`,
      {},
      "get",
      {}
    );
    return res;
  }

  static async GetTermsAndCondition(param) {
    let res = await this.request(
      `/superAdmin/pageContent?pageType=${param}`,
      {},
      "get",
      {}
    );
    return res;
  }

  // Add Member======
  static async AddMember(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/member", data, "post", header);

    return res;
  }

  // Get Member======
  static async GetMember(authToken, page, search, order) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member?pageNo=${page}&search=${search}&orderBy=${order}`,
      {},
      "get",
      header
    );

    return res;
  }

  // Get Member Detail======
  static async GetMemberDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/member/${id}`, {}, "get", header);

    return res;
  }

  // Get Member Detail======
  static async DeleteMember(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/member/${id}`, {}, "delete", header);

    return res;
  }

  //Delete Member
  static async DeleteMember(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/member/${id}`, {}, "delete", header);
    return res;
  }

  //Active/InActive Member
  static async ActiveInActiveMember(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${id}/changeMemberActiveStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  //Get Member Result
  static async GetMemberResult(authToken, id, page) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${id}/result?pageNo=${page}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Add Event======
  static async AddEvent(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/event", data, "post", header);

    return res;
  }

  // Get Event======
  static async GetEvent(authToken, page, search, order) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event?pageNo=${page}&search=${search}&orderBy=${order}`,
      {},
      "get",
      header
    );
    return res;
  }
  // Get Event======
  static async GetEventByDate(authToken, page, date) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event?pageNo=${page}&eventDate=${date}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Event Detail======
  static async GetEventDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/event/${id}`, {}, "get", header);

    return res;
  }

  // Change Event Status======
  static async ChangeEventStatus(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/activeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  //DeleteGroup
  static async DeleteEvent(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/event/${id}`, {}, "delete", header);
    return res;
  }

  // Search member======
  static async searchMember(authToken, search, page) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/memberSearch?pageNo=${page}&search=${search}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Send invitation======
  static async SendInvitation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/memberInvite`, data, "post", header);
    return res;
  }

  // Copy Event======
  static async CopyEvent(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/copyEvent`,
      {},
      "post",
      header
    );
    return res;
  }

  // Event Distance Data======
  static async GetEventDistanceData(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${id}/result/eventsDistanceData`,
      {},
      "get",
      header
    );
    return res;
  }

  // Add Result Data======
  static async AddResultData(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${id}/result`,
      data,
      "post",
      header
    );
    return res;
  }

  //Delete Result
  static async DeleteResult(authToken, memberId, resultId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${memberId}/result/${resultId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Event Distance Data======
  static async GetEventResultDetail(authToken, member_id, resultId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${member_id}/result/${resultId}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Pending Invite List======
  static async GetPendingInvite(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/memberInvite`, {}, "get", header);
    return res;
  }

  //Delete Pending Invite List======
  static async DeletePendingInvite(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/memberInvite/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Post Image Gallery======
  static async PostImageGallery(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/gallery`,
      data,
      "post",
      header
    );
    return res;
  }

  //Post Image Gallery======
  static async UpdateImageGallery(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/gallery`,
      data,
      "put",
      header
    );
    return res;
  }

  // Add Routes======
  static async AddRouteApi(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/training/route", data, "post", header);
    return res;
  }

  // Get Routes======
  static async GetRoutes(authToken, page, search, order, skipPaging) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/route?pageNo=${page}&search=${search}&orderBy=${order}&skipPaging=${skipPaging}`,
      {},
      "get",
      header
    );

    return res;
  }

  // Get Routes details======
  static async GetRoutesDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/route/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  //Delete Routes
  static async DeleteRoutesList(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/route/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Post Image Gallery======
  static async DeleteGalleryImage(authToken, eventId, ImageId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${eventId}/gallery/${ImageId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Add Poll======
  static async AddPOll(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/poll`,
      data,
      "post",
      header
    );
    return res;
  }

  //Add Poll======
  static async GetEventMemberDistance(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/result/memberDistanceData`,
      {},
      "get",
      header
    );
    return res;
  }

  // Add Event Result Data======
  static async AddEventResultData(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/result`,
      data,
      "post",
      header
    );
    return res;
  }

  // Get Event Result Data======
  static async GetEventResultData(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/result`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Event Voter List======
  static async GetEventVoterList(authToken, skip, eventId, answerId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${eventId}/pollVoters?answerId=${answerId}&skipPaging=${skip}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Event Voter List======
  static async DeleteEventVoter(authToken, eventId, voteId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${eventId}/poll/${voteId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Get Event Result Data======
  static async DeleteEventResultData(authToken, eventId, resultId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${eventId}/result/${resultId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Get Event Result Deatil======
  static async DeleteEventResultDetail(authToken, eventId, resultId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${eventId}/result/${resultId}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Add NEWS======
  static async AddNews(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/news", data, "post", header);

    return res;
  }

  // Get News======
  static async GetNews(authToken, page, search, order) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news?pageNo=${page}&search=${search}&orderBy=${order}`,
      {},
      "get",
      header
    );

    return res;
  }

  // Change Event Status======
  static async ChangeNewsStatus(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${id}/activeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  // Get News Detail======
  static async GetNewsDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/news/${id}`, {}, "get", header);

    return res;
  }

  //Delete News
  static async DeleteNews(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/news/${id}`, {}, "delete", header);
    return res;
  }

  //Post News Image Gallery======
  static async PostNewsImage(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${id}/gallery`,
      data,
      "post",
      header
    );
    return res;
  }

  //Post Image Gallery In News======
  static async UpdateImageGalleryOfNews(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${id}/gallery`,
      data,
      "put",
      header
    );
    return res;
  }

  //Post News Gallery======
  static async DeleteNewsImage(authToken, eventId, ImageId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${eventId}/gallery/${ImageId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Add Poll======
  static async AddNewsPOll(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${id}/poll`,
      data,
      "post",
      header
    );
    return res;
  }

  // Get Event Voter List======
  static async GetNewsVoterList(authToken, skip, eventId, answerId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${eventId}/pollVoters?answerId=${answerId}&skipPaging=${skip}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Get Event Voter List======
  static async DeleteNewsVoter(authToken, eventId, voteId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${eventId}/poll/${voteId}`,
      {},
      "delete",
      header
    );
    return res;
  }
  // Get workout type List======
  static async getWorkoutTypeList(authToken, search) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workoutType?&search=${search}`,
      {},
      "get",
      header
    );

    return res;
  }

  // save Workout type======
  static async saveWorkoutType(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workoutType`,
      data,
      "post",
      header
    );
    return res;
  }

  //Delete workout type
  static async DeleteWorkoutType(authToken, workoutTypeId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workoutType/${workoutTypeId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Get workout List======
  static async getWorkoutList(authToken, page, search, skipPaging) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workout?pageNo=${page}&search=${search}&skipPaging=${skipPaging}`,
      {},
      "get",
      header
    );

    return res;
  }

  // save Workout ======
  static async addWorkOut(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workout`,
      data,
      "post",
      header
    );
    return res;
  }

  //Delete workout List
  static async deleteWorkoutList(authToken, workoutId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workout/${workoutId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Get workout detail======
  static async getWorkoutDetail(authToken, workoutId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/workout/${workoutId}`,
      {},
      "get",
      header
    );

    return res;
  }

  // Get traning List======
  static async getTrainingList(authToken, search, trainingDate) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training?search=${search}&trainingDate=${trainingDate}`,
      {},
      "get",
      header
    );

    return res;
  }

  // Get traning detail======
  static async getTrainingDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/training/${id}`, {}, "get", header);

    return res;
  }

  // Get Event======
  static async GetNewsByDate(authToken, page, date) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news?pageNo=${page}&newsDate=${date}`,
      {},
      "get",
      header
    );
    return res;
  }

  //==== save Traning ======
  static async addTraning(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/training`, data, "post", header);
    return res;
  }

  //Delete Traning List
  static async deleteTraningList(authToken, trainingId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/${trainingId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Send event notification
  static async SendEventNotification(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/event/${id}/sendNotification`,
      data,
      "post",
      header
    );
    return res;
  }

  //Send news notification
  static async SendNewsNotification(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/news/${id}/sendNotification`,
      data,
      "post",
      header
    );
    return res;
  }

  // Get Workout  Logbook======
  static async getWorkoutLogbook(authToken, id, date) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${id}/logbook?date=${date}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Add workout logbook======
  static async AddWorkoutLogbook(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${id}/logbook`,
      data,
      "post",
      header
    );
    return res;
  }

  //Delete logbook workout======
  static async DeleteLogbook(authToken, memberId, logbookId) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/member/${memberId}/logbook/${logbookId}`,
      {},
      "delete",
      header
    );

    return res;
  }

  // Get member logbook workout type List======
  static async getLogbookWorkoutType(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/workoutType`, {}, "get", header);
    return res;
  }

  // social SignUp======
  static async socialLogin(data) {
    let res = await this.request("/brand/socialAuthentication", data, "post");
    return res;
  }

  // Dashboard======
  static async getDashboardData(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/dashboard`, {}, "get", header);
    return res;
  }

  //Send traning notification
  static async SendTraningNotification(authToken, trainingId, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/training/${trainingId}/sendNotification`,
      data,
      "post",
      header
    );
    return res;
  }
}

export default TrainzaApi;
