import React, { useEffect, useRef, useState } from "react";
import styles from "./Result.module.css";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Form from "react-bootstrap/Form";
import SelectImg from "../../../Assests/Images/SelectImg.svg";
import CalenderComp from "./CalenderComp";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import Toster from "../../../Common/Toster/Toster";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Loader from "../../../Common/Loader/Loader";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../Utils/MockData";
import { MONTH_CODE, MONTH_NO_CODE } from "../../../Utils/MonthCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import {
  calculatePace,
  formatTimeInCorrectFormat,
} from "../../../Utils/CommonFunction";
import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import { CONSTANT } from "../../../Utils/Constant";

function EditResult({
  editIndex,
  setShowEdit,
  showEdit,
  setEditIndex,
  member_id,
  handleGetResult,
  resultId,
  setShowAdd,
}) {
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const previousRef = useRef();
  const nextRef = useRef();

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleShowDate = () => setShowDate(true);
  const handleCloseDate = () => setShowDate(false);

  const [datesToDisable, setDatesToDisable] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventName, setEventName] = useState([]);
  const [eventId, setEventId] = useState("");
  const [eventDistance, setEventDistance] = useState([]);
  const [eventDistanceId, setEventDistanceId] = useState("");
  const [eventDistanceName, setEventDistanceName] = useState("");

  const [eventTitle, setEventTitle] = useState("");

  const [hrs, setHrs] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(0);

  const [pace, setPace] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [eventError, setEventError] = useState("");

  const currentDate = new Date();

  useEffect(() => {
    setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  const handleGetEventResultDetail = () => {
    const response = TrainzaApi.GetEventResultDetail(
      isAuthenticated,
      member_id,
      resultId
    );

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.data?.code == 157) {
          Toster(t("INVALD_MEMBER"), "error");
          navigate("/members");
        } else if (
          response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
        ) {
          Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
          navigate("/members");
        } else {
          setEventTitle(response?.data?.event?.eventName);

          setEventDistance(response?.data?.event?.distances);
          setValue("EVENT", response?.data?.eventId);
          setEventId(response?.data?.eventId);
          setTimeout(() => {
            setValue("DISTANCE", response?.data?.distanceId);
          }, 500);
          setEventDistanceId(response?.data?.distanceId);

          let timeArray = response?.data?.result?.split(":");
          let hrs = parseInt(timeArray[0]); // hours as integer
          let mins = parseInt(timeArray[1]); // minutes as integer
          let secs = parseInt(timeArray[2]); // seconds as integer
          setHrs(hrs);
          setMins(mins);
          setSecs(secs);
          setPace(response?.data?.pace);

          let id = response?.data?.distanceId;
          let distance = response?.data?.event?.distances;
          let desiredDistance = distance.find((item) => item?.id === id);
          setEventDistanceName(desiredDistance?.distance);
        }
      }
    });
  };

  useEffect(() => {
    if (resultId) {
      handleGetEventResultDetail();
    }
  }, [resultId, editIndex, showEdit]);

  useEffect(() => {
    handleGetEventDistance();
  }, []);

  const handleGetEventDistance = () => {
    const response = TrainzaApi.GetEventDistanceData(
      isAuthenticated,
      member_id
    );

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setEventData(response?.data?.eventsDistanceData);
        const eventDates = response?.data?.eventsDistanceData?.map(
          (event) => event.eventDate
        );
        const uniqueEventDates = [...new Set(eventDates)];

        setDatesToDisable(uniqueEventDates);
      }
    });
  };

  useEffect(() => {
    var daySelected = day;

    if (day.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const selectedDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;

    const eventsOnSelected = eventData?.filter(
      (event) => event?.eventDate === selectedDate
    );

    const modifiedEvents = eventsOnSelected?.map((event) => ({
      eventName: event.eventName,
      id: event.id,
    }));

    setEventName(modifiedEvents);
  }, [day, eventData, month, year]);

  const findDistaneOfEvent = (eventId) => {
    const event = eventData.find((event) => event.id === eventId);

    const distances = event ? event.distances : [];

    setEventDistance(distances);
    setValue("DISTANCE", "");
  };

  const handleCangeHrs = (e) => {
    const inputValue = e.target.value;

    const cleanedValue = inputValue.replace(/^0+/, "");

    const newValue = cleanedValue.replace(".", "");

    if (newValue < 1000) {
      setHrs(newValue);
    }
  };

  const handleCangeMins = (e) => {
    let inputValue = e.target.value.trim();

    if (inputValue < 60) {
      if (inputValue == 0) {
        setMins(0);
      } else {
        if (/^\d{2}$/.test(inputValue)) {
          setMins(inputValue);
          return;
        }

        if (/^\d+$/.test(inputValue)) {
          inputValue = parseInt(inputValue, 10).toString();
        }

        if (inputValue === "") {
          setMins("");
          return;
        }

        const intValue = parseInt(inputValue, 10);

        if (intValue >= CONSTANT.MIN_MIN && intValue <= CONSTANT.MAX_MIN) {
          const formattedValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
          setMins(formattedValue);
        } else {
          console.log("Value out of range or invalid");
        }
      }
    }
  };

  const handleCangeSecs = (e) => {
    let inputValue = e.target.value.trim();
    if (inputValue < 60) {
      if (inputValue == 0) {
        setSecs(0);
      } else {
        if (/^\d{2}$/.test(inputValue)) {
          setSecs(inputValue);
          return;
        }

        if (/^\d+$/.test(inputValue)) {
          inputValue = parseInt(inputValue, 10).toString();
        }

        if (inputValue === "") {
          setSecs("");
          return;
        }

        const intValue = parseInt(inputValue, 10);

        if (intValue >= CONSTANT.MIN_SEC && intValue <= CONSTANT.MAX_SEC) {
          const formattedValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
          setSecs(formattedValue);
        } else {
          console.log("Value out of range or invalid");
        }
      }
    }
  };

  function OnSubmit() {
    const PostData = new FormData();
    if (resultId) {
      PostData.append("eventResultId", resultId);
    }
    PostData.append("eventId", eventId);
    PostData.append("distanceId", eventDistanceId);
    PostData.append(
      "time",
      formatTimeInCorrectFormat(`${hrs}:${mins}:${secs}`)
    );

    PostData.append("pace", pace);

    if (pace[0] !== CONSTANT.UNDEFINED_PACE && pace[0] !== "0:00 p/km") {
      setIsLoading(true);
      TrainzaApi.AddResultData(isAuthenticated, PostData, member_id).then(
        (response) => {
          setIsLoading(false);

          const ResponseCode = STATUS_MSG[response?.data?.code];

          if (
            response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            response?.data?.code === STATUS_CODE.SESSION_EXPIRED
          ) {
            dispatch(userLogoutClear());
            Toster(t("SESSION_EXPIRED"), "error");
          } else if (
            response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
          ) {
            Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
            navigate("/members");
          } else {
            if (response?.code === STATUS_CODE.SUCCESS) {
              if (resultId) {
                Toster(t("RESULT_UPDATED"), "success");
              } else {
                Toster(t("RESULT_ADDED"), "success");
              }
              handleGetResult(CONSTANT.PAGE_ONE);
              reset();
              setMins();
              setHrs();
              setSecs();
              setEditIndex();
              setShowEdit(false);
              setShowAdd(false);
            } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
              Toster(response?.data?.message, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        }
      );
    }
  }

  const handleSetDistance = (e) => {
    const id = e.target.value;
    setEventDistanceId(id);

    const foundObject = eventDistance?.find((item) => item.id === id);
    setEventDistanceName(foundObject?.distance);
  };

  useEffect(() => {
    const pace = `${calculatePace(
      `${hrs}:${mins}:${secs}`,
      eventDistanceName
    )} p/km`;
    setPace([pace]);
  }, [hrs, mins, secs, eventDistanceId]);

  const tileDisabled1 = ({ date }) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    return !datesToDisable?.includes(formattedDate);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div
            className={styles.iconHolder}
            onClick={() => {
              if (!resultId) {
                callgoToPreviousMonth();
              }
            }}
          >
            <FaLessThan size="15" color="#ff4300" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div
            className={styles.iconHolder}
            onClick={() => {
              if (!resultId) {
                callgoToNextMonth();
              }
            }}
          >
            <FaGreaterThan size="15" color="#ff4300" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={() => {
            if (!resultId) {
              handleShowDate();
            }
          }}
        />
      </div>
      <Form
        onSubmit={handleSubmit((data) => {
          setFormSubmitted(true); // Set formSubmitted to true when the form is submitted
          OnSubmit(data); // Call the actual submit function
        })}
      >
        <div className={styles.fields}>
          {resultId ? (
            <Form.Group className={styles.inputArea}>
              <Form.Label>
                <div className={styles.inputLabel}>
                  <p>
                    {t("SELECT")} <span>{t("EVENT")}</span>
                  </p>
                </div>
              </Form.Label>
              <Form.Select className={styles.inputSelect} disabled={true}>
                <option value="" defaultValue>
                  {eventTitle}
                </option>
              </Form.Select>
            </Form.Group>
          ) : (
            <Form.Group className={styles.inputArea}>
              <Form.Label>
                <div className={styles.inputLabel}>
                  <p>
                    {t("SELECT")} <span>{t("EVENT")}</span>
                  </p>
                </div>
              </Form.Label>

              <Form.Select
                className={styles.inputSelect}
                {...register("EVENT", ValidationSchema.EVENT)}
                isInvalid={!!formState.errors.EVENT || eventError}
                onChange={(e) => {
                  // Reset the custom error message when an event is selected
                  setEventError("");

                  setValue("EVENT", e.target.value);
                  clearErrors("EVENT");
                  findDistaneOfEvent(e.target.value);
                  setEventId(e.target.value);
                }}
                // disabled={!eventName || eventName.length === 0}
              >
                <option value="" defaultValue>
                  {eventTitle ? eventTitle : t("Select Event")}
                </option>
                {eventName?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.eventName}
                    </option>
                  );
                })}
              </Form.Select>

              {(!eventName || eventName.length === 0) && (
                <div
                  onClick={() => Toster(t("NO_EVENT_AVAILBLE"), "error")}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                  }}
                ></div>
              )}
              <img src={SelectImg} alt="SelectImg" />
              <Form.Control.Feedback type="invalid">
                {/* Display the validation error from react-hook-form or the custom error */}
                {t(formState.errors.EVENT?.message) || eventError}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group className={styles.inputArea}>
            <Form.Label>
              <div className={styles.inputLabel}>
                <p>
                  {t("SELECT")} <span>{t("DISTANCE")}</span>
                </p>
              </div>
            </Form.Label>
            <Form.Select
              className={styles.inputSelect}
              {...register("DISTANCE", ValidationSchema.DISTANCE)}
              isInvalid={!!formState.errors.DISTANCE}
              // disabled={eventDistance?.length === 0}
              onChange={(e) => {
                setValue("DISTANCE", e.target.value);
                clearErrors("DISTANCE");
                handleSetDistance(e);
              }}
            >
              <option value="" defaultValue>
                Select Distance
              </option>
              {eventDistance?.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.distance} {item.unit === 1 ? t("KM") : t("MI")}
                </option>
              ))}
            </Form.Select>
            {eventDistance?.length === 0 && (
              <div
                onClick={() => Toster(t("NO_DIS_AVAILBLE"), "error")}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                }}
              ></div>
            )}
            <img src={SelectImg} alt="SelectImg" />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.DISTANCE?.message)}
            </Form.Control.Feedback>
          </Form.Group>

          <div className={styles.timeAndPace}>
            <div>
              <p className={styles.timeHeading}>{t("TIME_")}</p>
              <div className={styles.inputBox}>
                <div className={styles.inputContainer}>
                  <input
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={hrs}
                    onChange={(e) => {
                      handleCangeHrs(e);
                    }}
                  />
                  <p>{t("HOURS")}</p>
                </div>

                <p
                  style={{
                    color: "#6C757D",
                    margin: "8px 5px 0px 5px",
                    fontSize: "20px",
                  }}
                >
                  :
                </p>

                <div className={styles.inputContainer}>
                  <input
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={mins}
                    onChange={(e) => {
                      handleCangeMins(e);
                    }}
                  />
                  <p>{t("MINUTES")}</p>
                </div>

                <p
                  style={{
                    color: "#6C757D",
                    margin: "8px 5px 0px 5px",
                    fontSize: "20px",
                  }}
                >
                  :
                </p>

                <div className={styles.inputContainer}>
                  <input
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={secs}
                    onChange={(e) => {
                      handleCangeSecs(e);
                    }}
                  />
                  <p>{t("SECONDS")}</p>
                </div>

                <div className="validateErrorResult">
                  {/* Display validation message only if the form is submitted and the pace is undefined */}
                  {formSubmitted &&
                  (pace[0] === CONSTANT.UNDEFINED_PACE ||
                    pace[0] === "0:00 p/km")
                    ? t("ENTER_TIME")
                    : ""}
                </div>
              </div>
            </div>

            <div className={styles.pace}>
              <p>{t("PACE")}</p>
              <input
                style={{
                  width: `${Math.max(pace.length * 10, 140)}px`, // Adjust 10 as needed
                  minWidth: "140px",
                }}
                disabled={true}
                value={pace[0] !== CONSTANT.UNDEFINED_PACE ? pace : ""}
              />
            </div>
          </div>
        </div>

        <div className={styles.scheduleFooter}>
          <div className={styles.cancelOrDelete}>
            <button
              onClick={() => {
                setEditIndex();
                setShowAdd(false);
                setShowEdit(false);
              }}
              type="button"
            >
              {t("CANCEL")}
            </button>
          </div>
          <div className={styles.saveButton}>
            <button
              type="submit"
              onClick={() => setFormSubmitted(true)} // Set formSubmitted to true when clicking the Save button
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      </Form>
      <CalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
        tileDisabled={tileDisabled1}
        eventData={eventData}
      />
    </div>
  );
}

export default EditResult;
