import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-bootstrap/Modal";
import styles from "../../Members/MemberDetail/Result.module.css";
import { useTranslation } from "react-i18next";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../Utils/MockData";
import Lessthan from "../../../Assests/Images/Lessthan.png";
import Greatethan from "../../../Assests/Images/Greatethan.svg";
import Calender1 from "../../../Assests/Images/Calender1.png";
import Calender2 from "../../../Assests/Images/Calender2.png";
import { resetTimeToMidnight } from "../../../Utils/CommonFunction";

function EventCalender({
  show,
  handleClose,
  setWeekday,
  setDay,
  setMonth,
  setYear,
  previousRef,
  nextRef,
  tileDisabled,
  selectedDate,
  dateToDiable,
  eventData,
  startDate,
  endDate,
  setEndDate,
  setEndTime,
}) {
  const [disableButton, setDisableButton] = useState(false);
  const { t } = useTranslation();
  const currentDate = new Date();
  const dateObject = new Date(selectedDate);
  const start = new Date(startDate); // Convert startDate to a Date object

  const [value, onChange] = useState(selectedDate ? dateObject : new Date());
  const [view, setView] = useState("month");
  const [currentMonthName, setCurrentMonthName] = useState("");

  const handleViewChange = useCallback(({ activeStartDate, view }) => {
    setView(view);
    if (view === "month") {
      const monthName = activeStartDate.toLocaleDateString("en-US", {
        month: "long",
      });
      setCurrentMonthName(monthName);
    }
  }, []);

  const goToPreviousMonth = () => {
    const previousDate = selectedDate ? dateObject : new Date(value);
    previousDate.setDate(previousDate.getDate() - 1);
    const previousDateReset = resetTimeToMidnight(previousDate);
    const dateToDiableReset = resetTimeToMidnight(dateToDiable);

    if (eventData || previousDateReset >= dateToDiableReset) {
      setDisableButton(false);
      onChange(previousDate);
      const previousDateWeekday = previousDate.toLocaleString("default", {
        weekday: "long",
      });
      const previousDateName = previousDate.toLocaleString("default", {
        month: "long",
      });
      const previousDateYear = previousDate.getFullYear();
      const previousDateDay = previousDate.getDate();

      setWeekday(previousDateWeekday);
      setDay(previousDateDay);
      setMonth(previousDateName);
      setYear(previousDateYear);
    } else {
      setDisableButton(true);
    }
  };

  React.useImperativeHandle(previousRef, () => ({
    handleCallPrevious: goToPreviousMonth,
  }));

  const goToNextMonth = () => {
    setDisableButton(false);
    const nextDate = selectedDate ? dateObject : new Date(value);
    nextDate.setDate(nextDate?.getDate() + 1);
    onChange(nextDate);
    const nextDateWeekday = nextDate.toLocaleString("default", {
      weekday: "long",
    });
    const nextDateName = nextDate.toLocaleString("default", {
      month: "long",
    });
    const nextDateYear = nextDate.getFullYear();
    const nextDateDay = nextDate.getDate();

    setWeekday(nextDateWeekday);
    setDay(nextDateDay);
    setMonth(nextDateName);
    setYear(nextDateYear);
  };

  React.useImperativeHandle(nextRef, () => ({
    handleCallNext: goToNextMonth,
  }));

  const handleDateClick = (date) => {
    setDisableButton(false);
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();

    setWeekday(weekday);
    setDay(day);
    setMonth(month);
    setYear(year);
    if (weekday && day && month && year) {
      handleClose();
    }
  };

  const goToToday = () => {
    const today = new Date();
    onChange(today);
    setWeekday(DAYS_OF_WEEK[today.getDay()]);
    setDay(today.getDate());
    setMonth(MONTH_OF_YEAR[today.getMonth()]);
    setYear(today.getFullYear());
    handleClose();
  };

  useEffect(() => {
    if (selectedDate) {
      const predefautDate = new Date(selectedDate);
      onChange(predefautDate);
    }
  }, [show]);

  useEffect(() => {
    const monthName = value.toLocaleDateString("en-US", { month: "long" });
    setCurrentMonthName(monthName);
  }, [value, selectedDate]);

  var dateString = selectedDate;
  var month = MONTH_OF_YEAR[Number(dateString?.substring(5, 7)) - 1];

  // const isPreviousButtonDisabled = value <= start;

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    // Check if the start date is after the end date
    if (start > end) {
      setEndDate("");
      setEndTime(null);
      onChange(new Date());
    }
  }, [startDate, setEndTime]);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="dateModal">
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            onClickDay={handleDateClick}
            tileDisabled={tileDisabled}
            onActiveStartDateChange={handleViewChange}
          />
          {currentMonthName === month
            ? view === "month" && (
                <div className={styles.modalBtnSection}>
                  <button
                    onClick={goToPreviousMonth}
                    className={styles.previousOrNext}
                    disabled={disableButton}
                    style={{
                      opacity: disableButton ? 0.5 : 1,
                      cursor: disableButton ? "not-allowed" : "pointer",
                    }}
                  >
                    <img src={Lessthan} alt="lessthan" height="19px"></img>
                    <img
                      src={Calender2}
                      alt="Calender2"
                      className={styles.calenderChange}
                    ></img>
                  </button>
                  <button className={styles.showDay} onClick={goToToday}>
                    {t("TODAY")}
                  </button>
                  <button
                    onClick={goToNextMonth}
                    className={styles.previousOrNext}
                  >
                    <img
                      src={Calender1}
                      alt="Calender2"
                      className={styles.calenderChange}
                    ></img>
                    <img src={Greatethan} alt="greater" height="19px"></img>
                  </button>
                </div>
              )
            : ""}
        </div>
      </Modal>
    </>
  );
}

export default EventCalender;
