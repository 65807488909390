import React, { useEffect, useRef, useState } from "react";
import styles from "./RoutesPage.module.css";
import Cross from "../../Assests/Images/Cross.svg";
import Edit from "../../Assests/Images/Edit.svg";
import { useTranslation } from "react-i18next";
import {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { STATUS_CODE } from "../../Utils/StatusCode";
import TrainzaApi from "../../Helpers/Api";
import Toster from "../../Common/Toster/Toster";
import Loader from "../../Common/Loader/Loader";
import DeleteRoute from "./DeleteRoute";
import { useScroll } from "../../Context/ScrollerContext";
import { CONSTANT } from "../../Utils/Constant";

function Route({
  searchKeyword,
  isDataPresent,
  setIsDataPresent,
  setShowtab,
  onEdit,
  setSearchKeyword
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );

  const { lastEditIndex, scrollToEditedItem } = useScroll();

  const routeListRef = useRef([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState();

  const [routeList, setRouteList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [RouteId, setRouteId] = useState();

  const [sorting, setSorting] = useState(CONSTANT.ALPHABETICAL);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleOpen = (id, index) => {
    onEdit(id);
    setShowtab(true);
    scrollToEditedItem(index);
  };

  //===== route list api=====
  const handleGetRoute = (page, search, order , skipPaging = 0) => {
    setIsLoading(true);
    const encodedSearch = encodeURIComponent(search);
    const response = TrainzaApi.GetRoutes(
      isAuthenticated,
      page,
      encodedSearch,
      order,
      (skipPaging = skipPaging)
    );

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (page == 1) {
          setRouteList(response?.data?.routeList);
        } else {
          setRouteList([...routeList, ...response?.data?.routeList]);
        }
        if (!search) {
          setIsDataPresent(response?.data?.count == 0);
        }
        setPageNumber(page + 1);
        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    handleGetRoute(1, searchKeyword, sorting);
  }, [searchKeyword, sorting]);

  useEffect(() => {
    if (lastEditIndex !== null) {
      const element = routeListRef.current[lastEditIndex];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [routeList, lastEditIndex]);

  return (
    <div>
      {isLoading && <Loader />}

      <div className={styles.summary}>
        <div className={styles.personalDetail}>
          <p className={styles.personalDetailOff}>{t("LIST_BY")} -</p>
          <p
            className={
              sorting === 1 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.ALPHABETICAL);
            }}
          >
            {t("ALPHABETICAL")}
          </p>
          <p
            className={
              sorting === 2 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.LOW_DISTANCE);
            }}
          >
            - {t("LOW_DISTANCES")}
          </p>
          <p
            className={
              sorting === 3 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.HIGH_DISTANCE);
            }}
          >
            - {t("HIGH_DISTANCES")}
          </p>
        </div>
      </div>
      <div className={styles.devider}></div>

      {/* display route list */}
      {routeList?.map((info, index) => {
        return (
          <div
            id={`route-item-${index}`}
            className={styles.detail}
            key={index}
            style={{ background: info?.status ? "#FFFFFF" : "#CBCBCB" }}
            ref={(el) => (routeListRef.current[index] = el)}
          >
            <div className={styles.info} key={index}>
              <div className={styles.infoOfMember}>
                <p className={styles.gender}>
                  {info.name} -{" "}
                  <span className={styles.date}>
                    {info.distance}
                    {info.distanceUnit == 1 ? "KM" : "MI"}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.editCross}>
              <CustomImgButton
                variant="primary"
                onClick={() => {
                  handleShowDelete();
                  setRouteId(info.id);
                }}
                src={Cross}
                alt="cross"
              ></CustomImgButton>
              <CustomImgButton
                variant="primary"
                src={Edit}
                alt="edit"
                onClick={() => handleOpen(info?.id)}
              ></CustomImgButton>
            </div>
          </div>
        );
      })}

      {/* MO RESULT FOUND FOR SEARCHING */}
      {searchKeyword && routeList?.length === 0 ? (
        <React.Fragment>
          <div className={styles.noDataFound}>
            <p>
              {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
            </p>
          </div>
          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        ""
      )}

      {/* OVERALL NO DATA FOUND */}
      {searchKeyword === "" && isDataPresent ? (
        <React.Fragment>
          <div className={styles.noRecordFound}>
            <p>
              {t("CURRENTLY_NO_ROUTES")} -{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowtab(2);
                }}
              >
                {t("ADD_ROUTES")}
              </span>
            </p>
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      {/* INVITE AND LOAD MORE SECTION */}
      {!searchKeyword ? (
        <div className={styles.inviteormore}>
          <div className={styles.membersCount}>
            <CustomButton
              variant="footerBtnCustom"
              onClick={() => {
                setShowtab(2);
              }}
            >
              {" "}
              <p>
                {t("ADD")} <span>{t("ROUTE__")}</span>
              </p>
            </CustomButton>

            <p>
              {totalCount}{" "}
              <span>{routeList?.length > 1 ? t("ROUTEES") : t("ROUTE")} </span>
            </p>
            <CustomButton
              variant="footerBtnCustom"
              onClick={() => {
                if (isLoadMore) {
                  handleGetRoute(pageNumber, searchKeyword, sorting);
                }
              }}
              opacity={isLoadMore ? "" : "30%"}
            >
              {" "}
              <p>
                {t("LOAD")} <span>{t("MORE")}</span>
              </p>
            </CustomButton>
          </div>
        </div>
      ) : (
        ""
      )}

      <DeleteRoute
        handleClose={handleCloseDelete}
        show={showDelete}
        RouteId={RouteId}
        setShowDelete={setShowDelete}
        setShowtab={setShowtab}
        handleGetRoute={handleGetRoute}
        isEdit={false}
        sorting={sorting}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </div>
  );
}

export default Route;
