import { useContext, useEffect, useState } from "react";
import styles from "./Branding.module.css";
import { useTranslation } from "react-i18next";
import { TiThMenu } from "react-icons/ti";
import { FaRegBell } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaMedal } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { Form } from "react-bootstrap";
import CrossImg from "../../../Assests/Images/Cross.svg";
import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import TrainzaApi from "../../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  is_modal_open,
  setAppLogo,
  userLogoutClear,
} from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { useNavigate } from "react-router-dom";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import Loader from "../../../Common/Loader/Loader";
import {
  b64toBlob,
  capitalizeFirstLetter,
  fetchImageAsFile,
  getRandom,
} from "../../../Utils/CommonFunction";
import CropImg from "../../../Common/Crop/CropImg";
import mime from "mime";
import { CONSTANT } from "../../../Utils/Constant";
import { UserContext } from "../../../Context/UserContext";
import ColorPicker from "./ColorPicker";
import {
  CustomColorHolder,
  CustomColorHolderButton,
} from "../../../ReactBootstrap/CustomColorHolder/CustomColorHolder";
import { CustomColorInputField } from "../../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";

function Branding() {
  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [imageURL, setImageURL] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);
  const [isImgSelected, setIsImgSelected] = useState(true);
  const [showCrop, setShowCrop] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [inputId, setInputId] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isImageRemove, setIsImageRemove] = useState(false);
  const [userName, setUserName] = useState("");

  const [buttonTextColor, setButtonTextColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");

  const handleCloseColorPicker = () => setShowColorPicker(false);
  const handleShowColorPicker = () => setShowColorPicker(true);

  const handleCloseCrop = () => {
    setShowCrop(false);
    document.getElementById("imageInput").value = "";
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setIsImageRemove(false);
    setIsCropper(true);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setImageURL(fileUrl);
      setImageSrc(fileUrl);
      setIsAnyChange(true);
      setSaveChanges(false);
    } else {
      setImageURL(null);
    }
  };

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  useEffect(() => {
    getBrandDetail();
  }, []);

  const getBrandDetail = () => {
    const response = TrainzaApi.brandDetail(isAuthenticated);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        const brandInfo = response?.data?.branding;
        setUserName(response?.data?.brandInfo?.brandName);
        if (
          brandInfo?.btnTxtColor &&
          brandInfo?.btnBgColor &&
          brandInfo?.txtColor
        ) {
          setValue("BUTTON_TEXT_COLOR", brandInfo?.btnTxtColor);
          setValue("BACKGROUND_COLOR", brandInfo?.btnBgColor);
          setValue("TEXT_COLOR", brandInfo?.txtColor);

          setButtonTextColor(brandInfo?.btnTxtColor);
          setBgColor(brandInfo?.btnBgColor);
          setTextColor(brandInfo?.txtColor);

          setImageURL(brandInfo?.appLogo);
          setImageSrc(brandInfo?.appLogo);

          dispatch(
            setAppLogo({
              logo: brandInfo?.appLogo,
            })
          );
        } else {
          setValue("BUTTON_TEXT_COLOR", "#ffffff");
          setValue("BACKGROUND_COLOR", "#ff4300");
          setValue("TEXT_COLOR", "#ff4300");

          setButtonTextColor("#ffffff");
          setBgColor("#ff4300");
          setTextColor("#ff4300");

          dispatch(
            setAppLogo({
              logo: "",
            })
          );
        }
      }
    });
  };

  const fetchImageAsBinary = async (url) => {
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");
      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const OnSubmit = async () => {
    const PostData = new FormData();
    PostData.append("btnTxtColor", getValues("BUTTON_TEXT_COLOR"));
    PostData.append("btnBgColor", getValues("BACKGROUND_COLOR"));
    PostData.append("txtColor", getValues("TEXT_COLOR"));
    if (isCropper) {
      const imageFile = await fetchImageAsBinary(imageURL);
      PostData.append("appLogo", imageFile);
    }
    PostData.append("removeAppLogo", isImageRemove ? 1 : 0);
    setIsLoading(true);
    TrainzaApi.branding(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("BRANDING_ADDED_SUCCESSFULLY"), "success");
          setImageURL(null);
          setIsImgSelected(true);
          setImageSrc(null);
          setIsCropper(false);
          setIsAnyChange(false);
          setIsImageRemove(false);
          document.getElementById("imageInput").value = "";
          getBrandDetail();
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
          setSaveChanges(false);
        } else {
          Toster(t(ResponseCode), "error");
          setSaveChanges(false);
        }
      }
    });
  };

  const handleReset = () => {
    setValue("BUTTON_TEXT_COLOR", "#ffffff");
    setValue("BACKGROUND_COLOR", "#ff4300");
    setValue("TEXT_COLOR", "#ff4300");
    setButtonTextColor("#ffffff");
    setBgColor("#ff4300");
    setTextColor("#ff4300");
  };

  useEffect(() => {
    if (saveChanges) {
      OnSubmit();
    }
  }, [saveModalOpen]);

  useEffect(() => {
    if (inputId == "BTC") {
      setValue("BUTTON_TEXT_COLOR", selectedColor);
      setButtonTextColor(selectedColor);
    } else if (inputId == "BC") {
      setValue("BACKGROUND_COLOR", selectedColor);
      setBgColor(selectedColor);
    } else if (inputId == "TC") {
      setValue("TEXT_COLOR", selectedColor);
      setTextColor(selectedColor);
    }
  }, [selectedColor]);

  return (
    <>
      {isLoading && <Loader />}

      <div className={styles.brandHead}>
        <p>{capitalizeFirstLetter(userName)}</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.brandHeading}>
        {" "}
        <h3>{t("BRANDING")}</h3>
      </div>

      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div className={styles.btnStyle}>
          <div className={styles.btnStyleMatch}>
            <p>
              {t("BUTTON_STYLE")} <span>{t("MATCH_BRAND_COLORS")}</span>
            </p>
          </div>
          <div className={styles.dispalyColor}>
            <div className={styles.clorInput}>
              <div className={styles.exbtnLabel}>
                <p>
                  {t("BUTTON_TEXT_COLOR")} <span>{t("COLOR_TEXT")}</span>
                </p>
              </div>

              <div className={styles.colourInput}>
                <CustomColorHolder
                  customColor={buttonTextColor}
                  onClick={() => {
                    handleShowColorPicker();
                    setInputId(CONSTANT.BUTTON_TEXT_COLOR);
                  }}
                ></CustomColorHolder>

                <CustomColorInputField
                  RegisterName="BUTTON_TEXT_COLOR"
                  register={register}
                  formState={formState}
                  onChange={(e) => {
                    setIsAnyChange(true);
                    setButtonTextColor(e.target.value);
                    setValue(
                      "BUTTON_TEXT_COLOR",
                      e.target.value.replace(/\s/g, ""),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                ></CustomColorInputField>
              </div>

              <p className={styles.bottomHighlight}>{t("TEXT_COLOR_ON_BTN")}</p>
              <p className={styles.errorMsg}>
                {t(formState.errors.BUTTON_TEXT_COLOR?.message)}
              </p>
            </div>

            <CustomColorHolderButton
              Label={
                <p>
                  {t("STYLES")} <span>{t("EXAMPLE_TITLE")}</span>
                </p>
              }
              color={buttonTextColor}
              paragraph={t("BUTTONS_NAVIGATIONS")}
              bgColor={bgColor}
            ></CustomColorHolderButton>
          </div>
          <div className={styles.bottomBox}>
            <p>
              {t("BACKGROUND_COLOR")}{" "}
              <span> {t("BACKGROUND_COLOUR_TEXT")}</span>
            </p>

            <div className={styles.colourInput}>
              <CustomColorHolder
                customColor={bgColor}
                onClick={() => {
                  handleShowColorPicker();
                  setInputId(CONSTANT.BACKGROUND_COLOR);
                }}
              ></CustomColorHolder>

              <CustomColorInputField
                RegisterName="BACKGROUND_COLOR"
                register={register}
                formState={formState}
                onChange={(e) => {
                  setIsAnyChange(true);
                  setBgColor(e.target.value);
                  setValue(
                    "BACKGROUND_COLOR",
                    e.target.value.replace(/\s/g, ""),
                    {
                      shouldValidate: true,
                    }
                  );
                }}
              ></CustomColorInputField>
            </div>

            <div className={styles.brandBgText}>
              <p>{t("BACKGROUND_COLORS_ON_BUTTON")}</p>
            </div>
            <div className={styles.errorMsgs}>
              <p>{t(formState.errors.BACKGROUND_COLOR?.message)}</p>
            </div>
          </div>
          <div className={styles.resetColor}>
            <p>
              {t("COLOURED_TEXT")} <span>{t("COLORD_SPAN_TEXT")} </span>
            </p>
          </div>
          <div className={styles.dispalyColor}>
            <div className={styles.clorInput}>
              <div className={styles.exbtnLabel}>
                <p>
                  {t("TEXT_COLOR_LABEL")} <span>{t("COLOR_TEXT")}</span>
                </p>
              </div>

              <div className={styles.colourInput}>
                <CustomColorHolder
                  customColor={textColor}
                  onClick={() => {
                    handleShowColorPicker();
                    setInputId(CONSTANT.TEXT_COLOR);
                  }}
                ></CustomColorHolder>

                <CustomColorInputField
                  RegisterName="TEXT_COLOR"
                  register={register}
                  formState={formState}
                  onChange={(e) => {
                    setIsAnyChange(true);
                    setTextColor(e.target.value);
                    setValue("TEXT_COLOR", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}
                ></CustomColorInputField>
              </div>
              <p className={styles.bottomHighlight}>{t("TEXT_COLOR_WHITE")}</p>
              <p className={styles.errorMsg}>
                {t(formState.errors.TEXT_COLOR?.message)}
              </p>
            </div>

            <CustomColorHolderButton
              Label={
                <p>
                  {t("STYLES")} <span>{t("EXAMPLE_")}</span>
                </p>
              }
              color={textColor}
              paragraph={t("COLOURED_TEXT_WHITE_BG")}
            ></CustomColorHolderButton>
          </div>
          <div className={styles.tabbarText}>
            <p>
              {t("TABBAR_TEXT")} <span>{t("TABBAR_NAVIGATION")}</span>
            </p>
          </div>
          <div className={styles.tabBox}>
            <div className={styles.menuItem}>
              <p className={styles.calendersvgs}>
                <TiThMenu />
              </p>
              <p className={styles.calendersvgs}>
                <FaRegBell />
              </p>

              <p
                className={styles.calendersvg}
                style={{ backgroundColor: bgColor }}
              >
                {" "}
                <MdOutlineCalendarMonth />
              </p>
              <p className={styles.calendersvgs}>
                {" "}
                <LuLayoutDashboard />
              </p>
              <p className={styles.calendersvgs}>
                {" "}
                <FaMedal />
              </p>
            </div>
          </div>
          <div
            className={styles.resetDefault}
            onClick={() => {
              handleReset();
            }}
          >
            <p>
              {t("RESET_TEXT")} <span>{t("RESET_DEFAULT")}</span>
            </p>
          </div>
          <div className={styles.bottomText}>
            <p>
              {t("APP_LOGO")} - <span>{t("BRAND_YOUR_APP")}</span>
            </p>
            <h5>{t("LOGO_PARA")}</h5>
          </div>
          <div className={styles.btnSection}>
            <div className={styles.uploadBox}>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="imageInput"
                onChange={handleFileChange}
              />
              <CustomButton
                variant="footerBtnCustom"
                type="button"
                onClick={() => document.getElementById("imageInput").click()}
              >
                <p>
                  {t("SELECT")} <span>{t("IMAGE")}</span>
                </p>
              </CustomButton>
            </div>
          </div>

          {imageURL && (
            <div className={styles.previewImgBox}>
              <img src={imageURL} alt="img" className={styles.previewImg}></img>
              <img
                src={CrossImg}
                alt="crosImg"
                className={styles.removeImg}
                onClick={() => {
                  setIsImageRemove(true);
                  setImageURL(null);
                  setIsImgSelected(false);
                  setImageSrc(null);
                  setIsCropper(false);
                  setIsAnyChange(false);
                  document.getElementById("imageInput").value = "";
                  // fileInputRef.current.value = "";
                }}
              ></img>
            </div>
          )}
          <div className={styles.devider}></div>
          <div className={styles.saveBtnSection}>
            <CustomButton variant="footerBtnCustom" type="submit">
              <p>{t("SAVE")}</p>
            </CustomButton>
          </div>
        </div>
      </Form>

      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setImageURL}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRANDING}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
      />

      <ColorPicker
        show={showColorPicker}
        handleClose={handleCloseColorPicker}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
    </>
  );
}
export default Branding;
