import React, { useEffect, useRef, useState } from "react";
import CalenderComp from "./CalenderComp";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import styles from "./LogBook.module.css";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Form } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { CONSTANT } from "../../../Utils/Constant";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomSelect } from "../../../ReactBootstrap/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { MONTH_CODE, MONTH_NO_CODE } from "../../../Utils/MonthCode";
import Loader from "../../../Common/Loader/Loader";
import DeleteResult from "./DeleteResult";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import {
  CustomInputField,
  CustomInputFieldLogbook,
} from "../../../ReactBootstrap/CustomInputField/CustomInputField";

function EditLogbook({
  member_id,
  logBookId,
  workoutData,
  setAddLogbook,
  setLogBookId,
  handleGetLogbook,
  setSelectedWorkout,
  setDay1,
  setMonth1,
  setSelectedDate1,
  handleDeleteLogbook,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousRef = useRef();
  const nextRef = useRef();
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);
  const [activeBtn, setActiveBtn] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleShowDate = () => setShowDate(true);
  const [workoutList, setWorkoutList] = useState([]);
  const [workoutType, setWorkoutType] = useState("");
  const handleCloseDate = () => setShowDate(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isDistanceAdded, setIsDistanceAdded] = useState();
  const [effortLevel, setEffortLevel] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [pace, setPace] = useState("0.0");
  const [logbookList, setLogbookList] = useState();
  const [kilometer, setKilometer] = useState(0);
  const [meter, setMeter] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [distanceError, setDistanceError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  //==== function for handle distance time 0=====
  const addLeadingZero = (input) => {
    let str = input?.toString();
    if (str?.length === 1) {
      return "0" + str;
    }
    if (str?.length > 1 && str.startsWith("0")) {
      return str.slice(1);
    }
    return str;
  };

  //======function for handle validation=====
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
  });

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const currentDate = new Date();
  const daysOfWeek = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];
  const monthsOfYear = [
    t("JANUARY"),
    t("FEB"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEP"),
    t("OCT"),
    t("NOV"),
    t("DEC"),
  ];

  useEffect(() => {
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = day;

    if (day.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }

    const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;

    setSelectedDate(rightFormatOfDate);
  }, [day, year, month, weekday]);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  useEffect(() => {
    setValue("WORKOUT_TYPES", workoutType);
  }, [workoutType, logbookList, workoutList]);

  //===== function for calling workout type list api====
  const handleWorkoutTypeList = () => {
    const response = TrainzaApi.getLogbookWorkoutType(isAuthenticated);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setWorkoutList(response?.data);
      }
    });
  };

  useEffect(() => {
    handleWorkoutTypeList("");
  }, []);

  // =====function for select workout type======
  const handleWorkout = (e) => {
    setValue("WORKOUT_TYPES", e.target.value);
    setWorkoutType(e.target.value);
  };

  //===== Handle checkbox change=========
  const handleCheckboxChange = (e) => {
    setIsDistanceAdded(e.target.checked ? 1 : 0);
  };

  const clearAllInputs = () => {
    setKilometer(0);
    setMeter(0);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
    setPace("0.00");
  };

  useEffect(() => {
    if (kilometer > 0 || meter > 0) {
      const distance = `${kilometer ? kilometer : 0}.${meter ? meter : 0}`;
      const time = `${hours}:${addLeadingZero(minutes)}:${addLeadingZero(
        seconds
      )}`;
      const newPace = calculatePace(time, distance);
      setPace(newPace);
    } else {
      setPace("0.00");
    }
  }, [hours, minutes, seconds, kilometer, meter]);

  // =====Function to calculate pace======
  const calculatePace = (time, distance) => {
    const [hours = 0, minutes = 0, seconds = 0] = time.split(":").map(Number);

    const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

    const numericDistance = parseFloat(distance);

    const paceInSecondsPerKm = totalTimeInSeconds / numericDistance;

    var paceMinutes = Math.floor(paceInSecondsPerKm / 60);
    var paceSeconds = Math.round(paceInSecondsPerKm % 60);

    if (paceSeconds == 60) {
      paceSeconds = "00";
      paceMinutes = paceMinutes + 1;
    }

    const formattedPace = `${paceMinutes}.${String(paceSeconds).padStart(
      2,
      "0"
    )}`;

    return formattedPace;
  };

  //===== Function to format time with leading zeros for minutes and seconds====
  const formatTimeInCorrectFormat = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return `${hours}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
  };

  //======== function for add workout logbbok==========
  async function OnSubmit(data) {
    if (Number(kilometer) || Number(meter)) {
      setDistanceError(false);
    } else {
      setDistanceError(true);
    }

    if (seconds || minutes || hours) {
      setTimeError(false);
    } else {
      setTimeError(true);
    }

    const PostData = new FormData();
    if (workoutData && workoutData?.memberWorkoutId) {
      PostData.append("workoutId", workoutData?.memberWorkoutId);
    }
    PostData.append("workoutTypeId", workoutType);
    let effortLevel;
    if (activeBtn === 0) effortLevel = CONSTANT.EASY;
    else if (activeBtn === 1) effortLevel = CONSTANT.NORMAL;
    else if (activeBtn === 2) effortLevel = CONSTANT.HARD;
    PostData.append("effortLevel", effortLevel);
    PostData.append("date", selectedDate);
    PostData.append("isDistance", isDistanceAdded ? isDistanceAdded : 0);
    const totalTime = `${hours}:${addLeadingZero(minutes)}:${addLeadingZero(
      seconds
    )}`;
    let isDistanceValid = true;
    let isTimeValid = true;

    if (isDistanceAdded === 1) {
      isDistanceValid = kilometer > 0 || meter > 0;
      PostData.append(
        "distance",
        `${kilometer ? kilometer : 0}.${meter ? meter : 0}`
      );

      isTimeValid = hours > 0 || minutes > 0 || seconds > 0;
      PostData.append(
        "time",
        formatTimeInCorrectFormat(`${hours}:${minutes}:${seconds}`)
      );
      if (isDistanceValid && isTimeValid) {
        const pace = calculatePace(
          totalTime,
          `${kilometer || 0}.${meter || 0}`
        );
        PostData.append("pace", pace);
      } else {
        if (!isDistanceValid) console.error("Invalid distance");
        if (!isTimeValid) console.error("Invalid time");
        return;
      }
    }

    PostData.append(
      "notes",
      data.WORKOUT_NOTES ? data.WORKOUT_NOTES.trim() : ""
    );
    PostData.append(
      "coachComments",
      data.WORKOUT_COACH_COMMENT ? data.WORKOUT_COACH_COMMENT.trim() : ""
    );
    if (
      isDistanceValid &&
      isTimeValid &&
      (!isDistanceAdded || (isDistanceAdded && !distanceError)) &&
      (!isDistanceAdded || (isDistanceAdded && !timeError))
    ) {
      setIsLoading(true);
      TrainzaApi.AddWorkoutLogbook(isAuthenticated, PostData, member_id).then(
        (response) => {
          setIsLoading(false);
          const ResponseCode = STATUS_MSG[response?.data?.code];
          if (
            response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            response?.data?.code === STATUS_CODE.SESSION_EXPIRED
          ) {
            dispatch(userLogoutClear());
            Toster(t("SESSION_EXPIRED"), "error");
          } else if (
            response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
          ) {
            Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
            navigate("/members");
          } else {
            if (response?.code === STATUS_CODE.SUCCESS) {
              if (logBookId) {
                Toster(t("LOGBOOK_UPDATED"), "success");
              } else {
                Toster(t("LOGBOOK_ADDED"), "success");
              }
              setAddLogbook(false);
              handleGetLogbook(selectedDate);
              setMonth1(MONTH_NO_CODE[selectedDate?.substring(5, 7)]);
              setDay1(selectedDate?.substring(8, 10));
              setSelectedDate1(selectedDate);
              setLogBookId("");
              setIsLoading(false);
            } else if (response?.data?.code === STATUS_CODE.VALIDATION_ERROR) {
              Toster(response?.data?.message, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        }
      );
    }
  }

  //=== function for show data prefilled===========
  useEffect(() => {
    if (workoutData) {
      setValue("WORKOUT_TYPES", workoutData.workoutTypeId);
      setWorkoutType(workoutData.workoutTypeId);
      setSelectedDate(workoutData.date);
      setYear(workoutData.date?.substring(0, 4));
      setMonth(MONTH_NO_CODE[workoutData.date?.substring(5, 7)]);
      setDay(workoutData.date?.substring(8, 10));

      setSelectedDate(workoutData.date);

      let effortLevel;
      let activeBtn;
      if (workoutData.effortLevel === 1) {
        effortLevel = CONSTANT.EASY;
        activeBtn = 0;
      } else if (workoutData.effortLevel === 2) {
        effortLevel = CONSTANT.NORMAL;
        activeBtn = 1;
      } else if (workoutData.effortLevel === 3) {
        effortLevel = CONSTANT.HARD;
        activeBtn = 2;
      } else {
        effortLevel = "";
        activeBtn = -1;
      }
      setEffortLevel(effortLevel);
      setActiveBtn(activeBtn);
      setIsDistanceAdded(workoutData.isDistance);

      setPace(workoutData.pace);
      if (workoutData.time) {
        const [hrs, mins, secs] = workoutData.time.split(":").map(Number);
        setHours(hrs);
        setMinutes(mins);
        setSeconds(secs);
      }
      setValue("WORKOUT_NOTES", workoutData.notes);
      setValue("WORKOUT_COACH_COMMENT", workoutData.coachComments);

      const distance =
        workoutData?.distance !== undefined && workoutData?.distance !== null
          ? workoutData.distance.toString()
          : "0.0";
      const [km, m] = distance.split(".");
      setKilometer(km);
      setMeter(m ? m : "0");
    }
    if (workoutData && workoutData.date) {
      const date = new Date(workoutData.date);
      const weekday = daysOfWeek[date.getDay()];
      const day = date.getDate();
      const month = monthsOfYear[date.getMonth()];
      const year = date.getFullYear();

      setWeekday(weekday);
      setDay(day);
      setMonth(month);
      setYear(year);
      setSelectedDate(workoutData.date);
    }
  }, [workoutData, logbookList]);

  //===== function for handle time inputs==========
  const handleCangeHrs = (e) => {
    let inputValue = e.target.value.replace(".", "");
    if (inputValue) {
      setTimeError(false);
    } else {
      setTimeError(true);
    }
    if (inputValue >= 0) {
      let cleanedValue = inputValue.replace(/^0+/, "");
      if (cleanedValue === "") {
        cleanedValue = "0";
      }
      if (cleanedValue < 1000) {
        setHours(cleanedValue);
      }
    }
  };

  const handleCangeMins = (e) => {
    let inputValue = e.target.value.trim();
    if (inputValue) {
      setTimeError(false);
    } else {
      setTimeError(true);
    }
    if (inputValue < 60) {
      if (inputValue == 0) {
        setMinutes(0);
      } else {
        if (/^\d{2}$/.test(inputValue)) {
          setMinutes(inputValue);
          return;
        }

        if (/^\d+$/.test(inputValue)) {
          inputValue = parseInt(inputValue, 10).toString();
        }

        if (inputValue === "") {
          setMinutes("");
          return;
        }
        const intValue = parseInt(inputValue, 10);
        if (intValue >= CONSTANT.MIN_MIN && intValue <= CONSTANT.MAX_MIN) {
          const formattedValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
          setMinutes(formattedValue);
        } else {
          console.log("Value out of range or invalid");
        }
      }
    }
  };

  const handleCangeSecs = (e) => {
    let inputValue = e.target.value.trim();
    if (inputValue) {
      setTimeError(false);
    } else {
      setTimeError(true);
    }
    if (inputValue < 60) {
      if (inputValue == 0) {
        setSeconds(0);
      } else {
        if (/^\d{2}$/.test(inputValue)) {
          setSeconds(inputValue);
          return;
        }

        if (/^\d+$/.test(inputValue)) {
          inputValue = parseInt(inputValue, 10).toString();
        }

        if (inputValue === "") {
          setSeconds("");
          return;
        }

        const intValue = parseInt(inputValue, 10);

        if (intValue >= CONSTANT.MIN_SEC && intValue <= CONSTANT.MAX_SEC) {
          const formattedValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
          setSeconds(formattedValue);
        } else {
          console.log("Value out of range or invalid");
        }
      }
    }
  };

  const distanceRef = useRef(null);
  const timeRef = useRef(null);

  useEffect(() => {
    if (!formState.errors.WORKOUT_TYPES && distanceError) {
      distanceRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [distanceError]);

  useEffect(() => {
    if (!formState.errors.WORKOUT_TYPES && timeError) {
      timeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [timeError]);

  // const handleKeyPress = (e, t) => {
  //   if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  // };

  //======== function for prevent white space==============
  const handleKeyDown = (e) => {
    if (e.target.value === "" && (e.key === " " || e.key === "Enter")) {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    if (e.target.value.startsWith(" ")) {
      setValue(e.target.name, e.target.value.trim());
    }
  };

  const RemoveleadingZero = (num) => {
    // Convert to string, check length, and remove leading zero if needed
    return num.length > 2 ? num.replace(/^0/, "") : num;
  };

  return (
    <div>
      {isLoading && <Loader />}
      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div className={styles.addWorkoutBox}>
          <div className={styles.scheduleHeaderForAdd}>
            <div className={styles.scheduleBoxOne}>
              <div
                className={styles.iconHolder}
                onClick={callgoToPreviousMonth}
              >
                <FaLessThan size="15" color="#ff4300" />
              </div>
              <div className={styles.scheduleDate}>
                <p>
                  <span>{weekday}</span> {day} {month} {year}
                </p>
              </div>
              <div className={styles.iconHolder} onClick={callgoToNextMonth}>
                <FaGreaterThan size="15" color="#ff4300" />
              </div>
            </div>
            <MdOutlineCalendarMonth
              size="24"
              color="#FFFFFF"
              onClick={handleShowDate}
            />
          </div>

          <div className={styles.selectWorkoutBox}>
            <CustomSelect
              Label={
                <p>
                  {t("WORKOUT_TITLE_TEXT")} <span>{t("TYPE_")}</span> (
                  {t("REQUIRED_")})
                </p>
              }
              styleName="selectOptionWorkout"
              RegisterName={"WORKOUT_TYPES"}
              register={register}
              formState={formState}
              onChangeHandler={handleWorkout}
              defaultKey="Select Workout Type"
              arrayData={workoutList}
              disabled={workoutList?.length === 0}
              defaultValue=""
            ></CustomSelect>
          </div>

          <div className={styles.effortLevelBox}>
            <div className={styles.effortLevelHead}>
              <p>
                {t("EFFORT")} <span>{t("LEVEL")}</span>
              </p>
            </div>
            <div className={styles.effortLevelBtn}>
              <button
                type="button"
                className={
                  activeBtn === 0
                    ? styles.effortLevelBtnActive
                    : styles.effortLevelBtnInactive
                }
                onClick={() => setActiveBtn(0)}
              >
                {t("EASY")}
              </button>
              <button
                type="button"
                className={
                  activeBtn === 1
                    ? styles.effortLevelBtnActive
                    : styles.effortLevelBtnInactive
                }
                onClick={() => setActiveBtn(1)}
              >
                {t("NORMAL")}
              </button>
              <button
                type="button"
                className={
                  activeBtn === 2
                    ? styles.effortLevelBtnActive
                    : styles.effortLevelBtnInactive
                }
                onClick={() => setActiveBtn(2)}
              >
                {t("HARD")}
              </button>
            </div>
          </div>

          <div className={styles.checkBoxAndLabelArea}>
            <Form.Check
              type="checkbox"
              checked={isDistanceAdded === 1}
              onChange={handleCheckboxChange}
              className={styles.checkbox}
            />
            <p>{t("DISTANCE_AND_TIME")}</p>
          </div>

          {isDistanceAdded === 1 && (
            <>
              <div className={styles.timeDurationBox}>
                <p>{t("DISTANCE_")}</p>
                <div className={styles.distanceTravel}>
                  <input
                    ref={distanceRef}
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={RemoveleadingZero(kilometer)}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        setDistanceError(false);
                      } else {
                        setDistanceError(true);
                      }
                      if (/^\d{0,3}$/.test(value)) {
                        setKilometer(value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "." || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    className={
                      formState.errors.kilometer
                        ? styles.inputError
                        : styles.input
                    }
                  />
                  <p>.</p>
                  <input
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={meter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        setDistanceError(false);
                      } else {
                        setDistanceError(true);
                      }
                      if (/^\d{0,2}$/.test(value)) {
                        setMeter(value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "." || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    className={
                      formState.errors.meter ? styles.inputError : styles.input
                    }
                  />
                </div>

                {isDistanceAdded == 1 &&
                  ((!kilometer && !meter) ||
                    (Number(kilometer) < 1 && Number(meter) < 1)) &&
                  (distanceError ? (
                    <p className={styles.inputError}>Please enter distance</p>
                  ) : (
                    ""
                  ))}
              </div>

              <div className={styles.timeDurationBox}>
                <p>{t("TIME_")}</p>
                <div className={styles.timeTaken}>
                  <div className={styles.timeBox}>
                    <input
                      ref={timeRef}
                      name="hours"
                      // type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      {...register("hours", {
                        required: isDistanceAdded === 1,
                        min: 0,
                        max: 999,
                      })}
                      value={hours}
                      onChange={handleCangeHrs}
                      className={
                        formState.errors.hours
                          ? styles.inputError
                          : styles.input
                      }
                    />
                    <p>{t("HOURS")}</p>
                  </div>
                  <p>:</p>
                  <div className={styles.timeBox}>
                    <input
                      name="minutes"
                      // type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      {...register("minutes", {
                        required: isDistanceAdded === 1,
                        min: 0,
                        max: 59,
                      })}
                      value={minutes}
                      onChange={handleCangeMins}
                      className={
                        formState.errors.minutes
                          ? styles.inputError
                          : styles.input
                      }
                    />
                    <p>{t("MINS")}</p>
                  </div>
                  <p>:</p>
                  <div className={styles.timeBox}>
                    <input
                      name="seconds"
                      // type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      {...register("seconds", {
                        required: isDistanceAdded === 1,
                        min: 0,
                        max: 59,
                      })}
                      value={seconds}
                      onChange={handleCangeSecs}
                      className={
                        formState.errors.seconds
                          ? styles.inputError
                          : styles.input
                      }
                    />
                    <p>{t("SECS")}</p>
                  </div>
                </div>

                {(isDistanceAdded == 1 && !hours && !minutes && !seconds) ||
                (hours < 1 && minutes < 1 && seconds < 1) ? (
                  timeError ? (
                    <p className={styles.inputError}>Please enter time</p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className={styles.paceInfo}>
                <h1>
                  {t("PACE")}: {pace || "0.00"}/{t("KM")}
                </h1>
                <p onClick={clearAllInputs}>{t("CLEAR_INPUTS")}</p>
              </div>
              <div className={styles.devider}></div>
            </>
          )}

          <div className={styles.workoutNotesSection}>
            <p>
              {t("WORKOUT")} <span>{t("NOTES_")}</span>
            </p>

            <CustomInputFieldLogbook
              styleName="inputTextForAddLogbook"
              as="textarea"
              type="text"
              PlaceHolder={t("DETAILS_OF_YOUR_WORKOUT")}
              RegisterName={"WORKOUT_NOTES"}
              register={register}
              formState={formState}
              // onChange={(e) => {
              //   handleKeyPress(e);
              // }}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            ></CustomInputFieldLogbook>
          </div>

          <div className={styles.coachCommentSection}>
            <p>
              {t("COACH")} <span>{t("COMMENTS_")}</span>
            </p>
            <h1>{t("ADD_WORKOUT_COMMNET_BACK_TO_MEMBER_HERE")}</h1>

            <CustomInputFieldLogbook
              styleName="inputTextForAddLogbook"
              as="textarea"
              type="text"
              PlaceHolder={t("ADD_CMT_HERE")}
              RegisterName={"WORKOUT_COACH_COMMENT"}
              register={register}
              formState={formState}
              // onChange={(e) => {
              //   handleKeyPress(e);
              // }}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            ></CustomInputFieldLogbook>
          </div>

          <div className={styles.btnSection}>
            <div className={styles.cancelDeleteBtn}>
              <CustomButton
                variant={"cancelBtn"}
                type="button"
                onClick={() => {
                  setLogBookId("");
                  setAddLogbook(false);
                  setSelectedWorkout("");
                }}
              >
                {t("CANCEL")}
              </CustomButton>
              {logBookId && (
                <CustomButton
                  variant={"cancelBtn"}
                  type="button"
                  onClick={() => {
                    handleShowDelete();
                    setLogBookId(logBookId);
                  }}
                >
                  <RiDeleteBin6Line size="20" color="#000000" />
                  {t("DELETE")}
                </CustomButton>
              )}
            </div>

            <CustomButton variant="footerBtnCustom" type="submit">
              <p>{t("SAVE")}</p>
            </CustomButton>
          </div>
        </div>
      </Form>

      <CalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
        selectedDate={selectedDate}
        eventData={CONSTANT.ALLOW_PREVIOUS}
      />

      <DeleteResult
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteGroup={handleDeleteLogbook}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EditLogbook;
