import React, { useCallback, useEffect, useState } from "react";
import debounce from "debounce";
import styles from "./Invites.module.css";
import { Col, Row } from "react-bootstrap";
import PlayStore from "../../Assests/Images/PlayStore.svg";
import Huawei from "../../Assests/Images/HuaweiLogo.svg";
import GalaxyLogo from "../../Assests/Images/GalaxyStore.svg";
import { FaApple } from "react-icons/fa6";
import ProfileDemo from "../../Assests/Images/ProfileDemo.svg";
import SendInvite from "./SendInvite";
import { SEND_INVITATION } from "../../Utils/MockData";
import CancelInvite from "./CancelInvite";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../Helpers/Api";
import Toster from "../../Common/Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { capitalizeFirstLetter } from "../../Utils/CommonFunction";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import Loader from "../../Common/Loader/Loader";
import { CONSTANT } from "../../Utils/Constant";
function Invites() {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [sendInvite, setSendInvite] = useState(false);
  const [cancelInvite, setCancelInvite] = useState(false);

  const [inviteId, setInviteId] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const [pendingInviteList, setPendingInviteList] = useState([]);

  const handleSendInvite = () => {
    setSendInvite(!sendInvite);
  };

  const handleCancelInvite = () => {
    setCancelInvite(!cancelInvite);
  };

  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = useCallback(
    debounce((event) => {
      setSearchKeyword(event);
    }, 500),
    []
  );

  function HighlightKeyInParagraph({ keyWord = "", paragraph = "" }) {
    // Function to render the paragraph with highlighted keyWord
    const renderHighlightedParagraph = () => {
      // Ensure paragraph is a string
      if (typeof paragraph !== "string") {
        return <span>Invalid paragraph content</span>;
      }

      // Split the paragraph using the keyWord and render the highlighted parts
      const parts = paragraph.split(new RegExp(`(${keyWord})`, "gi"));

      return parts.map((part, index) =>
        part.toLowerCase() === keyWord.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "15px",
              background: "#FF4300",
              margin: "0px",
              color: "#FFFFFF",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <h1 style={{ margin: "0px" }}>{renderHighlightedParagraph()}</h1>;
  }

  const handleSearchGrpMember = (page, search) => {
    const response = TrainzaApi.searchMember(isAuthenticated, search, page);

    response.then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setLoadMore(response?.data?.loadMore);
        setPageNo(page + 1);
        if (page == 1) {
          setMemberList(response?.data?.memberList);
        } else {
          setMemberList([...memberList, ...response?.data?.memberList]);
        }
      }
    });
  };

  useEffect(() => {
    handleSearchGrpMember(1, searchKeyword);
  }, [searchKeyword]);

  const handleSendInvitation = (id) => {
    const PostData = new FormData();
    PostData.append("memberId", id);
    setIsLoading(true);
    TrainzaApi.SendInvitation(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          handleSendInvite();
          handlePendingInvite();
          const filteredMemberList = memberList?.filter(
            (member) => member.id !== id
          );
          setMemberList(filteredMemberList);
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handlePendingInvite = () => {
    const response = TrainzaApi.GetPendingInvite(isAuthenticated);
    response.then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setPendingInviteList(response?.data?.memberPendingInviteList);
      }
    });
  };

  useEffect(() => {
    handlePendingInvite();
  }, []);

  const handleDeleteInvite = () => {
    const response = TrainzaApi.DeletePendingInvite(isAuthenticated, inviteId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } 
      else if (response?.data?.code == STATUS_CODE.INVALID_INVITATION_ID) {
        Toster(t("INVALID_INVITATION_ID"), "error");
        setCancelInvite(false);
        handlePendingInvite();
      } else {
        Toster(t("INVITATION_CANCELLATION_SUCCESSFULLY"), "success");
        setCancelInvite(false);
        handlePendingInvite();
      }
    });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.inviteLabel}>
        <p>{t("ASK_YOUR_MEMBER")}</p>
      </div>

      <div className={styles.CompanyHolder}>
        <Row lg={2}>
          <Col>
            <div className={styles.CompanySection}>
              <img src={PlayStore} alt="playStore"></img>
              <div className={styles.CompanyInfo}>
                <h1>{t("GOOGLE_PLAY")}</h1>
                <p>{t("GET_IT_ON")}</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className={styles.CompanySection}>
              <FaApple color="white" size="30" />
              <div className={styles.CompanyInfo}>
                <h1>{t("DOWNLOAD_ON_THE")}</h1>
                <p>{t("APPLE_STORE")}</p>
              </div>
            </div>
          </Col>

          <Col>
            <div className={styles.CompanySection}>
              <img src={GalaxyLogo} alt="galaxy"></img>
              <div className={styles.CompanyInfo}>
                <h1>{t("AVAILABLE_ON")}</h1>
                <p>{t("GALAXY_STORE")}</p>
              </div>
            </div>
          </Col>

          <Col>
            <div className={styles.CompanySection}>
              <img src={Huawei} alt="huiwei"></img>
              <div className={styles.CompanyInfo}>
                <h1>{t("EXPLORE_IT_ON")}</h1>
                <p>{t("APPGALAXY")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.searchBox}>
        <p>
          {t("SEARCH")} <span>{t("MEMBER_SMALL")}</span>
        </p>
        <input
          placeholder={t("SEARCH_MEMBER_NAME")}
          onChange={(e) => handleChange(e.target.value)}
        ></input>
        <div className={styles.profleImg}>
          <img src={ProfileDemo} alt="ProfileDemo"></img>
        </div>

        {searchKeyword !== "" && (
          <React.Fragment>
            {memberList?.map((item, index) => {
              return (
                <div className={styles.searchResult} key={index}>
                  <div className={styles.searchInfo}>
                    <img
                      src={
                        item.profilePicture ? item.profilePicture : ProfileDemo
                      }
                      alt="ProfileDemo"
                    ></img>
                    <div className={styles.userDetail}>
                      <h1>
                        {" "}
                        <HighlightKeyInParagraph
                          keyWord={searchKeyword}
                          paragraph={`${capitalizeFirstLetter(
                            item?.firstName
                          )} ${capitalizeFirstLetter(
                            item?.lastName ? item?.lastName : ""
                          )}`}
                        />
                      </h1>
                      <p>
                        <HighlightKeyInParagraph
                          keyWord={searchKeyword}
                          paragraph={capitalizeFirstLetter(item.email ? item.email :"")}
                        />
                      </p>
                    </div>
                  </div>
                  <CustomButton
                    variant="footerBtnCustom"
                    onClick={() => {
                      handleSendInvitation(item.id);
                    }}
                  >
                    <p>
                      <span>{t("INVITE")}</span>
                    </p>
                  </CustomButton>
                </div>
              );
            })}

            {/* MO RESULT FOUND FOR SEARCHING */}
            {memberList?.length === 0 ? (
              <React.Fragment>
                <div className={styles.noDataFound}>
                  <p>
                    {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
                  </p>
                </div>
                <div className={styles.devider}></div>
              </React.Fragment>
            ) : (
              ""
            )}
            {memberList?.length !== 0 ? (
              <div
                className={styles.searchResult}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <CustomButton
                    variant="footerBtnCustom"
                    onClick={() => {
                      if (loadMore) {
                        handleSearchGrpMember(pageNo, searchKeyword);
                      }
                    }}
                    opacity={loadMore ? "" : CONSTANT.OPACITY_RATIO}
                  >
                    {" "}
                    <p>
                      {t("LOAD")} <span>{t("MORE")}</span>
                    </p>
                  </CustomButton>
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        )}
      </div>

      {pendingInviteList?.length !== 0 ? (
        <div className={styles.pendingInvitesBox}>
          <div className={styles.pendingInvitesBoxLabel}>
            <h1>
              {t("PENDING")}{" "}
              <span>
                {" "}
                {pendingInviteList?.length > 1 ? "Invites" : "Invite"}
              </span>
            </h1>
            <p>{t("PENDING_INVITE_COUNTS")}</p>
          </div>

          <div>
            {pendingInviteList?.map((item, index) => {
              return (
                <div className={styles.searchResult} key={index}>
                  <div className={styles.searchInfo}>
                    <img
                      src={
                        item?.profilePicture
                          ? item?.profilePicture
                          : ProfileDemo
                      }
                      alt="ProfileDemo"
                    ></img>
                    <div className={styles.userDetail}>
                      <h1>
                        {capitalizeFirstLetter(item.firstName)} {item?.lastName ? item?.lastName : ""}
                      </h1>
                      <p>{item.email ? item.email :""}</p>
                    </div>
                  </div>
                  <CustomButton
                    variant="footerBtnCustom"
                    onClick={() => {
                      handleCancelInvite();
                      setInviteId(item?.id);
                    }}
                  >
                    <p>
                      <span>{t("CANCEL")}</span>
                    </p>
                  </CustomButton>
                  {/* <button onClick={handleCancelInvite}>{t("CANCEL")}</button> */}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <SendInvite
        handleClose={handleSendInvite}
        show={sendInvite}
        setSearchKeyword={setSearchKeyword}
      />
      <CancelInvite
        handleClose={handleCancelInvite}
        show={cancelInvite}
        handleCancel={handleDeleteInvite}
      />
    </div>
  );
}

export default Invites;
