import { Form } from "react-bootstrap";
import styles from "./CustomSelect.module.css";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import { useTranslation } from "react-i18next";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useEffect, useRef } from "react";


export const CustomSelect = ({
  Label,
  RegisterName,
  register,
  formState,
  onChangeHandler,
  defaultKey,
  arrayData,
  disabled,
  defaultValue,
  styleName,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, {
          ...ValidationSchema[RegisterName],
          onChange: onChangeHandler,
        })}
        isInvalid={!!formState.errors[RegisterName]}
        disabled={disabled}
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        <option value={defaultValue} defaultValue>
          {defaultKey}
        </option>

        {arrayData &&
          arrayData?.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
      </Form.Select>

      <Form.Control.Feedback type="invalid">
        {t(formState.errors.COUNTRY?.message)}
      </Form.Control.Feedback>
      <RiArrowDropDownLine color="#424242" />
    </Form.Group>
  );
};

export const TraningCustomSelect = ({
  Label,
  RegisterName,
  register,
  formState,
  onChangeHandler,
  defaultKey,
  arrayData,
  disabled,
  defaultValue,
  styleName,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        disabled={disabled}
        onChange={onChangeHandler}
      >
        <option value={defaultValue} defaultValue>
          {defaultKey}
        </option>

        {arrayData &&
          arrayData?.map((item, index) => (
            <option value={item.id} key={index}>
              {item.title}
            </option>
          ))}
      </Form.Select>

      <Form.Control.Feedback type="invalid">
        {t(formState.errors.COUNTRY?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const RouteCustomSelect = ({
  Label,
  RegisterName,
  register,
  formState,
  onChangeHandler,
  defaultKey,
  arrayData,
  disabled,
  defaultValue,
  styleName,
}) => {
  const { t } = useTranslation();
  const selectRef = useRef(null);

  useEffect(() => {
    const handleOpenDirection = () => {
      const selectElement = selectRef.current;
      if (!selectElement) return;

      const rect = selectElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow < 200 && spaceAbove > spaceBelow) {
        selectElement.classList.add("open-upwards");
      } else {
        selectElement.classList.remove("open-upwards");
      }
    };

    window.addEventListener("scroll", handleOpenDirection);
    window.addEventListener("resize", handleOpenDirection);
    handleOpenDirection(); // Initial call

    return () => {
      window.removeEventListener("scroll", handleOpenDirection);
      window.removeEventListener("resize", handleOpenDirection);
    };
  }, []);

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        disabled={disabled}
        style={{ maxHeight: "200px", overflowY: "auto" }}
        onChange={onChangeHandler}
      >
        <option value={defaultValue} defaultValue>
          {defaultKey}
        </option>

        {arrayData &&
          arrayData?.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
      </Form.Select>

      <Form.Control.Feedback type="invalid">
        {t(formState.errors.COUNTRY?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
