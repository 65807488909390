import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./Gallery.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../ReactBootstrap/CustomTabButton";
import CrossImg from "../../../../Assests/Images/Cross.svg";
import { CONSTANT } from "../../../../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toster from "../../../../Common/Toster/Toster";
import Loader from "../../../../Common/Loader/Loader";
import { userLogoutClear } from "../../../../redux/slices/userSlice";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";
import { STATUS_CODE } from "../../../../Utils/StatusCode";
import TrainzaApi from "../../../../Helpers/Api";
import {
  capitalizeFirstLetter,
  modifySingularPlural,
} from "../../../../Utils/CommonFunction";
import DeleteResult from "../../../Members/MemberDetail/DeleteResult";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

function EventGallery({ eventId, eventTitle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [isUpdateSeq, setIsUpdateSeq] = useState(false);
  const [binaryImg, setBinaryImg] = useState(null);
  const [uploadedImg, setUploadedImg] = useState([]);
  const [thumbnails, setThumbnails] = useState("");

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  // old function for only orignal images
  // const handleFileSelect = (event) => {
  //   const file = event.target.files;
  //   setBinaryImg(file);
  // };

  //=====  handle center cropped image with thumbnail=======
  const handleFileSelect = async (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files);
    const createThumbnail = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Determine the size for 1:1 aspect ratio
            const size = Math.min(img.width, img.height);
            canvas.width = size;
            canvas.height = size;

            // Calculate the center crop coordinates
            const xOffset = (img.width - size) / 2;
            const yOffset = (img.height - size) / 2;

            // Draw the cropped 1:1 aspect ratio image
            ctx.drawImage(img, xOffset, yOffset, size, size, 0, 0, size, size);

            // Convert canvas to blob
            canvas.toBlob((blob) => {
              resolve(blob);
            }, "");
          };
        };
        reader.onerror = (error) => reject(error);
      });
    };

    const binaryImages = [];
    const thumbnails = [];

    for (const file of imageFiles) {
      binaryImages.push(file);
      const thumbnail = await createThumbnail(file);
      thumbnails.push(thumbnail);
    }

    setBinaryImg(binaryImages); // Store original images
    setThumbnails(thumbnails); // Store the generated thumbnails
  };

  // function for handle post with thumbnails
  const handlePostImage = () => {
    const PostData = new FormData();
    // for (let i = 0; i < binaryImg.length; i++) {
    //   PostData.append("images", binaryImg[i]);
    // }
    // for (let i = 0; i < thumbnails.length; i++) {
    //   PostData.append(
    //     "thumbnails",
    //     thumbnails[i],
    //     `thumb_${binaryImg[i].name}`
    //   );
    // }

    //========== send both images in same sequence===============
    for (let i = 0; i < binaryImg.length; i++) {
      PostData.append("images", binaryImg[i]);
      PostData.append(
        "thumbnail_images",
        thumbnails[i],
        `thumb_${binaryImg[i].name}`
      );
    }
    setIsLoading(true);
    TrainzaApi.PostImageGallery(isAuthenticated, PostData, eventId).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            Toster(t("IMAGE_ADDED"), "success");
            handleGetEventDetail();
            setBinaryImg(null);
            setThumbnails(null);
            document.getElementById("imageInput").value = "";
          } else if (response?.data?.code === STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  //======== function for update gallry images============
  const handleUpdateGallery = () => {
    const sequence = uploadedImg?.map((item, index) => ({
      id: item.id,
      sequence: index + 1,
    }));
    const PostData = new FormData();
    PostData.append("gallerySequence", JSON.stringify(sequence));
    setIsLoading(true);
    TrainzaApi.UpdateImageGallery(isAuthenticated, PostData, eventId).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            Toster(t("GALLERY_UPDATED"), "success");
            handleGetEventDetail();
            setBinaryImg(null);
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  //===== function for get event detail=========
  const handleGetEventDetail = () => {
    setIsLoading(true);
    TrainzaApi.GetEventDetail(isAuthenticated, eventId).then(
      async (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          const imageDimensionsPromises = response?.data?.eventGallery.map(
            (imageItem) => getImageDimensions(imageItem)
          );
          const dimensions = await Promise.all(imageDimensionsPromises);
          setUploadedImg(dimensions);
          // setUploadedImg(response?.data?.eventGallery);
        }
      }
    );
  };

  useEffect(() => {
    handleGetEventDetail();
  }, []);

  useEffect(() => {
    if (binaryImg) {
      handlePostImage();
    }
  }, [binaryImg]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = useCallback(
    (e, newIndex) => {
      e.preventDefault();
      const draggedImageIndex = Number(e.dataTransfer.getData("index"));
      if (draggedImageIndex !== newIndex) {
        const draggedBinary = uploadedImg[draggedImageIndex];
        const newBinary = [...uploadedImg];
        newBinary.splice(draggedImageIndex, 1);
        newBinary.splice(newIndex, 0, draggedBinary);
        setUploadedImg(newBinary);
        setIsUpdateSeq(true);
      }
    },
    [uploadedImg]
  );

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const lgRef = useRef(null);
  const closeGallery = () => {
    if (lgRef.current) {
      lgRef.current.instance.closeGallery();
    }
  };

  const handleCrossImgClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    closeGallery();
    handleDelete();
  };

  //===== function for delete gallry images=========
  const handleDeleteImage = () => {
    setIsLoading(true);
    const response = TrainzaApi.DeleteGalleryImage(
      isAuthenticated,
      eventId,
      deleteIndex
    );
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("IMG_DELETED"), "success");
          handleGetEventDetail();
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    const sequence = uploadedImg?.map((item, index) => ({
      id: item.id,
      sequence: JSON.stringify(index + 1),
    }));
  }, [uploadedImg]);

  //======== Function to get image dimensions==============
  function getImageDimensions(imageItem) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const newImage = { ...imageItem, width: width, height: height };
        resolve(newImage);
      };
      img.onerror = () => reject("Failed to load image");
      img.src = imageItem.image;
    });
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.detailHeading}>
        <p>{capitalizeFirstLetter(eventTitle)}</p>
      </div>

      <div className={styles.devider}></div>

      {/* old code with limit */}
      {/* <div className={styles.selectImgBox}>
        <p>{t("IMG_GALLERY")}</p>
        <h1 style={{ marginTop: "-10px" }}>
          {t("Add multiple images to this Event")}
        </h1>

        <input
          id="imageInput"
          type="file"
          multiple
          accept=".jpg, .jpeg, .gif, .png, .webp"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />

        <CustomButton
          variant="footerBtnCustom"
          type="button"
          opacity={uploadedImg?.length >= 12 ? CONSTANT.OPACITY_RATIO : ""}
          onClick={() => {
            if (uploadedImg?.length < 12) {
              document.getElementById("imageInput").click();
            }
          }}
        >
          <p>
            {t("SELECT")} <span>{t("IMAGES")}</span>
          </p>
        </CustomButton>
      </div> */}

      {/* new code without limit */}
      <div className={styles.selectImgBox}>
        <p>{t("IMG_GALLERY")}</p>
        <h1 style={{ marginTop: "-10px" }}>
          {t("Add multiple images to this Event")}
        </h1>

        <input
          id="imageInput"
          type="file"
          multiple
          accept=".jpg, .jpeg, .gif, .png, .webp"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />

        <CustomButton
          variant="footerBtnCustom"
          type="button"
          onClick={() => {
            document.getElementById("imageInput").click();
          }}
        >
          <p>
            {t("SELECT")} <span>{t("IMAGES")}</span>
          </p>
        </CustomButton>
      </div>

      {/* <div onDragOver={handleDragOver} className={styles.showImg}>
        <Gallery
          options={{
            fullscreenEl: true,
            zoomEl: true,
            bgOpacity: 999,
          }}
        >
          {uploadedImg?.map((item, index) => (
            <Item
              key={index}
              original={item?.image}
              thumbnail={item?.thumb_image}
              width={item.width}
              height={item.height}
            >
              {({ ref, open }) => (
                <a ref={ref} onClick={open} style={{ position: "relative" }}>
                  <img
                    alt={`img ${index + 1}`}
                    src={item?.image}
                    className={styles.imgBox}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDrop={(e) => handleDrop(e, index)}
                  />
                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.CrossImg}
                    onClick={(e) => {
                      handleCrossImgClick(e, index);
                      setDeleteIndex(item.id);
                    }}
                  />
                </a>
              )}
            </Item>
          ))}
        </Gallery>
      </div> */}

      <div onDragOver={handleDragOver} className={styles.showImg}>
        <Gallery
          options={{
            fullscreenEl: true,
            zoomEl: true,
            bgOpacity: 999,
          }}
        >
          {uploadedImg?.map((item, index) => (
            <Item
              key={index}
              original={item?.image} // Original image to show on click
              // thumbnail={item?.thumb_image || item?.image}
              width={item.width}
              height={item.height}
            >
              {({ ref, open }) => (
                <a ref={ref} onClick={open} style={{ position: "relative" }}>
                  <img
                    alt={`img ${index + 1}`}
                    src={item?.thumb_image || item?.image} // Show thumbnail or original as fallback
                    className={styles.imgBox}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDrop={(e) => handleDrop(e, index)}
                  />
                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.CrossImg}
                    onClick={(e) => {
                      handleCrossImgClick(e, index);
                      setDeleteIndex(item.id);
                    }}
                  />
                </a>
              )}
            </Item>
          ))}
        </Gallery>
      </div>

      <div className={styles.footer}>
        <p>
          {uploadedImg?.length}{" "}
          <span>{modifySingularPlural(t("IMAGE"), uploadedImg?.length)}</span>
        </p>

        {uploadedImg?.length > 1 && (
          <CustomButton
            variant="footerBtnCustom"
            onClick={() => {
              if (isUpdateSeq) {
                handleUpdateGallery();
              }
            }}
            opacity={isUpdateSeq ? "1" : CONSTANT.OPACITY_RATIO}
            type="button"
          >
            <p>
              {t("UPDATE")} <span>{t("SEQUENCE")}</span>
            </p>
          </CustomButton>
        )}
      </div>

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteImage}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EventGallery;
