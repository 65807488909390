import React, { useEffect, useState } from "react";
import styles from "./Groups.module.css";
import CrossImg from "../../Assests/Images/Cross.svg";
import EditImg from "../../Assests/Images/Edit.svg";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  modifySingularPlural,
} from "../../Utils/CommonFunction";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Toster from "../../Common/Toster/Toster";
import { useNavigate } from "react-router-dom";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import TrainzaApi from "../../Helpers/Api";
import Loader from "../../Common/Loader/Loader";
import DeleteResult from "../Members/MemberDetail/DeleteResult";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import EditGroup from "./EditGroup";
import {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import DeletePopUp from "../Members/MemberDetail/DeletePopUp";
import { CONSTANT } from "../../Utils/Constant";

function Groups({
  searchKeyword,
  isLoading,
  setIsLoading,
  handleGetGroupList,
  grpList,
  setGrpList,
  setShowtab,
  setSearchKeyword,
}) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showMember, setShowMember] = useState(false);
  const [showMemberIndex, setShowMemberIndex] = useState();

  const [editGrp, setEditGrp] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [predefaultData, setPredefaultData] = useState();
  const [grpMember, setGrpMember] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [totalCount, setTotalCount] = useState();

  const [grpId, setGrpId] = useState("");
  const [MmemberId, setMemberId] = useState("");
  const [showDeleteMember, setShowDeleteMember] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState();

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleDeleteMember = () => {
    setShowDeleteMember(!showDeleteMember);
  };

  useEffect(() => {
    handleGetGroupList(searchKeyword);
  }, [searchKeyword]);

  const handleDeleteGroup = () => {
    const response = TrainzaApi.DeleteGroupList(isAuthenticated, deleteIndex);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("GRP_DELETED"), "success");
          handleGetGroupList(searchKeyword);
          setSearchKeyword("");
          setEditIndex();
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleGetGroupMember = (id, index, page) => {
    const response = TrainzaApi.groupMember(isAuthenticated, id, page);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.data?.groupMembers?.length === 0) {
          setShowMember(false);
          setShowMemberIndex();
        }

        if (response?.data?.count !== 0) {
          setShowMember(true);
          setShowMemberIndex(index);
        }

        if (page == 1) {
          setGrpMember(response?.data?.groupMembers);
        } else {
          setGrpMember([...grpMember, ...response?.data?.groupMembers]);
        }
        setPageNumber(page + 1);
        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.count);

        // setGrpMember(response?.data?.groupMembers);
      }
    });
  };

  const handleDeleteGroupMember = () => {
    const response = TrainzaApi.DeleteGroupListMember(
      isAuthenticated,
      grpId,
      MmemberId
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDeleteMember(!showDeleteMember);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("Member deleted successfully"), "success");
          handleGetGroupMember(grpId, showMemberIndex, 1);

          setEditIndex();

          setGrpList((prevGrpList) =>
            prevGrpList?.map((grp) =>
              grp?.id === grpId
                ? { ...grp, totalMembers: grp?.totalMembers - 1 }
                : grp
            )
          );
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
  }

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const escapedKeyWord = escapeRegExp(keyWord); // Escape special characters
      const parts = paragraph?.split(new RegExp(`(${escapedKeyWord})`, "gi"));
      return parts?.map((part, index) =>
        part?.toLowerCase() === keyWord?.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "12px",
              letterSpacing: "0.26px",
              background: "#CEF56E",
              margin: "0px",
              color: "#000000",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  return (
    <div>
      {isLoading && <Loader />}

      {grpList?.map((item, index) => {
        return (
          <React.Fragment>
            <div className={styles.grpBox} key={index}>
              <div className={styles.detailBox}>
                <div className={styles.grpDetail}>
                  <h1>
                    <HighlightKeyInParagraph
                      keyWord={searchKeyword}
                      paragraph={capitalizeFirstLetter(item?.name)}
                    />
                  </h1>
                  <h2>{item.subtitle ? item.subtitle : ""}</h2>
                  <p>
                    {item.totalMembers}{" "}
                    {item?.totalMembers > 1 ? t("MEMBER") : t("Member")} -{" "}
                    <span
                      onClick={() => {
                        setGrpId(item.id);
                        if (index == showMemberIndex) {
                          setShowMember(false);
                          setShowMemberIndex();
                        } else {
                          if (item?.totalMembers !== 0) {
                            handleGetGroupMember(item?.id, index, 1);
                          }
                        }
                      }}
                    >
                      {t("VIEW")}{" "}
                      {item?.totalMembers > 1 ? t("MEMBER") : t("Member")}
                    </span>
                  </p>
                </div>
                <div className={styles.grpOption}>
                  <CustomImgButton
                    onClick={() => {
                      handleDelete();
                      setDeleteIndex(item?.id);
                    }}
                    src={CrossImg}
                    alt="delete"
                  ></CustomImgButton>

                  <CustomImgButton
                    onClick={() => {
                      if (index === editIndex) {
                        setEditGrp(false);
                        setEditIndex();
                      } else {
                        setEditGrp(true);
                        setEditIndex(index);
                        setPredefaultData(item);
                        setDeleteIndex(item?.id);
                      }
                    }}
                    src={EditImg}
                    alt="edit"
                  ></CustomImgButton>
                </div>
              </div>
              {showMember && showMemberIndex === index && (
                <div>
                  {grpMember?.map((info, index) => {
                    return (
                      <div className={styles.memberBox} key={index}>
                        <div className={styles.memberBoxInfo}>
                          {" "}
                          <div className={styles.memberImg}>
                            <img
                              src={info?.image}
                              alt="memberImg"
                              width="46px"
                              height="46px"
                            ></img>
                          </div>
                          <p>
                            {capitalizeFirstLetter(info?.firstName)}{" "}
                            <span>{info?.lastName ? info?.lastName : ""}</span>
                          </p>
                        </div>

                        <img
                          src={CrossImg}
                          alt="delete"
                          onClick={() => {
                            setGrpId(item.id);
                            setMemberId(info.id);
                            handleDeleteMember();
                          }}
                        ></img>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      className={styles.closeButton}
                      onClick={() => {
                        setShowMember(false);
                      }}
                    >
                      {t("CLOSE")}
                    </button>

                    <CustomButton
                      variant="footerBtnCustom"
                      opacity={isLoadMore ? "1" : CONSTANT.OPACITY_RATIO}
                      onClick={() => {
                        if (isLoadMore) {
                          handleGetGroupMember(
                            grpId,
                            showMemberIndex,
                            pageNumber
                          );
                        }
                      }}
                    >
                      {" "}
                      <p>
                        {t("Load")} <span>{t("More")}</span>
                      </p>
                    </CustomButton>
                  </div>
                </div>
              )}
            </div>
            {editGrp && editIndex === index && (
              <EditGroup
                predefaultData={predefaultData}
                setEditIndex={setEditIndex}
                handleGetGroupList={handleGetGroupList}
                handleDeleteGroup={handleDeleteGroup}
                show={showDelete}
                handleClose={handleDelete}
                grpList={grpList}
                setGrpList={setGrpList}
              />
            )}
          </React.Fragment>
        );
      })}

      {grpList?.length === 0 && searchKeyword !== "" ? (
        <div className={styles.noDataFound}>
          <p>
            {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
          </p>
        </div>
      ) : (
        ""
      )}

      {grpList?.length === 0 && (searchKeyword === "") & !isLoading ? (
        <div>
          <div className={styles.noActiveGrp}>
            <p>
              {t("CURRNETLY_HAVE_NO_GRP")} -{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditGrp(false);
                  setEditIndex();
                  setShowtab(2);
                }}
              >
                {t("ADD_GRP")}
              </span>
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.addGrpSection}>
        <p>
          {grpList?.length}{" "}
          <span>{modifySingularPlural(t("GROUP"), grpList?.length)}</span>
        </p>

        {/* <button
          onClick={() => {
            setEditGrp(false);
            setEditIndex();
            setShowtab(2);
          }}
        >
          <p>
            {t("ADD")} <span>{t("GROUP")}</span>
          </p>
        </button> */}

        <CustomButton
          variant="footerBtnCustom"
          onClick={() => {
            setEditGrp(false);
            setEditIndex();
            setShowtab(2);
          }}
        >
          {" "}
          <p>
            {t("ADD")} <span>{t("GROUP")}</span>
          </p>
        </CustomButton>
      </div>

      {/* {isLoadMore ? (
        <div className={styles.inviteormore}>
          <button
            type="button"
            onClick={() => handleGetGroupList(pageNumber, searchKeyword)}
          >
            <p>
              {t("LOAD")} <span>{t("MORE")}</span>
            </p>
          </button>
        </div>
      ) : (
        ""
      )} */}

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteGroup}
        isLoading={isLoading}
      />

      <DeletePopUp
        show={showDeleteMember}
        handleClose={handleDeleteMember}
        handleDeleteGroup={handleDeleteGroupMember}
      />
    </div>
  );
}

export default Groups;
