export const STATUS_CODE = {
  HEADER_IS_MISSING: 100,
  DEVICE_TYPE_NOT_ALLOWED: 101,
  INVALID_API_KEY: 102,
  AUTH_TOKEN_IS_REQUIRED: 103,
  INVALID_TOKEN: 104,
  VALIDATION_ERROR: 105,
  INVALID_LOGIN_CREDENTIAL: 111,
  EMAIL_EXIST: 112,
  SESSION_EXPIRED: 113,
  BRAND_NAME_EXIST: 114,
  INVALID_VERIFICATION_CODE: 115,
  FAILED_TO_SEND_EMAIL: 116,
  INVALID_BRAND_ID: 117,
  INVALID_IMAGE_SIZE: 118,
  INVALID_BRAND_FILE_EXTENSION: 119,
  INVALID_APP_LOGO_IMAGE_SIZE: 120,
  INVALID_APP_LOGO_FILE_EXTENSION: 121,
  APP_LOGO_REQUIRED: 122,
  COUNTRY_ID_IS_REQUIRED: 123,

  INVALID_GROUP_ID: 124,
  EMAIL_AND_PHONE_NUMBER_EXIST: 125,
  INVALID_MEMBER_ID: 126,
  INVALID_MEMBER_PICTURE_SIZE: 127,
  INVALID_MEMBER_FILE_EXTENSION: 128,
  GROUP_NAME_EXIST: 129,
  INVALID_PERSONAL_BESTS_OBJECT: 130,
  INVALID_BRANDS_OBJECT: 131,
  MEMBER_LIMIT_EXCEEDED: 132,
  THE_GROUP_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION: 133,
  PHONE_NUMBER_EXIST: 134,
  PROFILE_PICTURE_REQUIRED: 135,
  INVALID_EVENT_ID: 136,
  INVALID_EVENT_PICTURE_SIZE: 137,
  INVALID_EVENT_FILE_EXTENSION: 138,
  INVALID_RESULT_DISTANCE_JSON_OBJECT: 139,
  INVALID_KEY_IN_RESULT_DISTANCE_JSON_OBJECT: 140,
  EVENT_NAME_ALREADY_EXIST: 141,
  MEMBER_IS_INACTIVE: 142,
  ACCOUNT_NOT_FOUND: 143,
  INVALID_PERSONAL_BESTS_JSON_OBJECT: 144,
  INVALID_BRANDS_JSON_OBJECT: 145,
  EVENT_GALLERY_REQUIRED: 146,
  INVALID_EVENT_GALLERY_PICTURE_SIZE: 147,
  INVALID_EVENT_GALLERY_FILE_EXTENSION: 148,
  BRANDS_FIELD_IS_REQUIRED: 149,
  INVALID_POLL_ANSWERS_JSON_OBJECT: 150,
  INVALID_KEY_IN_POLL_ANSWERS_JSON_OBJECT: 151,
  DUPLICATE_POLL_ANSWER_FOUND: 152,
  NUMBER_OF_POLL_ANSWER_CAN_BE_MAXIMUM_10: 153,
  INVALID_TRAINING_GROUPS_JSON_OBJECT: 154,
  ALREADY_A_MEMBER_OF_THIS_CLUB: 155,
  MEMBER_INVITATION_IS_ALREADY_SENT: 156,

  INVALID_EVENT_RESULT_ID: 157,
  INVALID_INVITATION_ID: 158,
  ACTION_ALREADY_TAKEN: 159,
  INVALID_EVENT_DISTANCE_ID: 160,
  EVENT_RESULT_ALREADY_EXISTS: 161,
  INVALID_WORKOUT_TYPE_ID: 162,
  WORKOUT_TYPE_EXISTS: 163,
  THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION: 164,
  FAILED_TO_SEND_SMS: 165,
  WORKOUT_TITLE_EXISTS: 166,
  INVALID_WORKOUT_ID: 167,
  INVALID_WORKOUT_PICTURE_SIZE: 168,
  INVALID_WORKOUT_PICTURE_FILE_EXTENSION: 169,
  THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION: 170,
  ROUTE_NAME_EXISTS: 171,
  INVALID_ROUTE_PICTURE_SIZE: 172,
  INVALID_ROUTE_PICTURE_FILE_EXTENSION: 173,
  INVALID_ROUTE_ID: 174,
  THE_ROUTE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION: 175,
  INVALID_ANSWER_ID: 176,
  NEWS_GALLERY_REQUIRED: 177,
  INVALID_NEWS_GALLERY_PICTURE_SIZE: 178,
  INVALID_NEWS_GALLERY_FILE_EXTENSION: 179,
  INVALID_NEWS_ID: 180,
  INVALID_TRAINING_ID: 181,
  INVALID_NEWS_PICTURE_SIZE: 182,
  INVALID_NEWS_FILE_EXTENSION: 183,
  NEWS_TITLE_EXISTS: 184,
  INVALID_EVENT_POLL_VOTE_ID: 185,
  POLL_VOTE_EXISTS: 186,
  INVALID_EVENT_IMAGE_ID: 189,
  PLS_ADD_A_GRP: 195,
  SUCCESS: 200,
  MEMBER_BRAND_PART: 201,
  INVALID_WORKOUT_LOG_ID: 202,
  OLD_PASSWORD_NOT_MATCH: 203,
  INVALID_NOTIFICATION_ID: 204,
  INVALID_WORKOUT_IMAGE: 205,
  MEMBERSHIP_EXIT_FAILED: 206,
  INVALID_LOGBOOK_ID: 207,
  WORKOUT_CANT_DELETE: 208,
  CLUB_INACTIVE: 209,
  TWITTER_TOKEN_FAILED: 210,
  TWITTER_ACCESS_TOKEN_FAILED: 211,
  MEMBERSHIP_NO_LONGER_EXIST: 212,
  CANNOT_FORGOT_PASSWORD: 213,
  NO_REPETING_DAYS: 214,
  INTERNAL_SERVER_ERROR: 500,
};
