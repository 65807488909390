import React, { useEffect, useRef, useState } from "react";
import styles from "./EventResultList.module.css";
import profileImg from "../../../../Assests/Images/ProfileDemo.svg";

import { useTranslation } from "react-i18next";

import { CustomButton } from "../../../../ReactBootstrap/CustomTabButton";
import {
  capitalizeFirstLetter,
  getTotalResultCount,
  modifySingularPlural,
} from "../../../../Utils/CommonFunction";
import AddEventResult from "./AddEventResult";
import CrossImg from "../../../../Assests/Images/Cross.svg";
import EditImg from "../../../../Assests/Images/Edit.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TrainzaApi from "../../../../Helpers/Api";
import { userLogoutClear } from "../../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../../Utils/StatusCode";
import Loader from "../../../../Common/Loader/Loader";
import Toster from "../../../../Common/Toster/Toster";
import DeleteResult from "../../../Members/MemberDetail/DeleteResult";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";
import { CONSTANT } from "../../../../Utils/Constant";

function EventResultList({
  eventId,
  eventTitle,
  eventImage,
  setShowtab,
  isResultAdded,
}) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();

  const [memberName, setMemberName] = useState("");

  const [resultList, setResultList] = useState([]);
  const [resultId, setResultId] = useState("");

  const [totalResult, setTotalResult] = useState();

  const containerRef = useRef(null);

  //2️⃣ watch for when add button click
  useEffect(() => {
    if (showAdd) {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [showAdd]);

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleGetResult = () => {
    const response = TrainzaApi.GetEventResultData(isAuthenticated, eventId);

    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setResultList(response?.data?.eventResultList);

        const totalResultCount = getTotalResultCount(
          response?.data?.eventResultList
        );

        setTotalResult(totalResultCount);
      }
    });
  };

  useEffect(() => {
    handleGetResult();
  }, []);

  const handleDeleteResult = () => {
    const response = TrainzaApi.DeleteEventResultData(
      isAuthenticated,
      eventId,
      deleteIndex
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("RESULT_DELETED"), "success");
          handleGetResult();
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div ref={containerRef}>
      {isLoading && <Loader />}
      <div className={styles.detailHeading}>
        <p>{capitalizeFirstLetter(eventTitle)}</p>
      </div>

      <div className={styles.devider}></div>

      {!isResultAdded ? (
        <React.Fragment>
          <div className={styles.noActiveGrp}>
            <p>
              {t("You currently have no distances selected")} -{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowtab(1);
                }}
              >
                {t("Go to Event Details")}
              </span>
            </p>
          </div>

          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        ""
      )}

      {resultList?.map((item, index) => {
        return (
          <div key={index}>
            <div className={styles.listHead}>
              <p>
                {item.distance}
                <span>{item.distanceUnit == 1 ? "KM" : "MI"}</span>
              </p>
            </div>

            <div className={styles.devider}></div>

            {item?.results?.length < 1 ? (
              <React.Fragment>
                <div className={styles.noActiveGrp}>
                  <p>
                    {t("NO RESULTS")} -{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowAdd(true);
                        setResultId("");
                      }}
                    >
                      {t("ADD RESULT")}
                    </span>
                  </p>
                </div>

                <div className={styles.devider}></div>
              </React.Fragment>
            ) : (
              ""
            )}

            {item?.results?.map((info, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.detailBox}>
                    <div className={styles.nameSection}>
                      <p className={styles.serial}>{index + 1}.</p>
                      <p className={styles.name}>
                        {capitalizeFirstLetter(info?.firstName)}{" "}
                        {info?.lastName ? info?.lastName : ""}
                      </p>
                    </div>
                    <div className={styles.otherDetail}>
                      <p className={styles.age}>
                        {info?.gender === CONSTANT.MALE
                          ? t("M")
                          : info?.gender === CONSTANT.FEMALE
                          ? t("F")
                          : info?.gender === CONSTANT.OTHER
                          ? t("O")
                          : ""}
                        <span>{info?.age}</span>
                      </p>
                      <p className={styles.kmName}>{info?.pace}</p>
                      <p className={styles.kmName}>{info?.result}</p>
                      <div className={styles.optionImgSection}>
                        <img
                          src={EditImg}
                          alt="EditImg"
                          className={styles.optionImg}
                          onClick={() => {
                            setResultId(info.id);
                            setMemberName(
                              `${info?.firstName} ${
                                info?.lastName ? info?.lastName : ""
                              }`
                            );
                          }}
                        ></img>
                        <img
                          src={CrossImg}
                          alt="CrossImg"
                          className={styles.optionImg}
                          onClick={() => {
                            handleDelete();
                            setShowAdd(false);
                            setDeleteIndex(info?.id);
                          }}
                        ></img>
                      </div>
                    </div>
                  </div>

                  {resultId === info.id ? (
                    <AddEventResult
                      setShowAdd={setShowAdd}
                      eventId={eventId}
                      resultId={resultId}
                      setResultId={setResultId}
                      memberName={memberName}
                      handleGetResult={handleGetResult}
                    />
                  ) : (
                    ""
                  )}
                  <div className={styles.devider}></div>
                </React.Fragment>
              );
            })}
          </div>
        );
      })}

      <div className={styles.bottomSection}>
        <div className={styles.result}>
          <p>
            {totalResult}
            <span> {modifySingularPlural(t("RESULTS__"), totalResult)} </span>
          </p>
        </div>

        <CustomButton
          variant="footerBtnCustom"
          onClick={() => {
            setShowAdd(true);
            setResultId("");
          }}
        >
          <p>
            {t("ADD")} <span>{t("RESULTS__")}</span>
          </p>
        </CustomButton>
      </div>
      {showAdd ? (
        <AddEventResult
          setShowAdd={setShowAdd}
          eventId={eventId}
          handleGetResult={handleGetResult}
        />
      ) : (
        ""
      )}

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteResult}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EventResultList;
