import { useTranslation } from "react-i18next";
import styles from "../../../Routes/AddRoutes/AddRoutes.module.css";
import { useController } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CustomButton } from "../../../../ReactBootstrap/CustomTabButton";
import CrossImg from "../../../../Assests/Images/Cross.svg";
import TextEditor from "../../../../Common/TextEditor/TextEditor";
import { ValidationSchema } from "../../../../Utils/ValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RouteLink from "../../../../Assests/Images/routeLink.png";
import UploadImage from "../../../../Assests/Images/uploadImage.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import Toster from "../../../../Common/Toster/Toster";
import { STATUS_CODE } from "../../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";
import TrainzaApi from "../../../../Helpers/Api";
import { userLogoutClear } from "../../../../redux/slices/userSlice";
import Loader from "../../../../Common/Loader/Loader";
import DeleteRoute from "../../../Routes/DeleteRoute";
import { CONSTANT } from "../../../../Utils/Constant";
import { removeLeadingZeros } from "../../../../Utils/CommonFunction";

function AddRouteManually({
  id,
  setShowtab,
  onClose,
  setCurrentEditData,
  addManually,
  setAddManually,
  setRouteId,
  setRouteType,
  routeList,
  setWhichScreen,
  handleGetRouteDetail123,
  routeDetail,
  setRouteDetail,
  binaryRouteImg,
  setBinaryRouteImg,
  whichScreen,
  setIsAddManually,
}) {
  const { control, register, handleSubmit, formState, setValue, watch, reset } =
    useForm({
      mode: "onChange",
      defaultValues: {
        ROUTE_DETAIL: "",
      },
    });

  const { t } = useTranslation();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "ROUTE_DETAIL",
    control,
    // rules: {
    //   required: t("ERROR_DETAIL"),
    // },
  });

  const navigate = useNavigate();

  const [idForUpdate, setIdForUpdate] = useState();
  const [routeImg, setRouteImg] = useState(null);

  const [distanceUnit, setDistanceUnit] = useState("KM");
  const [isActive, setIsActive] = useState(0);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const routeLinkValue = watch("ROUTE_LINK_IN_ROUTE");

  useEffect(() => {
    setShowRemoveButton(routeLinkValue !== "");
  }, [routeLinkValue]);

  const handleRemoveClick = () => {
    setValue("ROUTE_LINK_IN_ROUTE", ""); // Clear the input field
    setShowRemoveButton(false); // Hide the "Remove" button
  };

  // Button selected distance unit
  const handleButtonClick = (unit) => {
    setDistanceUnit(unit);
  };

  // Select the image to upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setBinaryRouteImg(file);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setRouteImg(fileUrl);
    } else {
      setRouteImg(null);
    }
  };

  const handleHoursChange = (e) => {
    let value = e.target.value;

    // Remove leading zeros if length is greater than 1 and starts with "0"
    // if (value.length > 1 && value.startsWith("0")) {
    //   value = value.replace(/^0+/, "");
    // }

    // Prevent invalid input: check if the value contains a dot or exceeds 999
    if (value.includes(".") || value > 999) return;

    setHours(value);

    const minutesValue = parseFloat(minutes) || 0;
    if (parseInt(value, 10) > 0 || minutesValue > 0) {
      setErrorMessage("");
    } else {
      setErrorMessage(t("ERROR_DISTANCE"));
    }
  };

  const formatNumber = (num) => {
    // Check if num is a string and has a length greater than 2
    return typeof num === "string" && num.length > 2
      ? num.replace(/^0+/, "")
      : num;
  };

  const handleMinutesChange = (e) => {
    let value = e.target.value;
    if (value.length > 1 && value.startsWith("0")) {
      value = value.replace(/^0+/, "0"); // Keep single leading zero for minutes
    }
    if (/^\d*$/.test(value) && value <= 99) {
      setMinutes(value);

      const hoursValue = parseFloat(hours) || 0;
      if (hoursValue > 0 || parseFloat(value) > 0) {
        setErrorMessage("");
      }
    }
  };
  const ScrollRef = useRef(null);

  // Add route api call
  function OnSubmits(data) {
    // if (hours == 0) {
    //   setErrorMessage(t("ERROR_DISTANCE"));
    //   return; // Prevent form submission
    // }

    // if ((!hours || hours < 1 || hours === NaN) && (!minutes || minutes < 1)) {
    //   Toster("Please add route distance", "error");
    // }

    const hoursValue = parseFloat(hours) || 0;
    const minutesValue = parseFloat(minutes) || 0;

    // Validate combined value of hours and minutes
    if (hoursValue === 0 && minutesValue === 0) {
      setErrorMessage(t("ERROR_DISTANCE"));
      return;
    }
    if ((hours && hours > 0 && hours !== NaN) || (minutes && minutes > 0)) {
      setRouteDetail({
        name: data.ROUTENAME,
        details: data.ROUTE_DETAIL,
        routeLink: data.ROUTE_LINK_IN_ROUTE,
        distance: `${hours ? hours : 0}.${minutes ? minutes : `0`}`,
        distanceUnit: distanceUnit === "KM" ? 1 : 2,
        status: isActive ? 1 : 0,
        image: routeImg,
      });

      setWhichScreen(CONSTANT.GET_DETAIL);
      setIsAddManually(false);
    }
  }

  useEffect(() => {
    if (routeDetail) {
      setValue("ROUTENAME", routeDetail?.name);
      setValue("ROUTE_DETAIL", routeDetail?.details);
      setValue("ROUTE_LINK_IN_ROUTE", routeDetail?.routeLink);
      setRouteImg(routeDetail?.image);
      setBinaryRouteImg(routeDetail?.image);
      const number = routeDetail?.distance;
      if (number !== undefined) {
        const integerPart = Math.floor(number); // Gives you 3240

        const decimalAsString = routeDetail?.distance?.toString().split(".")[1];
        const decimalPart = number - integerPart;

        setHours(integerPart);
        setMinutes(decimalAsString);
        setDistanceUnit(routeDetail?.distanceUnit === 1 ? "KM" : "MI");
        setIsActive(routeDetail?.status);
      }
    }
  }, [routeDetail, whichScreen]);

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  function removeLeadingZeros(input) {
    // Convert input to a string if it's a number
    const inputStr = typeof input === "number" ? input.toString() : input;

    // Remove leading zeros
    const result = parseInt(inputStr, 10).toString();

    // Handle edge case where the input might be zero
    return result === "NaN" ? "0" : result;
  }

  const handleChange = (value) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    onChange(sanitizedValue);
  };

  return (
    <div className={styles.addSection} ref={ScrollRef}>
      {isLoading && <Loader />}
      <Form onSubmit={handleSubmit(OnSubmits)}>
        {/* Route name input */}
        <div
          style={{
            border: "1px solid #CED4DA",
            background: "#ffffff",
            marginTop: "-10px",
            marginBottom: "-32px",
            padding: "20px 15px 10px 20px",
          }}
        >
          <div className={styles.eventTitle}>
            <Form.Group className={styles.inputIcon}>
              <Form.Label className={styles.clublabelText}>
                {t("ROUTE__")}
                <span> {t("NAME")}</span>
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                type="text"
                placeholder={t("NAME_OF_ROUTE")}
                {...register("ROUTENAME", ValidationSchema.ROUTENAME)}
                isInvalid={!!formState.errors.ROUTENAME}
                autoFocus={true}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.ROUTENAME?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          {/* Route detail input */}
          <div className={styles.infoLabel}>
            <div className="editorBox">
              <h2 className={styles.clublabelText}>
                {t("ROUTE__")} <span>{t("DETAILS")}</span>{" "}
                {t("OPTIONAL_ROUTE_INFO")}
              </h2>

              <TextEditor
                placeholder={t("DETAIL_ROUTE_PLACEHOLDER")}
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                ref={ref}
                error={error}
              />
            </div>
          </div>

          {/* Route link input */}
          <div className={styles.eventImage}>
            <div className={styles.routeHeading}>
              <img src={RouteLink} alt="Route Link" />
              <h4>
                {t("ROUTE__")} <span>{t("LINK")}</span> {t("OPTIONA")}
              </h4>
            </div>
            <p>{t("ROUTE_LINK_DETAIL")}</p>

            <Form.Group className={styles.inputIcon}>
              <div className={styles.routeContent}>
                <Form.Label className={styles.labelText}>
                  <span>{t("LINK_TO_ROUTE")}</span> {t("ROUTE_LINK_LABEL")}
                </Form.Label>
                {showRemoveButton && (
                  <button onClick={handleRemoveClick}>{t("Remove")}</button>
                )}
              </div>
              <Form.Control
                className={styles.FormInput}
                type="text"
                placeholder={t("LINK_PLACEHOLDER")}
                {...register("ROUTE_LINK_IN_ROUTE", {
                  ...ValidationSchema.ROUTE_LINK_IN_ROUTE,
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                })}
                isInvalid={!!formState.errors.ROUTE_LINK_IN_ROUTE}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.ROUTE_LINK_IN_ROUTE?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          {/* image upload button */}
          <div className={styles.eventImage}>
            <div className={styles.routeHeading}>
              <img src={UploadImage} alt="Route Info" />
              <h4>
                {t("ROUTE__")} <span>{t("INFO")}</span> {t("OPTIONA")}
              </h4>
            </div>
            <p>{t("ROUTE_INFO_DETAIL")}</p>
            <div className={styles.uploadBox}>
              <div className={styles.profileSectionOption}>
                <label htmlFor="file-input" className={styles.customFileInput}>
                  <CustomButton variant="footerBtnCustom" as="span">
                    <p>
                      {t("UPLOAD")} <span>{t("IMAGE")}</span>
                    </p>
                  </CustomButton>
                </label>
                <input
                  type="file"
                  id="file-input"
                  accept=".jpg, .jpeg, .gif, .png, .webp"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              {routeImg && (
                <div className={styles.previewMembershipImgBox}>
                  <img
                    src={routeImg}
                    alt="previewMembershipImg"
                    className={styles.previewImg}
                  ></img>

                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.removeImg}
                    onClick={() => {
                      setRouteImg(null);
                      setBinaryRouteImg("");
                    }}
                  ></img>
                </div>
              )}
            </div>
          </div>

          {/* Route distance input */}
          <div className={styles.routeDistance}>
            <h4>
              {t("ROUTE__")} <span>{t("DISTANCE")}</span> ({t("REQUIRED_")})
            </h4>
            <p>{t("ROUTE_DIS_DETAIL")}</p>
          </div>

          <div className={styles.distanceSection}>
            <div className={styles.distanceData}>
              <h3>{t("DISTANCE")}</h3>
              <div className={styles.Distance}>
                <div className={styles.timeInput}>
                  <input
                    type="number"
                    name="hours"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={formatNumber(hours)}
                    maxLength="3" // Update maxLength to 3 for 999
                    onChange={(e) => {
                      const value = e.target.value;
                      // Check if value is a positive number with max 3 digits
                      if (
                        (/^\d+$/.test(value) && value.length <= 3) || // Allow up to 3 digits
                        value === ""
                      ) {
                        handleHoursChange(e);
                      }
                    }}
                    onKeyPress={(event) => {
                      // Prevent invalid characters
                      if (
                        event.key === "." ||
                        event.key === "e" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    max="999" // Set max to 999
                  />
                </div>
                <p className={styles.dot}>.</p>
                <div className={styles.timeInput}>
                  <input
                    type="number"
                    name="minutes"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={minutes}
                    maxLength="2" // Restrict to 2 digits
                    onChange={(e) => {
                      const value = e.target.value;
                      // Check if value is a positive number with max 2 digits
                      if (
                        (/^\d+$/.test(value) && value.length <= 2) ||
                        value === ""
                      ) {
                        handleMinutesChange(e);
                      }
                    }}
                    onKeyPress={(event) => {
                      // Prevent the user from typing a dot (.), negative or "e" in the input
                      if (
                        event.key === "." ||
                        event.key === "e" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    max="99"
                  />
                </div>
                <h6>{distanceUnit}</h6>
              </div>
              <div className="switch-field">
                <Form.Check
                  type="radio"
                  id="radio-one"
                  name="switch-one"
                  label={t("KILOMETER")}
                  checked={distanceUnit === "KM"}
                  onClick={() => handleButtonClick("KM")}
                />
                <Form.Check
                  type="radio"
                  id="radio-two"
                  name="switch-one"
                  label={t("MILES")}
                  checked={distanceUnit === "MI"}
                  onClick={() => handleButtonClick("MI")}
                />
              </div>
            </div>

            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
          </div>
        </div>
        <div
          className={styles.eventBtn}
          style={{ backgroundColor: "#ECECEC", border: "1px solid #CED4DA" }}
        >
          <div className="d-flex">
            <div className={styles.cancel}>
              <CustomButton
                variant="cancelBtn"
                type="button"
                onClick={() => {
                  setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                  setRouteDetail([]);
                  setRouteId();
                  setValue("ROUTES_TYPES", "");
                  setIsAddManually(false);
                }}
              >
                {t("Remove")}
              </CustomButton>
            </div>
            {/* <div className={styles.delete}>
              {idForUpdate ? (
                <CustomButton
                  variant="deleteBtn"
                  type="button"
                  onClick={() => handleShowDelete()}
                >
                  <RiDeleteBin6Line size="20" style={{ marginRight: "5px" }} />
                  {t("DELETE")}
                </CustomButton>
              ) : (
                <CustomButton
                  variant="deleteBtn"
                  type="button"
                  disabled={true}
                  className={styles.disabledButton}
                >
                  <RiDeleteBin6Line size="20" style={{ marginRight: "5px" }} />
                  {t("DELETE")}
                </CustomButton>
              )}
            </div> */}
          </div>
          <div className={styles.save}>
            <CustomButton
              variant="footerBtnCustom"
              onClick={handleSubmit(OnSubmits)}
              type="button"
            >
              <p>
                <>
                  {idForUpdate ? (
                    <>
                      {t("SAVE")} <span>{t("ROUTE")}</span>
                    </>
                  ) : (
                    <span>{t("SAVE")}</span>
                  )}
                </>
              </p>
            </CustomButton>
          </div>
        </div>
      </Form>
      <DeleteRoute
        handleClose={handleCloseDelete}
        show={showDelete}
        RouteId={id}
        setShowDelete={setShowDelete}
        setShowtab={setShowtab}
        isEdit={true}
      />
    </div>
  );
}

export default AddRouteManually;
